<template>
    <div class="tooloptions-container noselect">

<!--      <tool-options-introduction></tool-options-introduction>-->

        <div >
            <label class="text-base normal-grey" for="">Beschreibung der Markierung</label>
            <textarea class="input_textarea_big mt2 tooloptions-textarea annotation-textarea p2 w100 text-lg" rows="3"
                      @input="activetool.updateDescription($event.target.value)"
                      :value="activetool.description"
                      :style="`border: 1px solid ${activetool.appearance.Color};`">
                    </textarea>
            <div class="flex-group-end">
            <button id="btn_confirmDescription" @click="save($event)" class="tooloptions-button  py-1 px-2 mt2 text-lg ">Fertig</button>

            </div>
        </div>

        <div class="mt4" id="">
            <div :style="`color: ${activetool.appearance.Color};`" class="text-base bold mb2">
                <i class="fas fa-pencil-ruler mr2"></i>
                Markierte Elemente:
            </div>
            <div class="assignation flex-group-start" v-for="Assignation in activetool.Assignations">
                <div class="mt2 tooloptions-operation_element text-base text-serif "
                     v-html="activetool.formatTextElements(Assignation.elements)"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {wait} from "../../../../helpers/functions_helpers";

    export default {
        name: "farbe",
        props: ['activetool'],

        methods: {
            async save($event) {
                $event.target.classList.add('success');
                this.activetool.saveDescriptionToLinkNode();
                await wait(3000);
                $event.target.classList.remove('success');
            }
        }

    }
</script>

<style scoped>
    #btn_confirmDescription.success {
        background: #5cb85c;
        color: #fff;
    }
    #btn_confirmDescription.success:before {
        content: '\f00c ';
        margin-right: 0.25rem;
        font-family: 'FontAwesome';
    }
</style>