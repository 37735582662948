/**
 * Dieses Skript muss mit Node ausgeführt werden
 * und generiert die CSS-Klassen für sämtliche
 * Farbunterstreichungen dynamisch
 */
import {Farbe1} from "./tool_farbe1.js";
import {Farbe2} from "./tool_farbe2.js";
import {Farbe3} from "./tool_farbe3.js";
import {Farbe4} from "./tool_farbe4.js";
import {Farbe5} from "./tool_farbe5.js";
import {Farbe6} from "./tool_farbe6.js";
import {Farbe7} from "./tool_farbe7.js";
import {Farbe8} from "./tool_farbe8.js";
import {uniq} from 'underscore';


let FarbeTools = [Farbe1, Farbe2, Farbe3, Farbe4, Farbe5, Farbe6, Farbe7, Farbe8]

let EinserKombinationen = [];
let ZweierKombinationen = [];
let DreierKombinationen = [];
let ViererKombinationen = [];



function getFarbeInstance (Farbname) {

    return FarbeTools.filter(FarbeInstance => {
        return FarbeInstance.name === Farbname
    })[0]
}



/**
 * Vor <style="customcss"></style> ein neues <style>-Element einfügen
 * Wenn instance = 'reader', wird eine -active-Klasse vorgeschoben (PrefixClasses)
 * @type {HTMLStyleElement}
 * @param instance
 */
export function injectCustomCSS_ToolFarben (instance) {

    /**
     * EINSERKOMBINATIONEN
     */
    FarbeTools.forEach(ErstesFarbeTool => {
        /**
         * Setze die beiden Nummern der Farben zu einer zusammen, sortiere sie, damit später inverse Nummern herausgefiltert werden können
         */
        EinserKombinationen.push([ErstesFarbeTool.name].sort().join(''))


        /**
         * ZWEIERKOMBINATIONEN
         * In jeder Kombination darf jede Farbe nur einmal vorkommen
         */
        FarbeTools.forEach(ZweitesFarbeTool => {
            /**
             * Es handelt sich um ein anderes FarbTool
             */
            if (ZweitesFarbeTool !== ErstesFarbeTool) {
                /**
                 * Setze die beiden Nummern der Farben zu einer zusammen, sortiere sie, damit später inverse Nummern herausgefiltert werden können
                 */
                ZweierKombinationen.push([parseInt(ErstesFarbeTool.name.substring(5)), parseInt(ZweitesFarbeTool.name.substring(5))].sort().join(''))
            }


            /**
             * DREIERKOMBINATIONEN
             * In jeder Kombination darf jede Farbe nur einmal vorkommen
             */
            FarbeTools.forEach(DrittesFarbeTool => {
                if ((DrittesFarbeTool.name !== ZweitesFarbeTool.name) && (DrittesFarbeTool.name !== ErstesFarbeTool.name) && (ZweitesFarbeTool.name !== ErstesFarbeTool.name)) {

                    /**
                     * Setze die beiden Nummern der Farben zu einer zusammen, sortiere sie, damit später inverse Nummern herausgefiltert werden können
                     */
                    DreierKombinationen.push([parseInt(ErstesFarbeTool.name.substring(5)), parseInt(ZweitesFarbeTool.name.substring(5)), parseInt(DrittesFarbeTool.name.substring(5))].sort().join(''))
                }



                /**
                 * VIERERKOMBINATIONEN
                 * In jeder Kombination darf jede Farbe nur einmal vorkommen
                 */
                FarbeTools.forEach(ViertesFarbeTool => {
                    if (
                        (ViertesFarbeTool.name !== ErstesFarbeTool.name) &&
                        (ViertesFarbeTool.name !== ZweitesFarbeTool.name) &&
                        (ViertesFarbeTool.name !== DrittesFarbeTool.name) &&
                        (DrittesFarbeTool.name !== ZweitesFarbeTool.name) &&
                        (DrittesFarbeTool.name !== ErstesFarbeTool.name) &&
                        (ZweitesFarbeTool.name !== ErstesFarbeTool.name)) {

                        /**
                         * Setze die beiden Nummern der Farben zu einer zusammen, sortiere sie, damit später inverse Nummern herausgefiltert werden können
                         */
                        ViererKombinationen.push([parseInt(ErstesFarbeTool.name.substring(5)), parseInt(ZweitesFarbeTool.name.substring(5)), parseInt(DrittesFarbeTool.name.substring(5)), parseInt(ViertesFarbeTool.name.substring(5))].sort().join(''))
                    }


                });
            })
        });
    });

    /**
     * Entferne doppelte Zahlen
     * Aus irgendeinem Grund sind die Kombinationen bereits als Array der FarbtoolNamen vorhanden,
     * wenn die Reader-Component vom Editor aus geöffnet wird.
     */
    ZweierKombinationen = uniq(ZweierKombinationen).map(ZweierNummer => {
        if (typeof ZweierNummer === 'string') {
            return [`farbe${ZweierNummer[0]}`, `farbe${ZweierNummer[1]}`]
        } else {
            return ZweierNummer;
        }
    })
    DreierKombinationen = uniq(DreierKombinationen).map(DreierNummer => {
        if (typeof DreierNummer === 'string') {
            return [`farbe${DreierNummer[0]}`, `farbe${DreierNummer[1]}`, `farbe${DreierNummer[2]}`]
        } else {
            return DreierNummer;
        }
    })
    ViererKombinationen = uniq(ViererKombinationen).map(ViererNummer => {
        if (typeof ViererNummer === 'string') {
            return [`farbe${ViererNummer[0]}`, `farbe${ViererNummer[1]}`, `farbe${ViererNummer[2]}`, `farbe${ViererNummer[3]}`]
        } else {
            return ViererNummer;
        }
    })


    /**
     * ERZEUGUNG DES CSS-STRINGS
     * @type {string}
     */
    let CSSString = ``;

    let EinserCSS = ``
    let ZweierCSS = ``
    let DreierCSS = ``
    let ViererCSS = ``
    /**
     * CREATE CSS: EINSERKOMBINATIONEN
     */
    EinserKombinationen.forEach(Farbname => {
        let FarbTool = getFarbeInstance(Farbname);
        // Stellt sicher, dass im Editor alle Farbkombinationen dargestellt werden, wenn die Klasse .farbetool-active in einem Elternelement besteht / d.h. irgendein Farbtool aktiv ist.
        let PrefixClasses = instance === 'reader' ? `.${FarbTool.name}-active ` : `.farbetool-active`;
        let Selector = instance === 'reader' ? `*[ana*='${FarbTool.name}']` : `*.${FarbTool.name}`;
        let CombinationCSS =
            `
        ${PrefixClasses} ${Selector}  {
            box-shadow: inset 0 -4px 0 0 ${FarbTool.appearance.Color}
            }
            `
        // Farbkombination zu CSSString hinzufügen
        EinserCSS += CombinationCSS;
    })

    /**
     * CREATE CSS: ZWEIERKOMBINATIONEN
     */
    ZweierKombinationen.forEach(FarbKombination => {
        let FarbTool1 = getFarbeInstance(FarbKombination[0]);
        let FarbTool2 = getFarbeInstance(FarbKombination[1]);
        // Stellt sicher, dass im Editor alle Farbkombinationen dargestellt werden, wenn die Klasse .farbetool-active in einem Elternelement besteht / d.h. irgendein Farbtool aktiv ist.
        let PrefixClasses = instance === 'reader' ? `.${FarbTool1.name}-active.${FarbTool2.name}-active ` : `.farbetool-active`;
        let Selector = instance === 'reader' ? `*[ana*='${FarbTool1.name} ${FarbTool2.name}']` : `*.${FarbTool1.name}.${FarbTool2.name}`;
        let CombinationCSS =
            `
            ${PrefixClasses} ${Selector}  {
                box-shadow: inset 0 -3px 0 0 #ffffff, inset 0 -6px 0 0 ${FarbTool1.appearance.Color}, 0 3px 0 0 ${FarbTool2.appearance.Color};
            }
            `
        // Farbkombination zu CSSString hinzufügen
        ZweierCSS += CombinationCSS;
    })

    /**
     * CREATE CSS: ZWEIERKOMBINATIONEN
     */
    DreierKombinationen.forEach(FarbKombination => {
        let FarbTool1 = getFarbeInstance(FarbKombination[0]);
        let FarbTool2 = getFarbeInstance(FarbKombination[1]);
        let FarbTool3 = getFarbeInstance(FarbKombination[2]);
        // Stellt sicher, dass im Editor alle Farbkombinationen dargestellt werden, wenn die Klasse .farbetool-active in einem Elternelement besteht / d.h. irgendein Farbtool aktiv ist.
        let PrefixClasses = instance === 'reader' ? `.${FarbTool1.name}-active.${FarbTool2.name}-active.${FarbTool3.name}-active ` : `.farbetool-active`;
        let Selector = instance === 'reader' ? `*[ana*='${FarbTool1.name} ${FarbTool2.name} ${FarbTool3.name}']` : `*.${FarbTool1.name}.${FarbTool2.name}.${FarbTool3.name}`;



        let CombinationCSS =
            `
            ${PrefixClasses} ${Selector} {
                box-shadow: inset 0 -2px 0 0 #ffffff, inset 0 -4px 0 0 ${FarbTool1.appearance.Color}, inset 0 -6px 0 0 #ffffff, inset 0 -8px 0 0 ${FarbTool2.appearance.Color}, 0 2px 0 0 ${FarbTool3.appearance.Color}
            }
            `
        // Farbkombination zu CSSString hinzufügen
        DreierCSS += CombinationCSS;
    })

    /**
     * CREATE CSS: ZWEIERKOMBINATIONEN
     */
    ViererKombinationen.forEach(FarbKombination => {
        let FarbTool1 = getFarbeInstance(FarbKombination[0]);
        let FarbTool2 = getFarbeInstance(FarbKombination[1]);
        let FarbTool3 = getFarbeInstance(FarbKombination[2]);
        let FarbTool4 = getFarbeInstance(FarbKombination[3]);

      // Stellt sicher, dass im Editor alle Farbkombinationen dargestellt werden, wenn die Klasse .farbetool-active in einem Elternelement besteht / d.h. irgendein Farbtool aktiv ist.
        let PrefixClasses = instance === 'reader' ? `.${FarbTool1.name}-active.${FarbTool2.name}-active.${FarbTool3.name}-active.${FarbTool4.name}-active ` : `.farbetool-active`;
        let Selector = instance === 'reader' ? `*[ana*='${FarbTool1.name} ${FarbTool2.name} ${FarbTool3.name} ${FarbTool4.name}']` : `*.${FarbTool1.name}.${FarbTool2.name}.${FarbTool3.name}.${FarbTool4.name}`;


        let CombinationCSS =
            `
            ${PrefixClasses} ${Selector} {
                box-shadow: inset 0 -2px 0 0 #ffffff, inset 0 -4px 0 0 ${FarbTool1.appearance.Color}, inset 0 -6px 0 0 #ffffff, inset 0 -8px 0 0 ${FarbTool2.appearance.Color}, 0 2px 0 0 ${FarbTool3.appearance.Color}, 0 4px 0 0 #ffffff, 0 6px 0 0 ${FarbTool4.appearance.Color}
            }
            `
        // Farbkombination zu CSSString hinzufügen
        ViererCSS += CombinationCSS;
    })


    /**
     * CSS-String zusammenfügen
     * @type {string}
     */
    CSSString += EinserCSS;
    CSSString += ZweierCSS;
    CSSString += DreierCSS;
    CSSString += ViererCSS;



    const DOMElement_Style = document.createElement('style');
    DOMElement_Style.setAttribute('id', 'tool_farben')
    DOMElement_Style.textContent = CSSString;
    document.head.appendChild(DOMElement_Style);

}

