import axios from 'axios';
import WordAnalyzer from "../../../hermeneus.wordanalyzer/class_wordanalyzer";

/**
 * API-Class that calls callback after axios request
 */
export default class API {
    static cltk_api_base_url () {
        /*return `https://${process.env.MIX_APP_BASEURL}/api`*/
        return `https://api.hermeneus.eu`
    }


    static cltk_deptree (Data, Callback) {
        return axios.post(`${API.cltk_api_base_url()}/dependency-tree`, Data).then(async response => {
            Callback(response);
            return response.data;
        })
    }


    static cltk_scansion (Meter, Data, Callback) {
        return axios.post(`${API.cltk_api_base_url()}/${Meter}`, Data).then(async response => {
            Callback(response);
            return response.data;
        })
    }


    static cltk_scansion_alatius (Meter, Data, Callback) {
        return axios.post(`${API.cltk_api_base_url()}/alatius/scan`, Data).then(async response => {
            Callback(response);
            return response.data;
        })
    }


    static cltk_macronize (Meter, Data, Callback) {
        return axios.post(`${API.cltk_api_base_url()}/alatius/macronize/ambig-vowels`, Data).then(async response => {
            Callback(response);
            return response.data;
        })
    }


    static hermeneus_analysis (Form, API, Data) {

        return axios.get(`${API}${Form}`).then(response => {
            return response.data;
            /*return WordAnalyzer.convertFormat(response.data);*/
        }).catch((error) => {
                return WordAnalyzer.EmptyAnalysis;
            }
        );
    }
}