<template>
  <div v-html="renderedMarkdown"></div>
</template>

<script>
import MarkdownIt from 'markdown-it';

export default {
  name: 'MarkdownRenderer',
  props: {
    markdown: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      md: new MarkdownIt(),
    };
  },
  computed: {
    renderedMarkdown () {
      return this.md.render(this.markdown);
    },
  },
};
</script>

<style>
b, strong {

  font-weight: bold;
}

em, i {

  font-style: italic;
}


</style>

