import Helpers from "../../helpers/class_helpers";
import QuerySelectors from "./reader.class_queryselectors";
import {EventBus} from "../../hermeneus.eventbus/eventbus";
import {normalizeSentenceString, wait} from "../../helpers/functions_helpers";

/**
 * This class will parse the text-string given by the API into readable format
 */
export default class TextPreparation {
    constructor () {
    }


    static ElementPrefix () {
        return true;
    }


    static prefixedTEISource (text) {
        return Helpers.prefixTeiElements(text);
    }


    /**
     * Returns TEI-Source to $VueInstance.data.Source
     * (VerseAnalysisButtons? depending on format-variable)
     * @param text
     * @param Textconfig
     * @returns {{Annotations: *, Header: *, Farben: *, Body: *, Source: Element}}
     */
    static parseTEISource (text, Textconfig) {
        let PrefixedTEISource = Helpers.parseHTML(TextPreparation.prefixedTEISource(text));
        return {
            Annotations: TextPreparation.getAnnotations(PrefixedTEISource),
            Answers: TextPreparation.getAnswers(PrefixedTEISource),
            Farben: TextPreparation.getFarben(PrefixedTEISource),
            Highlights: TextPreparation.getHighlights(PrefixedTEISource),
            Body: TextPreparation.transformText(PrefixedTEISource, Textconfig),
            Header: TextPreparation.getHeader(PrefixedTEISource),
            Source: PrefixedTEISource,
            TextContent: TextPreparation.getTextContent(PrefixedTEISource),
        };
    }


    /**
     * Get Annotations (interprGrp)
     * @param TEISource
     * @returns {*}
     */
    static getHeader (TEISource) {
        let Header = TEISource.querySelector(QuerySelectors.teiheader);
        if (!Header) {
            /*
                        window.$EditorStore.LogStack.error('No teiHeader found!', 'TextPreparation.getHeader');
            */
        }
        return !Header ? false : Header;
    }


    /**
     * Get Annotations (interprGrp)
     * @param TEISource
     * @returns {*}
     */
    static getAnnotations (TEISource) {
        let Annotations = TEISource.querySelector(QuerySelectors.interprgrp);
        if (!Annotations) {
            /*
                        window.$EditorStore.LogStack.error('No interpGrp found!', 'TextPreparation.getAnnotations');
            */
        }
        return !Annotations ? false : Annotations;
    }


    /**
     * Get Farben (linkGrp)
     * @param TEISource
     * @returns {*}
     */
    static getFarben (TEISource) {
        let Farben = TEISource.querySelector(QuerySelectors.div_farben);
        //Fallback 0075
        if (!Farben) {
            let Farben = TEISource.querySelector(QuerySelectors.linkgrp);
            if (!Farben) {
                /*
                                window.$EditorStore.LogStack.error('Keine Farbe gefunden!', 'TextPreparation.getFarben');
                */
                return false;
            }
            return Farben;
        }
        return Farben;
    }


    /**
     * Get Farben (linkGrp)
     * @param TEISource
     * @returns {*}
     */
    static getHighlights (TEISource) {
        let Highlights = TEISource.querySelector(QuerySelectors.div_highlights);
        if (!Highlights) {
            /*
                        window.$EditorStore.LogStack.error('Keine Highlights gefunden!', 'TextPreparation.getHighlights');
            */
            return false;
        }
        return Highlights;
    }


    /**
     * Get Answers (div)
     * @param TEISource
     * @returns {*}
     */
    static getAnswers (TEISource) {
        let Answers = TEISource.querySelector(QuerySelectors.div_answers);
        if (!Answers) {
            /*
                        window.$EditorStore.LogStack.error('Keine Übersetzungen/Answers gefunden!', 'TextPreparation.getAnswerss');
            */
            return false;
        }
        return Answers;
    }


    /**
     * Transforms TEI-XML for hermeneus.reader use
     * @param Textsource
     * @param Textconfig
     * @param innerHTML
     * @returns {*}
     */
    static transformText (Textsource, Textconfig, innerHTML = true) {
        let HTMLString = Textsource.querySelector(QuerySelectors.div_textpart).innerHTML;
        let TextDOMWrapped = TextPreparation.parse(HTMLString);
        // Transform linebreaks
        TextDOMWrapped = TextPreparation.appendLinenumber(TextDOMWrapped);
        // Add SentenceAnalysis-button
        TextDOMWrapped = TextPreparation.insertSentenceButton(TextDOMWrapped, Textconfig,);

        return innerHTML ? TextDOMWrapped.innerHTML : TextDOMWrapped;
    }


    static getTextContent (Textsource) {
        return Array.from(Textsource.querySelectorAll(QuerySelectors.word_pc)).map(Element => Element.textContent).join(" ");
    }


    /**
     * Parse HTML-string as traversable HTML
     * @param HTMLString
     */
    static parse (HTMLString) {
        let HTMLWrapper = document.createElement('HTMLWrapper');
        HTMLWrapper.innerHTML = HTMLString;
        return HTMLWrapper;
    }


    /**
     * Parse Lines
     * @param PrefixedTEISource
     * @returns {Array}
     */
    static getLines (PrefixedTEISource) {
        let Linebreaks = PrefixedTEISource.querySelectorAll(QuerySelectors.linebreak);
        return Array.prototype.map.call(Linebreaks, LB => {
            let LineObject = TextPreparation.parseLBAttribute(LB);
            return {
                'line_no': parseInt(LineObject.original),
                'type': LineObject.type
            }
        });
    }


    /**
     * Parse Sentences
     * @param PrefixedTEISource
     * @returns {Array}
     */
    static getSentences (PrefixedTEISource) {
        let Sentences = PrefixedTEISource.querySelectorAll(QuerySelectors.sentence);
        return Array.prototype.map.call(Sentences, Sentence => {
            /**
             * Fallback <0.0.6
             * Wenn das Attribut "xml:id" die Zeichenkette "delimit" enthält, dann splitte das Attribut bei "_"
             */

            if (Sentence.getAttribute("xml:id").includes("delimit")) {
                let SentenceID = Sentence.getAttribute("xml:id").split("_")[2];
                return {
                    "sentence_string": normalizeSentenceString(Array.from(Sentence.querySelectorAll(`${
                        QuerySelectors.word
                    }, ${
                        QuerySelectors.pc
                    }`)).map(Element =>
                        Element.textContent
                    ).join(" ")),
                    "sentence_id": SentenceID,
                    "sentence_n": SentenceID,
                }
            } else {



                /**
                 * Ab Hermeneus 0.0.7
                 */
                return {
                    "sentence_string": normalizeSentenceString(Array.from(Sentence.querySelectorAll(`${
                        QuerySelectors.word
                    }, ${
                        QuerySelectors.pc
                    }`)).map(Element =>
                        Element.textContent
                    ).join(" ")),
                    "sentence_id": Sentence.getAttribute("xml:id").substr(1),
                    "sentence_n": Sentence.getAttribute("n"),
                }
            }
        })



    }


    /**
     * Reads the JSON-Attribute in lb-elements and creates a span-child
     * that displays the line-number
     * @param WrappedDOMText
     */
    static appendLinenumber (WrappedDOMText) {
        let Linebreaks = WrappedDOMText.querySelectorAll(QuerySelectors.linebreak);
        for (let LinebreakElement of Linebreaks) {
            let Linenumbers = Helpers.parseLinebreakNAttribute(LinebreakElement);
            let LinenumbersContainer = document.createElement('div');
            LinenumbersContainer.setAttribute('class', 'reader__workspace__primary--linenumber-container');
            let DivLinenumberOriginal = document.createElement('div');
            DivLinenumberOriginal.setAttribute('class', 'reader__workspace__primary--linenumber-container__original');
            /** @namespace Linenumbers.original**/
            DivLinenumberOriginal.textContent = Linenumbers.original;
            if (Helpers.isDivisableBy(Linenumbers.original, 5)) {
                DivLinenumberOriginal.classList.add('fifth-line')
            }
            LinenumbersContainer.appendChild(DivLinenumberOriginal);
            let DivLinenumberHermeneus = document.createElement('div');
            DivLinenumberHermeneus.setAttribute('class', 'reader__workspace__primary--linenumber-container__hermeneus');
            /** @namespace Linenumbers.hermeneus**/
            DivLinenumberHermeneus.textContent = Linenumbers.hermeneus;
            if (Helpers.isDivisableBy(Linenumbers.hermeneus, 5)) {
                DivLinenumberHermeneus.classList.add('fifth-line')
            }
            LinenumbersContainer.appendChild(DivLinenumberHermeneus);
            LinebreakElement.appendChild(LinenumbersContainer);
        }
        return WrappedDOMText;
    }


    /**
     * Append Helper-Button for sentence-analyis at end
     * of every sentence: Wenn die erste Satznummer 0 ist, dann beginne erst bei Satz 1
     * @param WrappedDOMText
     * @param Textconfig
     * @returns {*}
     */
    static insertSentenceButton (WrappedDOMText, Textconfig) {
        let SentenceElements = WrappedDOMText.querySelectorAll(QuerySelectors.sentence);
        let FirstSentenceID = parseInt(Array.from(SentenceElements).map(SentenceElement => TextPreparation.parseSentenceID(SentenceElement))[0]);
        for (let SentenceElement of SentenceElements) {
            let SentenceNumber = TextPreparation.parseSentenceNumber(SentenceElement, FirstSentenceID);
            let SentenceButton = document.createElement('button');
            SentenceButton.innerHTML = SentenceNumber.toString();
            SentenceButton.setAttribute('class', 'reader__hermeneus-helper--button-sentence-number');
            SentenceButton.setAttribute('sentence-no', SentenceNumber.toString());
            if (Textconfig.stylesheet === "minimal") {
                SentenceElement.insertBefore(SentenceButton, SentenceElement.firstElementChild);
            } else {
                SentenceElement.appendChild(SentenceButton);
            }
            TextPreparation.registerSentenceKeyPressedEvent(SentenceNumber, SentenceElement);
        }
        return WrappedDOMText;
    }


    /**
     * Attributswert vor 0096: delimit_s_N
     * Attributswert nach 0096: sN
     * @param SentenceElement
     * @returns {*|string}
     */
    static parseSentenceID (SentenceElement) {
        let IDAttributeValue = SentenceElement.getAttribute('id');
        return IDAttributeValue.includes('delimit_s_') ? IDAttributeValue.replace('delimit_s_', '') : IDAttributeValue.substring(1)
    }


    /**
     * Wenn der Satz ein n-Attribut hat, nimm dies als Satzzahl, andernfalls verwende die ID (ohne "delimit_s_")
     * @param SentenceElement
     * @param FirstSentenceID
     * @returns {number|number|*}
     */
    static parseSentenceNumber (SentenceElement, FirstSentenceID) {
        if (SentenceElement.hasAttribute("n")) {
            return SentenceElement.getAttribute('n');
        } else {
            let SentenceNumber = parseInt(TextPreparation.parseSentenceID(SentenceElement));
            return FirstSentenceID === 0 ? SentenceNumber + 1 : SentenceNumber
        }
    }


    /**
     * Register Event for Sentence-Button
     * Entsprechende Satznummer wird geöffnet, wenn Zahl gedrückt wird
     * @param SentenceNumber
     * @param SentenceElement
     */
    static registerSentenceKeyPressedEvent (SentenceNumber, SentenceElement) {
        let NumberKeyCodeLookupTable = {
            1: 49,
            2: 50,
            3: 51,
            4: 52,
            5: 53,
            6: 54,
            7: 55,
            8: 56,
            9: 57,
        }
        document.addEventListener('keyup', async function (e) {
            e = e || window.event;
            if (Object.values(NumberKeyCodeLookupTable).includes(e.keyCode)) {
                EventBus.$emit('setSentenceAnalysis', false);
            }
            await wait(100);
            if (e.keyCode === NumberKeyCodeLookupTable[SentenceNumber.toString()]) {
                let PressedSentenceButton = document.querySelector(`button[sentence-no="${SentenceNumber.toString()}"`);
                EventBus.$emit('setSentenceActiveID', SentenceNumber.toString());
                PressedSentenceButton.focus();
                EventBus.$emit('setSentenceAnalysis', SentenceElement.innerHTML)
                await wait(400);
                PressedSentenceButton.blur();
            }
        }, false)



    }


    /**
     * Reads LB-Element on a general level
     * @param LinebreakElement
     * @returns {any}
     */
    static parseLBAttribute (LinebreakElement) {
        let LineObject = Helpers.parseLinebreakNAttribute(LinebreakElement);
        LineObject['type'] = LinebreakElement.getAttribute('type') ? LinebreakElement.getAttribute('type') : '';
        return LineObject;
    }



}