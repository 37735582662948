export default {
    mounted(el, binding, vnode) {
        el.classList.add('alpha-feature');

        // Zugriff auf den Komponenten-Kontext
        const instance = vnode.component?.proxy;

        if (!instance?.$userData || !instance?.$userData.rights.accessAlphaFeatures) {
            el.classList.add('hidden');
            el.style.display = 'none';
            el.style.pointerEvents = 'none';
            el.disabled = true;
        }
    },
}