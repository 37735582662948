import Transformer from "./class_transformer";
import EditorHelpers from "../helpers/class_editorhelpers";

/**
 * Will transform Hermeneus.Editor-Format to TEI-XML
 */
export default class TEIXMLTransformer {
    constructor () {
    }


    /**
     * Global namespace for TEI-XML
     * @returns {string}
     */
    static get teiNS () {
        return "http://www.tei-c.org/ns/1.0";

    }


    /**
     * Creates an empty TEIDocument with basic teiHeader-tags and text-tag
     * @returns {Promise}
     */
    static createDocument () {
        return new Promise((resolve, reject) => {
                let TEIDocument = document.implementation.createDocument(TEIXMLTransformer.teiNS, 'TEI', null);
                let text = document.createElementNS(TEIXMLTransformer.teiNS, 'text');
                let teiHeader = document.createElementNS(TEIXMLTransformer.teiNS, 'teiHeader');
                let teiHeader__fileDesc = document.createElementNS(TEIXMLTransformer.teiNS, 'fileDesc');
                let teiHeader__fileDesc__titleStmt = document.createElementNS(TEIXMLTransformer.teiNS, 'titleStmt');
                let teiHeader__fileDesc__titleStmt__title = document.createElementNS(TEIXMLTransformer.teiNS, 'title');
                let teiHeader__fileDesc__titleStmt__author = document.createElementNS(TEIXMLTransformer.teiNS, 'author');
                let teiHeader__fileDesc__titleStmt__respStmt = document.createElementNS(TEIXMLTransformer.teiNS, 'respStmt');
                let teiHeader__fileDesc__titleStmt__respStmt__resp = document.createElementNS(TEIXMLTransformer.teiNS, 'resp');
                let teiHeader__fileDesc__titleStmt__respStmt__name = document.createElementNS(TEIXMLTransformer.teiNS, 'name');
                let teiHeader__fileDesc__seriesStmt = document.createElementNS(TEIXMLTransformer.teiNS, 'seriesStmt');
                let teiHeader__fileDesc__seriesStmt__title = document.createElementNS(TEIXMLTransformer.teiNS, 'title');
                let teiHeader__fileDesc__seriesStmt__p = document.createElementNS(TEIXMLTransformer.teiNS, 'p');
                let teiHeader__fileDesc__seriesStmt__biblScope = document.createElementNS(TEIXMLTransformer.teiNS, 'biblScope');
                let teiHeader__fileDesc__seriesStmt__respStmt = document.createElementNS(TEIXMLTransformer.teiNS, 'respStmt');
                let teiHeader__fileDesc__seriesStmt__respStmt__resp = document.createElementNS(TEIXMLTransformer.teiNS, 'resp');
                let teiHeader__fileDesc__seriesStmt__respStmt__name = document.createElementNS(TEIXMLTransformer.teiNS, 'name');
                let teiHeader__fileDesc__publicationStmt = document.createElementNS(TEIXMLTransformer.teiNS, 'publicationStmt');
                let teiHeader__fileDesc__publicationStmt__p = document.createElementNS(TEIXMLTransformer.teiNS, 'p');
                let teiHeader__fileDesc__sourceDesc = document.createElementNS(TEIXMLTransformer.teiNS, 'sourceDesc');
                let teiHeader__fileDesc__sourceDesc__p = document.createElementNS(TEIXMLTransformer.teiNS, 'p');



                //let teiHeader__encodingDesc = document.createElementNS(TEITransformer.teiNS, 'encodingDesc');
                //let teiHeader__profileDesc = document.createElementNS(TEITransformer.teiNS, 'profileDesc');
                //let teiHeader__revisionDesc = document.createElementNS(TEITransformer.teiNS, 'revisionDesc');
                //teiHeader.appendChild(teiHeader__encodingDesc);
                //teiHeader.appendChild(teiHeader__profileDesc);
                //teiHeader.appendChild(teiHeader__revisionDesc);

                /**
                 * Handle fileDesc
                 */
                teiHeader.appendChild(teiHeader__fileDesc);
                teiHeader__fileDesc.appendChild(teiHeader__fileDesc__titleStmt);
                teiHeader__fileDesc__titleStmt.appendChild(teiHeader__fileDesc__titleStmt__title);
                teiHeader__fileDesc__titleStmt.appendChild(teiHeader__fileDesc__titleStmt__author);
                teiHeader__fileDesc__titleStmt.appendChild(teiHeader__fileDesc__titleStmt__respStmt);
                teiHeader__fileDesc__titleStmt__respStmt.appendChild(teiHeader__fileDesc__titleStmt__respStmt__resp);
                teiHeader__fileDesc__titleStmt__respStmt.appendChild(teiHeader__fileDesc__titleStmt__respStmt__name);
                teiHeader__fileDesc.appendChild(teiHeader__fileDesc__publicationStmt);
                teiHeader__fileDesc__publicationStmt.appendChild(teiHeader__fileDesc__publicationStmt__p);
                teiHeader__fileDesc.appendChild(teiHeader__fileDesc__sourceDesc);
                teiHeader__fileDesc__sourceDesc.appendChild(teiHeader__fileDesc__sourceDesc__p);
                teiHeader__fileDesc.appendChild(teiHeader__fileDesc__seriesStmt);
                teiHeader__fileDesc__seriesStmt.appendChild(teiHeader__fileDesc__seriesStmt__title);
                teiHeader__fileDesc__seriesStmt.appendChild(teiHeader__fileDesc__seriesStmt__p);
                teiHeader__fileDesc__seriesStmt.appendChild(teiHeader__fileDesc__seriesStmt__biblScope);
                teiHeader__fileDesc__seriesStmt.appendChild(teiHeader__fileDesc__seriesStmt__respStmt);
                teiHeader__fileDesc__seriesStmt__respStmt.appendChild(teiHeader__fileDesc__seriesStmt__respStmt__resp);
                teiHeader__fileDesc__seriesStmt__respStmt.appendChild(teiHeader__fileDesc__seriesStmt__respStmt__name);
                TEIDocument.documentElement.appendChild(teiHeader);
                TEIDocument.documentElement.appendChild(text);
                resolve(TEIDocument);
            }
        );

    }



    /**
     * Creates an empty TEIDocument with basic teiHeader-tags and text-tag
     * @returns {Promise}
     */
    static createDocumentNS () {
        return new Promise((resolve, reject) => {
                let TEIElement = document.createElementNS(TEIXMLTransformer.teiNS, 'TEI');
                TEIElement.setAttribute('xmlns', TEIXMLTransformer.teiNS);
                let text = document.createElementNS(TEIXMLTransformer.teiNS, 'text');
                let body = document.createElementNS(TEIXMLTransformer.teiNS, 'body');
                let div_textpart = document.createElementNS(TEIXMLTransformer.teiNS, 'div');
                div_textpart.setAttribute('type', 'textpart');
                // Commentary / Annotation
                let div_commentary = document.createElementNS(TEIXMLTransformer.teiNS, 'div');
                div_commentary.setAttribute('type', 'commentary');
                let div_commentary_interpGrp = document.createElementNS(TEIXMLTransformer.teiNS, 'interpGrp');
                // Answers
                let div_answers = document.createElementNS(TEIXMLTransformer.teiNS, 'div');
                div_answers.setAttribute('type', 'answers');
                // Farben
                let div_farben = document.createElementNS(TEIXMLTransformer.teiNS, 'div');
                div_farben.setAttribute('type', 'farben');
                // Farben
                let div_highlight = document.createElementNS(TEIXMLTransformer.teiNS, 'div');
                div_highlight.setAttribute('type', 'highlight');
                // Textcontainer
                let p = document.createElementNS(TEIXMLTransformer.teiNS, 'p');

                /** teiHeader */
                let teiHeader = document.createElementNS(TEIXMLTransformer.teiNS, 'teiHeader');
                let teiHeader__fileDesc = document.createElementNS(TEIXMLTransformer.teiNS, 'fileDesc');
                let teiHeader__fileDesc__titleStmt = document.createElementNS(TEIXMLTransformer.teiNS, 'titleStmt');
                let teiHeader__fileDesc__titleStmt__title = document.createElementNS(TEIXMLTransformer.teiNS, 'title');
                let teiHeader__fileDesc__titleStmt__author = document.createElementNS(TEIXMLTransformer.teiNS, 'author');
                let teiHeader__fileDesc__titleStmt__respStmt = document.createElementNS(TEIXMLTransformer.teiNS, 'respStmt');
                let teiHeader__fileDesc__titleStmt__respStmt__resp = document.createElementNS(TEIXMLTransformer.teiNS, 'resp');
                let teiHeader__fileDesc__titleStmt__respStmt__name = document.createElementNS(TEIXMLTransformer.teiNS, 'name');
                let teiHeader__fileDesc__seriesStmt = document.createElementNS(TEIXMLTransformer.teiNS, 'seriesStmt');
                let teiHeader__fileDesc__seriesStmt__title = document.createElementNS(TEIXMLTransformer.teiNS, 'title');
                let teiHeader__fileDesc__seriesStmt__title_sub = document.createElementNS(TEIXMLTransformer.teiNS, 'title');
                teiHeader__fileDesc__seriesStmt__title_sub.setAttribute('type', 'sub');
                let teiHeader__fileDesc__seriesStmt__biblScope = document.createElementNS(TEIXMLTransformer.teiNS, 'biblScope');
                let teiHeader__fileDesc__seriesStmt__respStmt = document.createElementNS(TEIXMLTransformer.teiNS, 'respStmt');
                let teiHeader__fileDesc__seriesStmt__respStmt__resp = document.createElementNS(TEIXMLTransformer.teiNS, 'resp');
                let teiHeader__fileDesc__seriesStmt__respStmt__name = document.createElementNS(TEIXMLTransformer.teiNS, 'name');
                let teiHeader__fileDesc__publicationStmt = document.createElementNS(TEIXMLTransformer.teiNS, 'publicationStmt');
                let teiHeader__fileDesc__publicationStmt__p = document.createElementNS(TEIXMLTransformer.teiNS, 'p');
                let teiHeader__fileDesc__sourceDesc = document.createElementNS(TEIXMLTransformer.teiNS, 'sourceDesc');
                let teiHeader__fileDesc__sourceDesc__p = document.createElementNS(TEIXMLTransformer.teiNS, 'p');

                //let teiHeader__encodingDesc = document.createElementNS(TEITransformer.teiNS, 'encodingDesc');
                //let teiHeader__profileDesc = document.createElementNS(TEITransformer.teiNS, 'profileDesc');
                //let teiHeader__revisionDesc = document.createElementNS(TEITransformer.teiNS, 'revisionDesc');
                //teiHeader.appendChild(teiHeader__encodingDesc);
                //teiHeader.appendChild(teiHeader__profileDesc);
                //teiHeader.appendChild(teiHeader__revisionDesc);
                /**
                 * Handle fileDesc
                 */
                teiHeader.appendChild(teiHeader__fileDesc);
                teiHeader__fileDesc.appendChild(teiHeader__fileDesc__titleStmt);
                teiHeader__fileDesc__titleStmt.appendChild(teiHeader__fileDesc__titleStmt__title);
                teiHeader__fileDesc__titleStmt.appendChild(teiHeader__fileDesc__titleStmt__author);
                teiHeader__fileDesc__titleStmt.appendChild(teiHeader__fileDesc__titleStmt__respStmt);
                teiHeader__fileDesc__titleStmt__respStmt.appendChild(teiHeader__fileDesc__titleStmt__respStmt__resp);
                teiHeader__fileDesc__titleStmt__respStmt.appendChild(teiHeader__fileDesc__titleStmt__respStmt__name);
                teiHeader__fileDesc.appendChild(teiHeader__fileDesc__publicationStmt);
                teiHeader__fileDesc__publicationStmt.appendChild(teiHeader__fileDesc__publicationStmt__p);
                teiHeader__fileDesc.appendChild(teiHeader__fileDesc__seriesStmt);
                teiHeader__fileDesc__seriesStmt.appendChild(teiHeader__fileDesc__seriesStmt__title);
                teiHeader__fileDesc__seriesStmt.appendChild(teiHeader__fileDesc__seriesStmt__title_sub);
                teiHeader__fileDesc__seriesStmt.appendChild(teiHeader__fileDesc__seriesStmt__respStmt);
                teiHeader__fileDesc__seriesStmt__respStmt.appendChild(teiHeader__fileDesc__seriesStmt__respStmt__resp);
                teiHeader__fileDesc__seriesStmt__respStmt.appendChild(teiHeader__fileDesc__seriesStmt__respStmt__name);
                teiHeader__fileDesc__seriesStmt.appendChild(teiHeader__fileDesc__seriesStmt__biblScope);
                teiHeader__fileDesc.appendChild(teiHeader__fileDesc__sourceDesc);
                teiHeader__fileDesc__sourceDesc.appendChild(teiHeader__fileDesc__sourceDesc__p);
                TEIElement.appendChild(teiHeader);
                TEIElement.appendChild(text);
                text.appendChild(body);
                body.appendChild(div_textpart);
                div_textpart.appendChild(p);
                body.appendChild(div_commentary);
                div_commentary.appendChild(div_commentary_interpGrp);
                body.appendChild(div_farben);
                body.appendChild(div_answers);
                body.appendChild(div_highlight);
                resolve(TEIElement);
            }
        );

    }


    /**
     * Wraps an Element inside a wrapper so its innerHTML is being returned
     * as a string
     * @param TEIElement
     * @returns {string}
     */
    static getHTMLString (TEIElement) {
        let WrapperElement = document.createElement('wrapper');
        WrapperElement.appendChild(TEIElement);
        return WrapperElement.innerHTML;
    }


    /**
     * Fill in basic teiHeader info
     * @param TEIDocument
     * @returns {Promise}
     * @constructor
     */
    static appendHeaderContent (TEIDocument) {
        return new Promise((resolve, reject) => {
            let string_teiHeader__fileDesc__titleStmt__author = window.$EditorStore.Node_TextInfo.querySelector('.hermeneus-textinfo__default-author').textContent;
            let string_teiHeader__fileDesc__titleStmt__title = window.$EditorStore.Node_TextInfo.querySelector('.hermeneus-textinfo__default-title').textContent;
            let string_teiHeader__fileDesc__seriesStmt__biblScope = window.$EditorStore.Node_TextInfo.querySelector('.hermeneus-textinfo__default-locus').textContent;
            let string_teiHeader__fileDesc__seriesStmt__title = window.$EditorStore.Node_TextInfo.querySelector('.hermeneus-textinfo__user-title').textContent;
            let string_teiHeader__fileDesc__seriesStmt__title_sub = window.$EditorStore.Node_TextInfo.querySelector('.hermeneus-textinfo__user-subtitle').textContent;
            let string_teiHeader__fileDesc__seriesStmt__respStmt__name = window.$EditorStore.Node_TextInfo.querySelector('.hermeneus-textinfo__user-author').textContent;
            TEIDocument.querySelector('titleStmt title').innerHTML = string_teiHeader__fileDesc__titleStmt__title != '' ? string_teiHeader__fileDesc__titleStmt__title : 'null';
            TEIDocument.querySelector('titleStmt author').innerHTML = string_teiHeader__fileDesc__titleStmt__author != '' ? string_teiHeader__fileDesc__titleStmt__author : 'null';
            TEIDocument.querySelector('titleStmt respStmt resp').innerHTML = 'compiled by';
            TEIDocument.querySelector('titleStmt respStmt name').innerHTML = 'hermeneus (Benedikt Blumenfelder)';
            TEIDocument.querySelector('seriesStmt biblScope').innerHTML = string_teiHeader__fileDesc__seriesStmt__biblScope != '' ? string_teiHeader__fileDesc__seriesStmt__biblScope : 'null';
            TEIDocument.querySelector('seriesStmt title').innerHTML = string_teiHeader__fileDesc__seriesStmt__title != '' ? string_teiHeader__fileDesc__seriesStmt__title : 'null';
            TEIDocument.querySelector('seriesStmt title[type="sub"]').innerHTML = string_teiHeader__fileDesc__seriesStmt__title_sub != '' ? string_teiHeader__fileDesc__seriesStmt__title_sub : 'null';
            TEIDocument.querySelector('seriesStmt respStmt resp').innerHTML = 'text analyzed and edited by';
            TEIDocument.querySelector('seriesStmt respStmt name').innerHTML = string_teiHeader__fileDesc__seriesStmt__respStmt__name != '' ? string_teiHeader__fileDesc__seriesStmt__respStmt__name : 'null';
            TEIDocument.querySelector('publicationStmt p').innerHTML = 'null';
            TEIDocument.querySelector('sourceDesc p').innerHTML = 'null';
            resolve(TEIDocument);
        });
    }


    /**
     * Appends textmodel to <text>-section in TEI-Document
     * @param TEIDocument
     * @returns {Promise}
     */
    static async appendTextContent (TEIDocument) {
        return new Promise(async (resolve, reject) => {
            let Node_TextContainer = 'p';
            // Is it necessary to clone the node?
            TEIDocument.querySelector(`text body ${Node_TextContainer}`).innerHTML = window.$EditorStore.Node_Model_Text.querySelector('#hermeneus-text div[type="textpart"]').cloneNode(true).innerHTML;
            resolve(TEIDocument);
        });
    }


    /**
     * Appends annotations to <div type="commentary">-section in TEI-Document
     * @param TEIDocument
     * @returns {Promise}
     */
    static async appendCommentary (TEIDocument) {

        return new Promise(async (resolve, reject) => {
            let UserAnnotations = await EditorHelpers.decodeHtmlEntities(window.$EditorStore.Node_Model_Annotations.innerHTML);
            if (!UserAnnotations) {
                let EmptyInterpElement = document.createElementNS(TEIXMLTransformer.teiNS, 'interp');
                EmptyInterpElement.textContent = ' ';
                TEIDocument.querySelector('text body div[type="commentary"] interpGrp').appendChild(EmptyInterpElement);
            } else {
                TEIDocument.querySelector('text body div[type="commentary"] interpGrp').innerHTML = UserAnnotations;
            }
            resolve(TEIDocument);
        });
    }


    /**
     * Appends answers to <div type="answers">-section in TEI-Document
     * @param TEIDocument
     * @returns {Promise}
     */
    static async appendAnswers (TEIDocument) {

        return new Promise(async (resolve, reject) => {
            let UserAnswers = await EditorHelpers.decodeHtmlEntities(window.$EditorStore.Node_Model_Answers.innerHTML);
            if (!UserAnswers) {
                let EmptyInterpElement = document.createElementNS(TEIXMLTransformer.teiNS, 'span');
                EmptyInterpElement.textContent = ' ';
                TEIDocument.querySelector('text body div[type="answers"]').appendChild(EmptyInterpElement);
            } else {
                TEIDocument.querySelector('text body div[type="answers"]').innerHTML = UserAnswers;
            }
            resolve(TEIDocument);
        });
    }


    /**
     * Appends farben to <div type="farben">-section in TEI-Document
     * @param TEIDocument
     * @returns {Promise}
     */
    static async appendFarben (TEIDocument) {
        return new Promise(async (resolve, reject) => {
            let UserFarben = await window.$EditorStore.Node_Model_Farben.innerHTML;
            if (!UserFarben) {
                let EmptyFarbeElement = document.createElementNS(TEIXMLTransformer.teiNS, 'span');
                EmptyFarbeElement.textContent = ' ';
                TEIDocument.querySelector('text body div[type="farben"]').appendChild(EmptyFarbeElement);
            } else {
                window.$EditorStore.Node_Model_Farben.childNodes.forEach(async link => {
                    TEIDocument.querySelector('text body div[type="farben"]').insertAdjacentHTML('beforeend', link.outerHTML);
                });
            }
            resolve(TEIDocument);
        });
    }


    /**
     * Appends farben to <div type="highlight">-section in TEI-Document
     * @param TEIDocument
     * @returns {Promise}
     */
    static async appendHighlight (TEIDocument) {
        return new Promise(async (resolve, reject) => {
            let UserHighlight = await window.$EditorStore.Node_Model_Highlight.innerHTML;
            if (!UserHighlight) {
                let EmptyHighlightElement = document.createElementNS(TEIXMLTransformer.teiNS, 'span');
                EmptyHighlightElement.textContent = ' ';
                TEIDocument.querySelector('text body div[type="highlight"]').appendChild(EmptyHighlightElement);
            } else {
                window.$EditorStore.Node_Model_Highlight.childNodes.forEach(async link => {
                    TEIDocument.querySelector('text body div[type="highlight"]').insertAdjacentHTML('beforeend', link.outerHTML);
                });
            }
            resolve(TEIDocument);
        });
    }


    /**
     * Strips Elements inside <text>-tags of hermeneus-Attributes (and CSS-classes)
     * @param TEIDocument
     * @returns {Promise}
     */
    static stripAttributes (TEIDocument) {
        return new Promise((resolve, reject) => {
            let ElementArray = TEIDocument.querySelectorAll('text w, text lb, text pc, text gap, text milestone');
            Transformer.AllAttributes().forEach(Attribute => {
                ElementArray.forEach(Element => {
                    Element.removeAttribute(Attribute);
                    Element.removeAttribute('class');
                    Element.removeAttribute('style');
                });
            });
            resolve(TEIDocument);
        });
    }


    /**
     * Remove all whitespaces at beginning and end of words
     * @param TEIDocument
     * @returns {Promise}
     */
    static removeWhitespaces (TEIDocument) {
        return new Promise((resolve, reject) => {
            let Words = TEIDocument.querySelectorAll('w, pc');
            Words.forEach(Word => {
                Word.textContent = Word.textContent.trim();
            });
            resolve(TEIDocument);

        })
    }


    /**
     *
     * @returns {string}
     * @constructor
     */
    static get TEISchemata () {
        return `
        <?xml-model href="http://www.tei-c.org/release/xml/tei/custom/schema/relaxng/tei_all.rng" schematypens="http://relaxng.org/ns/structure/1.0"?>
        <?xml-model href="http://www.tei-c.org/release/xml/tei/custom/schema/relaxng/tei_all.rng" schematypens="http://purl.oclc.org/dsdl/schematron"?>
        `
    }


}