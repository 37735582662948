/**
 * Der LogStack hat einen globalen Stack und drei separate Stacks (warning, error, info)
 * Damit eine konsekutive Zählung der Einträge gewährleistet ist, läuft ein channelspezifischer Counter und ein
 * globaler Counter mit.
 */
export default class LogStack {
    constructor (logFunction) {
        this.GlobalStack = [];
        this.InfoStack = [];
        this.ErrorStack = [];
        this.WarningStack = [];

        this.Order_Global = 1;
        this.Order_Warning = 1;
        this.Order_Error = 1;
        this.Order_Info = 1;
    }


    /**
     * Übergebene Nachricht und Function-Callee auf den Stack werfen
     * @param Message
     * @param Callee
     */
    warning (Message, Callee = '') {
        this.WarningStack.push(
            {
                "Message": Message,
                "Callee": Callee,
                "Order_Global": this.Order_Global,
                "Order_Warning": this.Order_Warning
            });
        this.Order_Global++;
        this.Order_Warning++;
    }


    /**
     * Übergebene Nachricht und Function-Callee auf den Stack werfen
     * @param Message
     * @param Callee
     */
    error (Message, Callee = '') {
        this.ErrorStack.push(
            {
                "Message": Message,
                "Callee": Callee,
                "Order_Global": this.Order_Global,
                "Order_Error": this.Order_Error
            });
        this.Order_Global++;
        this.Order_Error++;
    }


    /**
     * Übergebene Nachricht und Function-Callee auf den Stack werfen
     * @param Message
     * @param Callee
     */
    info (Message, Callee = '') {
        this.InfoStack.push(
            {
                "Message": Message,
                "Callee": Callee,
                "Order_Global": this.Order_Global,
                "Order_Info": this.Order_Info
            });
        this.Order_Global++;
        this.Order_Info++;
    }


    /**
     *
     * @returns {{errors: [], info: []}}
     */
    get () {
        return {
            'errors': this.ErrorStack,
            'info': this.InfoStack,
            'warning': this.WarningStack
        };
    }
}