export default {
    root: ({props, context, parent}) => ({
        class: [
            'relative',

            // Alignments
            'items-center justify-center inline-flex text-center align-bottom',

            // Sizes & Spacing
            'text-base',
            {
                'px-2.5 py-1.5 min-w-[2rem]': props.size === null,
                'px-2 py-1 text-sm': props.size === 'small',
                'px-3 py-2 text-lg': props.size === 'large',
                'px-3 py-2 text-xl': props.size === 'xl',
                'px-3 py-3 text-2xl': props.size === 'xxl'
            },
            {
                'h-8 w-8 p-0': props.label == null && props.icon !== null
            },

            // Shapes
            {'shadow-sm': !props.raised && !props.link && !props.text, 'shadow': props.raised},
            {'rounded': !props.rounded, 'rounded-full': props.rounded},
            {'rounded-none first:rounded-l-md last:rounded-r-md self-center': parent.instance.$name == 'InputGroup'},

            // Link Button
            {'text-primary-600 bg-transparent ring-transparent': props.link},

            // Plain Button
            {'text-white bg-gray-500 ring-1 ring-gray-500': props.plain && !props.outlined && !props.text},
            // Plain Text Button
            {'text-surface-500': props.plain && props.text},
            // Plain Outlined Button
            {'text-surface-500 ring-1 ring-gray-500': props.plain && props.outlined},

            // Text Button
            {'bg-transparent ring-transparent': props.text && !props.plain},

            // Outlined Button
            {'bg-transparent': props.outlined && !props.plain},

            // --- Severity Buttons ---

            // Grey Button
            {
                'text-white dark:text-grey-900': !props.link && props.severity === 'grey' && !props.text && !props.outlined && !props.plain,
                'bg-grey-500 dark:bg-grey-400': !props.link && props.severity === 'grey' && !props.text && !props.outlined && !props.plain,
                'ring-1 ring-grey-500 dark:ring-grey-400': !props.link && props.severity === 'grey' && !props.text && !props.outlined && !props.plain
            },
            // Default Button
            {
                'text-white dark:text-primary-900': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain,
                'bg-primary-500 dark:bg-primary-400': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain,
                'ring-1 ring-primary-500 dark:ring-primary-400': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain
            },
            // Default Text Button
            {'text-primary-500 dark:text-primary-400': props.text && props.severity === null && !props.plain},
            // Default Outlined Button
            {'text-primary-500 ring-1 ring-primary-500 hover:bg-primary-300/20': props.outlined && props.severity === null && !props.plain},

            // Primary Button
            {
                'text-white dark:text-secondary-900': props.severity === 'primary' && !props.text && !props.outlined && !props.plain,
                'bg-primary-500 dark:bg-primary-400': props.severity === 'primary' && !props.text && !props.outlined && !props.plain,
                'ring-1 ring-primary-600 dark:ring-primary-400': props.severity === 'primary' && !props.text && !props.outlined && !props.plain
            },
            // Primary Text Button
            {'text-primary-500 dark:text-primary-400': props.text && props.severity === 'primary' && !props.plain},
            // Secondary Outlined Button
            {'text-primary-600 ring-1 ring-primary-600 hover:bg-primary-200': props.outlined && props.severity === 'primary' && !props.plain},

            // Secondary Dark Button
            {
                'text-white dark:text-primary-700': props.severity === 'primary-dark' && !props.text && !props.outlined && !props.plain,
                'bg-primary-700 dark:bg-primary-600': props.severity === 'primary-dark' && !props.text && !props.outlined && !props.plain,
                'ring-1 ring-primary-800 dark:ring-primary-600': props.severity === 'primary-dark' && !props.text && !props.outlined && !props.plain
            },
            // Secondary Dark Text Button
            {'text-primary-700 dark:text-primary-600': props.text && props.severity === 'primary-dark' && !props.plain},
            // Secondary Dark Outlined Button
            {'text-primary-800 ring-1 ring-primary-800 hover:bg-primary-400': props.outlined && props.severity === 'primary-dark' && !props.plain},


            // Secondary Button
            {
                'text-white dark:text-secondary-900': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
                'bg-secondary-500 dark:bg-secondary-400': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
                'ring-1 ring-secondary-600 dark:ring-secondary-400': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain
            },
            // Secondary Text Button
            {'text-secondary-500 dark:text-secondary-400': props.text && props.severity === 'secondary' && !props.plain},
            // Secondary Outlined Button
            {'text-secondary-600 ring-1 ring-secondary-600 hover:bg-secondary-200': props.outlined && props.severity === 'secondary' && !props.plain},

            // Secondary Dark Button
            {
                'text-white dark:text-secondary-700': props.severity === 'secondary-dark' && !props.text && !props.outlined && !props.plain,
                'bg-secondary-700 dark:bg-secondary-600': props.severity === 'secondary-dark' && !props.text && !props.outlined && !props.plain,
                'ring-1 ring-secondary-800 dark:ring-secondary-600': props.severity === 'secondary-dark' && !props.text && !props.outlined && !props.plain
            },
            // Secondary Dark Text Button
            {'text-secondary-700 dark:text-secondary-600': props.text && props.severity === 'secondary-dark' && !props.plain},
            // Secondary Dark Outlined Button
            {'text-secondary-800 ring-1 ring-secondary-800 hover:bg-secondary-400': props.outlined && props.severity === 'secondary-dark' && !props.plain},

            // Success Button
            {
                'text-white dark:text-green-900': props.severity === 'success' && !props.text && !props.outlined && !props.plain,
                'bg-green-500 dark:bg-green-400': props.severity === 'success' && !props.text && !props.outlined && !props.plain,
                'ring-1 ring-green-500 dark:ring-green-400': props.severity === 'success' && !props.text && !props.outlined && !props.plain
            },
            // Success Text Button
            {'text-green-500 dark:text-green-400': props.text && props.severity === 'success' && !props.plain},
            // Success Outlined Button
            {'text-green-500 ring-1 ring-green-500 hover:bg-green-300/20': props.outlined && props.severity === 'success' && !props.plain},

            // Info Button
            {
                'text-white dark:text-surface-900': props.severity === 'info' && !props.text && !props.outlined && !props.plain,
                'bg-grey-500 dark:bg-grey-400': props.severity === 'info' && !props.text && !props.outlined && !props.plain,
                'ring-1 ring-grey-500 dark:ring-grey-400': props.severity === 'info' && !props.text && !props.outlined && !props.plain
            },
            // Info Text Button
            {'text-grey-500 dark:text-grey-400': props.text && props.severity === 'info' && !props.plain},
            // Info Outlined Button
            {'text-grey-500 ring-1 ring-grey-500 hover:bg-grey-300/20 ': props.outlined && props.severity === 'info' && !props.plain},

            // Warning Button
            {
                'text-white dark:text-surface-900': props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
                'bg-orange-500 dark:bg-orange-400': props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
                'ring-1 ring-orange-500 dark:ring-orange-400': props.severity === 'warning' && !props.text && !props.outlined && !props.plain
            },
            // Warning Text Button
            {'text-orange-500 dark:text-orange-400': props.text && props.severity === 'warning' && !props.plain},
            // Warning Outlined Button
            {'text-orange-500 ring-1 ring-orange-500 hover:bg-orange-300/20': props.outlined && props.severity === 'warning' && !props.plain},

            // Help Button
            {
                'text-white dark:text-surface-900': props.severity === 'help' && !props.text && !props.outlined && !props.plain,
                'bg-purple-500 dark:bg-purple-400': props.severity === 'help' && !props.text && !props.outlined && !props.plain,
                'ring-1 ring-purple-500 dark:ring-purple-400': props.severity === 'help' && !props.text && !props.outlined && !props.plain
            },
            // Help Text Button
            {'text-purple-500 dark:text-purple-400': props.text && props.severity === 'help' && !props.plain},
            // Help Outlined Button
            {'text-purple-500 ring-1 ring-purple-500 hover:bg-purple-300/20': props.outlined && props.severity === 'help' && !props.plain},

            // Danger Button
            {
                'text-white dark:text-danger-900': props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
                'bg-danger-500 dark:bg-danger-400': props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
                'ring-1 ring-danger-500 dark:ring-danger-400': props.severity === 'danger' && !props.text && !props.outlined && !props.plain
            },
            // Danger Text Button
            {'text-danger-500 dark:text-danger-400': props.text && props.severity === 'danger' && !props.plain},
            // Danger Outlined Button
            {'text-danger-500 ring-1 ring-danger-500 hover:bg-danger-100': props.outlined && props.severity === 'danger' && !props.plain},

            // --- Severity Button States ---
            'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-offset-current',
            {'focus:ring-offset-2': !props.link && !props.plain && !props.outlined && !props.text},

            // Link
            {'focus:ring-primary-500 dark:focus:ring-primary-400': props.link},

            // Plain
            {'hover:bg-gray-600 hover:ring-gray-600': props.plain && !props.outlined && !props.text},
            // Text & Outlined Button
            {'hover:bg-surface-300/20': props.plain && (props.text || props.outlined)},

            // Grey
            {'hover:bg-grey-600 dark:hover:bg-grey-300 hover:ring-grey-600 dark:hover:ring-grey-300': !props.link && props.severity === 'grey' && !props.text && !props.outlined && !props.plain},
            {'focus:ring-grey-500 dark:focus:ring-grey-400': props.severity === 'grey'},
            // Text & Outlined Button
            {'hover:bg-grey-300/20': (props.text || props.outlined) && props.severity === 'grey' && !props.plain},

            // Primary
            {'hover:bg-primary-600 dark:hover:bg-primary-300 hover:ring-primary-600 dark:hover:ring-primary-300': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain},
            {'focus:ring-primary-500 dark:focus:ring-primary-400': props.severity === null},
            // Text & Outlined Button
            {'hover:bg-primary-300/20': (props.text || props.outlined) && props.severity === null && !props.plain},

            // Secondary
            {'hover:bg-secondary-600 dark:hover:bg-secondary-300 hover:ring-secondary-600 dark:hover:ring-secondary-300': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain},
            {'focus:ring-secondary-500 dark:focus:ring-secondary-400': props.severity === 'secondary'},
            // Text & Outlined Button
            {'hover:bg-secondary-300/20': (props.text || props.outlined) && props.severity === 'secondary' && !props.plain},
            // Secondary Dark
            {'hover:bg-secondary-900 dark:hover:bg-secondary-500 hover:ring-secondary-800 dark:hover:ring-secondary-500': props.severity === 'secondary-dark' && !props.text && !props.outlined && !props.plain},
            {'focus:ring-secondary-700 dark:focus:ring-secondary-600': props.severity === 'secondary-dark'},
            // Text & Outlined Button
            {'hover:bg-secondary-500': (props.text || props.outlined) && props.severity === 'secondary-dark' && !props.plain},

            // Success
            {'hover:bg-green-600 dark:hover:bg-green-300 hover:ring-green-600 dark:hover:ring-green-300': props.severity === 'success' && !props.text && !props.outlined && !props.plain},
            {'focus:ring-green-500 dark:focus:ring-green-400': props.severity === 'success'},
            // Text & Outlined Button
            {'hover:bg-green-300/20': (props.text || props.outlined) && props.severity === 'success' && !props.plain},

            // Info
            {'hover:bg-grey-600 dark:hover:bg-grey-300 hover:ring-grey-600 dark:hover:ring-grey-300': props.severity === 'info' && !props.text && !props.outlined && !props.plain},
            {'focus:ring-grey-500 dark:focus:ring-grey-400': props.severity === 'info'},
            // Text & Outlined Button
            {'hover:bg-grey-300/20': (props.text || props.outlined) && props.severity === 'info' && !props.plain},

            // Warning
            {'hover:bg-orange-600 dark:hover:bg-orange-300 hover:ring-orange-600 dark:hover:ring-orange-300': props.severity === 'warning' && !props.text && !props.outlined && !props.plain},
            {'focus:ring-orange-500 dark:focus:ring-orange-400': props.severity === 'warning'},
            // Text & Outlined Button
            {'hover:bg-orange-300/20': (props.text || props.outlined) && props.severity === 'warning' && !props.plain},

            // Help
            {'hover:bg-purple-600 dark:hover:bg-purple-300 hover:ring-purple-600 dark:hover:ring-purple-300': props.severity === 'help' && !props.text && !props.outlined && !props.plain},
            {'focus:ring-purple-500 dark:focus:ring-purple-400': props.severity === 'help'},
            // Text & Outlined Button
            {'hover:bg-purple-300/20': (props.text || props.outlined) && props.severity === 'help' && !props.plain},

            // Danger
            {'hover:bg-red-600 dark:hover:bg-red-300 hover:ring-red-600 dark:hover:ring-red-300': props.severity === 'danger' && !props.text && !props.outlined && !props.plain},
            {'focus:ring-red-500 dark:focus:ring-red-400': props.severity === 'danger'},
            // Text & Outlined Button
            {'hover:bg-red-300/20': (props.text || props.outlined) && props.severity === 'danger' && !props.plain},

            // Disabled
            {'opacity-60 pointer-events-none cursor-default': context.disabled},

            // Transitions
            'transition duration-200 ease-in-out',

            // Misc
            'cursor-pointer overflow-hidden select-none'
        ]
    }),
    label: ({props}) => ({
        class: [
            'duration-200',
            'font-semibold',
            {
                'hover:underline': props.link
            },
            {'flex-1': props.label !== null, 'invisible w-0': props.label == null}
        ]
    }),
    icon: ({props}) => ({
        class: [
            'mx-0',
            {
                'mr-2': props.iconPos == 'left' && props.label != null,
                'ml-2 order-1': props.iconPos == 'right' && props.label != null,
                'mb-2': props.iconPos == 'top' && props.label != null,
                'mt-2': props.iconPos == 'bottom' && props.label != null
            }
        ]
    }),
    loadingicon: ({props}) => ({
        class: [
            'h-3 w-3',
            'mx-0',
            {
                'mr-2': props.iconPos == 'left' && props.label != null,
                'ml-2 order-1': props.iconPos == 'right' && props.label != null,
                'mb-2': props.iconPos == 'top' && props.label != null,
                'mt-2': props.iconPos == 'bottom' && props.label != null
            },
            'animate-spin'
        ]
    }),
    badge: ({props}) => ({
        class: [{'ml-2 w-4 h-4 leading-none flex items-center justify-center': props.badge}]
    })
};
