export default {
    //When the bound element is inserted into the DOM...

    bind: function (el, binding, vnode) {
        el.classList.add('subscription-feature');
        if (!vnode.context.$userData.rights.accessSubscriptionFeatures && !vnode.context.$userData.rights.accessAlphaFeatures) {
            el.style.opacity = '0.35';
            el.style.pointerEvents = 'none';
            el.disabled = true;
        }
    }
}