import axios from "axios";

/**
 * HaxiosAPIClient ist ein axios-Client, der für die Kommunikation mit der API verwendet wird.
 */
export const haxiosAPIClient = axios.create({
    baseURL: '/api',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
});

haxiosAPIClient.defaults.withCredentials = true;
haxiosAPIClient.defaults.withXSRFToken = true;
