<template>
  <div>
    <transition-group name="bounce">
      <div v-if="messages.length > 0">
      <!-- GENERAL STYLING -->
      <div  v-for="(message, key) in messages" :key="key" class="iris-message__toast flex flex-col  cursor-pointer noselect rounded text-white shadow-lg"
           :class="{
        'text-white bg-success hover:bg-success_dark': message.type === 'success',
        'text-white bg-warning hover:bg-warning_dark': message.type === 'warning',
        'text-white bg-danger hover:bg-danger_dark': message.type === 'danger',
        'text-grey-600 bg-info hover:bg-info_light': message.type === 'confirm',
        'text-grey-700 bg-white border border-grey-400 hover:bg-info_light': message.type === 'info' || message.type === '' || message.type ==='confirm',

      }"
      >
        <div class=" flex flex-row items-start justify-start">
          <div class="iris-message__icon p-4 text-3xl">
            <!-- ICON -->
            <i v-if="message.type === 'success'" class="fas fa-check-circle"></i>
            <i v-if="message.type === 'warning'" class="fas fa-exclamation-circle"></i>
            <i v-if="message.type === 'danger'" class="fas fa-exclamation-triangle"></i>
            <i v-if="message.type === 'info'" class="fas fa-info-circle"></i>
            <i v-if="message.type === 'confirm'" class="fas fa-question-circle"></i>
          </div>
          <!-- CENTER BODY -->
          <div class="iris-message__body flex flex-col p-4 w-full">
            <div class="iris-message__title  text-xl " v-if="hasTitle(key)">{{ message.title }}</div>
            <div class="iris-message__message leading-relaxed " :class="{'text-xl py-1': !hasTitle(key)}" v-html="message.message"></div>
            <!-- CONFIRM BUTTONS -->
            <div class="iris-message__buttons-container flex flex-row justify-end " v-if="message.type==='confirm'">
              <button class=" rounded bg-grey-500 hover:bg-grey-600 text-white p-2" @click="$parent.close(message)">Abbrechen</button>
              <button class=" rounded bg-secondary_color hover:bg-secondary-800 text-white p-2 ml-4" @click="$parent.confirm(message)">OK</button>
            </div>
          </div>
          <button class="iris-message__close p-4 text-3xl hover:text-grey-300" @click="$parent.close(message)"><i class="fas fa-times"></i></button>
        </div>
        <div class="iris-message__countdown w-full h-1 bg-white opacity-50" :id="message.id" :style="`animation-duration: ${message.countdown}ms`"></div>
      </div>
      </div>

    </transition-group>


  </div>
</template>

<script>
export default {
  name: "iris-messenger-template-toast",
  props: {
    messages: {type: Array, required: false}
  },
  methods: {
    hasTitle (index) {
      return this.messages[index].title.length > 0;
    },



  },




}
</script>

<style scoped>
.iris-message__toast {
  position: fixed;
  z-index: 9999;
  bottom: 10vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 30vw;
}


.iris-message__countdown {
  animation-name: countdown;
  animation-delay: 200ms;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
  animation-direction: normal;
  animation-play-state: running;
  animation-timing-function: linear
}

@keyframes countdown {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}


.bounce-enter-active {
  animation: bounce-in 0.3s ease-in-out;
}

.bounce-leave-active {
  animation: bounce-in 0.25s reverse;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
</style>