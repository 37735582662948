<template>
  <div class="loading-dots flex flex-col items-center" :class="this.color">
    <div v-if="title.length > 0" class="font-light italic mb-2">{{ this.title }}</div>
    <div class="loading-dots__animation" :class="`${this.color} loading-dots__animation-${this.size}`">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "loading-dots",
  props: {
    title: {required: false, type: String, default: ''},
    color: {requried: false, type: String, default: 'red'},
    size: {requried: false, type: String, default: 'md'},
  }
}
</script>

<style scoped>
.loading-dots__animation {
  margin: auto auto;
  width: 70px;
  text-align: center;
}

.loading-dots__animation.white > div {
  background-color: #fff;

}

.loading-dots.white > div {
  color: #fff;
}

.loading-dots__animation.red > div {
  background-color: #B22E2F;

}

.loading-dots.red > div {
  color: #B22E2F;
}

.loading-dots__animation.green > div {
  background-color: #326470;

}

.loading-dots.green > div {
  color: #326470;
}

.loading-dots__animation-sm > div {
  width: 10px;
  height: 10px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  -moz-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  -o-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.loading-dots__animation-md > div {
  width: 14px;
  height: 14px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  -moz-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  -o-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loading-dots__animation .bounce1 {
  -webkit-animation-delay: -0.32s;
  -moz-animation-delay: -0.32s;
  -o-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loading-dots__animation .bounce2 {
  -webkit-animation-delay: -0.16s;
  -moz-animation-delay: -0.16s;
  -o-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

</style>