/**
 * Parse HTML-string as traversable HTML
 * @param HTMLString
 * @returns {Element}
 */

export function parseHTML (HTMLString) {
    let HTMLWrapper = document.createElement('HTMLWrapper');
    HTMLWrapper.innerHTML = HTMLString;
    return HTMLWrapper.firstElementChild;
}



/**
 * Formatiere den String, der aus der Aneinanderreihung von Element.textContent entsteht
 * @param SentenceString
 * @returns {*}
 */
export async function normalizeSentenceString(SentenceString) {
    return SentenceString.trim().replaceAll(" .", ".").replaceAll(" :", ":").replaceAll(" ;", ";").replaceAll(" ,", ",").replaceAll("„ ", "„").replaceAll(" “", "“").replace(/\s\s+/g,' ');
}

/**
 * Parse HTML-string as traversable HTML
 * @param HTMLString
 * @returns {Element}
 */

export function parseOuterHTML (HTMLString) {
    let HTMLWrapper = document.createElement('HTMLWrapper');
    HTMLWrapper.innerHTML = HTMLString;
    return HTMLWrapper;
}



export async function sortObjectByKey (UnorderedObject) {
    const OrderedObject = {};
    await Object.keys(UnorderedObject).sort().forEach(key => OrderedObject[key] = UnorderedObject[key]);
    return OrderedObject;
}

export function copyStringToClipboard (str) {
    // Temporäres Element erzeugen
    let el = document.createElement('textarea');
    // Den zu kopierenden String dem Element zuweisen
    el.value = str;
    // Element nicht editierbar setzen und aus dem Fenster schieben
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    // Text innerhalb des Elements auswählen
    el.select();
    // Ausgewählten Text in die Zwischenablage kopieren
    document.execCommand('copy');
    // Temporäres Element löschen
    document.body.removeChild(el);
}
/**
 * Group array of Object by a property
 * @param ArrayOfObjects
 * @param key
 * @returns {*}
 */
export function groupBy (ArrayOfObjects, key) {
    return ArrayOfObjects.reduce(
        (result, object) => ({
            ...result,
            [object[key]]: [
                ...(result[object[key]] || []),
                object,
            ],
        }),
        {},
    );
}



/**
 * Group array of Object by a property
 * @param ArrayOfObjects
 * @param key
 * @returns {*}
 */
export function groupByWithValue (ArrayOfObjects, key, value) {
    return ArrayOfObjects.reduce(
        (result, object) => ({
            ...result,
            [object[key]]: [
                ...(result[object[key]] || []),
                object[value],
            ],
        }),
        {},
    );
}

export function getArrayOfDistinctValues (ArrayOfObjects, DistinctionValue) {
    return [...new Set(ArrayOfObjects.map(Object => Object[DistinctionValue]))]
}


/**
 * Replaces ' in string with "
 * @param String
 * @returns {XML|*|void}
 */
export function substituteSingleQuotes (String) {
    return String.replace(/'/g, '"');
}



/**
 * Deep copy an object
 * @param ObjectModel
 * @returns {any}
 * @constructor
 */
export function ObjectCopy (ObjectModel) {
    return Object.assign({}, ObjectModel);
}



/**
 * Apply class to element for certain amount of time
 * @param Element
 * @param ClassName
 * @param Milliseconds
 */
export function toggleClassFor (Element, ClassName, Milliseconds) {
    Element.classList.add(ClassName);
    setTimeout(function () {
        Element.classList.remove(ClassName);
    }, Milliseconds);
}



/**
 * Determines if int is divisable by divisor
 * @param int
 * @param divisor
 * @returns {boolean}
 */
export function isDivisableBy (int, divisor) {
    return parseInt(int) % divisor === 0;
}
export function formatDate (date) {
    return new Date(date).toLocaleString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });
}

/**
 * Checks if needle is not yet in haystack
 */
export function notInArray (needle, haystack) {
    return haystack.indexOf(needle) === -1;
}



export function isOverflowing (Element) {
    let curOverflow = Element.style.overflow;

    if (!curOverflow || curOverflow === "visible")
        Element.style.overflow = "hidden";

    let isOverflowing = Element.clientWidth < Element.scrollWidth
        || Element.clientHeight < Element.scrollHeight;

    Element.style.overflow = curOverflow;

    return isOverflowing;
}



/**
 * Waiting function
 * @param ms
 * @returns {Promise<any>}
 */
export let wait = ms => new Promise((r, j) => setTimeout(r, ms));

//
// Folds a string at a specified length, optionally attempting
// to insert newlines after whitespace characters.
//
// s          -  input string
// n          -  number of chars at which to separate lines
// useSpaces  -  if true, attempt to insert newlines at whitespace
// a          -  array used to build result
//
// Returns an array of strings that are no longer than n
// characters long.  If a is specified as an array, the lines
// found in s will be pushed onto the end of a.
//
// If s is huge and n is very small, this metho will have
// problems... StackOverflow.
//

export let fold = function (s, n, useSpaces, a) {
    a = a || [];
    if (s.length <= n) {
        a.push(s);
        return a;

    }
    let line = s.substring(0, n);
    if (!useSpaces) { // insert newlines anywhere
        a.push(line);
        return fold(s.substring(n), n, useSpaces, a);
    } else { // attempt to insert newlines after whitespace
        let lastSpaceRgx = /\s(?!.*\s)/;
        let idx = line.search(lastSpaceRgx);
        let nextIdx = n;
        if (idx > 0) {
            line = line.substring(0, idx);
            nextIdx = idx;
        }
        a.push(line);
        return fold(s.substring(nextIdx), n, useSpaces, a);
    }
}



/**
 * Regex version of fold function.
 * @param s
 * @param n
 * @returns {*}
 */
function foldRgx (s, n) {
    let rgx = new RegExp('.{0,' + n + '}', 'g');
    return s.match(rgx);
}



/**
 * Array of lines is handled regarding its whitespaces
 * @param string
 * @param linelength
 * @returns {*|string}
 */
export let correctWhitespacesForLineLength = function (string, linelength) {
    return (fold(string, linelength, true)).map((line) => {
        // If first character is a linebreak && line contains any letters (i.e. is not mere linebreak(s))...
        if (line.substring(0, 2).match(/\n/g) && line.match(/\S/g)) {
            // ...trim line of it's whitespaces
            return line.trim() + '\n'
        } else {
            // If first charachter is a ' '...
            if (line.substring(0, 1) === ' ')
            // ...remove it
                return line.substring(1) + '\n';
            else
                return line + '\n';
        }
    }).join('');
};
/**
 * Array of lines is handled regarding its whitespaces
 * @param string
 * @param linelength
 * @returns {*|string}
 */
export let correctWhitespacesForLineLength2 = function (string, linelength) {
    let folded = (fold(string, linelength, true)).map(line => {
        return line.trim();
    });
    //return folded.join('').split('\n');
    return folded;
};

/**
 * Recursively removes spaces from beginning until there are none
 * @param {string}string
 * @returns {*}
 */
export let removeSpacesFromFront = function (string) {
    if (string.substring(0, 1) === ' ')
        return string.substring(1);
    else
        return string.substring(0);
};



/**
 *
 * @param strTextAreaId
 * @constructor
 */
export let applyLineBreaks = function (strTextAreaId) {
    let oTextarea = document.getElementById(strTextAreaId);
    if (oTextarea.wrap) {
        oTextarea.setAttribute("wrap", "off");
    } else {
        oTextarea.setAttribute("wrap", "off");
        let newArea = oTextarea.cloneNode(true);
        newArea.value = oTextarea.value;
        oTextarea.parentNode.replaceChild(newArea, oTextarea);
        oTextarea = newArea;
    }

    let strRawValue = oTextarea.value;
    oTextarea.value = "";
    let nEmptyWidth = oTextarea.scrollWidth;
    let nLastWrappingIndex = -1;



    function testBreak (strTest) {
        oTextarea.value = strTest;
        return oTextarea.scrollWidth > nEmptyWidth;
    }



    function findNextBreakLength (strSource, nLeft, nRight) {
        let nCurrent;
        if (typeof (nLeft) == 'undefined') {
            nLeft = 0;
            nRight = -1;
            nCurrent = 64;
        } else {
            if (nRight == -1)
                nCurrent = nLeft * 2;
            else if (nRight - nLeft <= 1)
                return Math.max(2, nRight);
            else
                nCurrent = nLeft + (nRight - nLeft) / 2;
        }
        let strTest = strSource.substr(0, nCurrent);
        let bLonger = testBreak(strTest);
        if (bLonger)
            nRight = nCurrent;
        else {
            if (nCurrent >= strSource.length)
                return null;
            nLeft = nCurrent;
        }
        return findNextBreakLength(strSource, nLeft, nRight);
    }



    let i = 0, j;
    let strNewValue = "";
    while (i < strRawValue.length) {
        let breakOffset = findNextBreakLength(strRawValue.substr(i));
        if (breakOffset === null) {
            strNewValue += strRawValue.substr(i);
            break;
        }
        nLastWrappingIndex = -1;
        let nLineLength = breakOffset - 1;
        for (j = nLineLength - 1; j >= 0; j--) {
            let curChar = strRawValue.charAt(i + j);
            if (curChar == ' ' || curChar == '-' || curChar == '+') {
                nLineLength = j + 1;
                break;
            }
        }
        strNewValue += strRawValue.substr(i, nLineLength) + "\n";
        i += nLineLength;
    }
    oTextarea.value = strNewValue;
    oTextarea.setAttribute("wrap", "");
    return strNewValue;
    /*
        document.getElementById("pnlPreview").innerHTML = oTextarea.value.replace(new RegExp("\\n", "g"), "<br />");
    */
}

/**
 * Sort function for Array.sort()
 * @param string1
 * @param string2
 * @returns {number}
 */
export let sortByStringValues = function (string1, string2) {
    if (string1 < string2) {
        return -1;
    }
    if (string1 > string2) {
        return 1;
    }
    return 0;
}

/**
 * Wandelt eine Wortart in die Model-Klasse um
 * @param wortart
 * @returns {*|string}
 */
export const castWortartToClassName = (wortart) => {
    switch (wortart) {
        case 'adjektiv':
            return 'App\\Models\\Adjektiv';
        case 'eigenname':
            return 'App\\Models\\Eigenname';
        case 'nomen':
            return 'App\\Models\\Nomen';
        case 'verb':
            return 'App\\Models\\Verb';
        case 'pronomen':
            return 'App\\Models\\Pronomen';
        case 'partikel':
            return 'App\\Models\\Partikel';
        case 'wendung':
            return 'App\\Models\\Wendung';
        case 'numerale':
            return 'App\\Models\\Numerale';
        default:
            return wortart;
    }
}

