<script>
export default {
  name: "iris-messenger-template-flash",
  props: {
    messages: {type: Array, required: false}
  },
  methods: {
    hasTitle (message) {
      return message.title !== undefined && (message.title.length > 0);
    },



  },



}
</script>

<template>

  <transition-group name="slide-in-right" tag="div" class="iris-message__flash absolute">
    <div v-for="(message, key) in messages" :key="key" class="iris-message__flash__message fixed flex items-start  bottom-10  right-10  p-4  shadow"
         :class="`bg-${message.type} text-${message.type}-contrast border-l-3 border-${message.type}-dark`">
      <div class="iris-message__flash__message__content">
        <div v-if="hasTitle(message)" class="iris-message__flash__message__content__title text-sm mb-2">
          {{ message.title }}
        </div>
        <div class="iris-message__flash__message__content__body text-lg " :class="`text-${message.type}-contrast`">
          {{ message.message }}
        </div>

      </div>
      <button @click="$parent.close(message)" class="ml-4 text-sm hover:text-white rounded-full" >
        <i class="fas fa-x"></i>
      </button>
    </div>
  </transition-group>
</template>

<style>
.slide-in-right-enter-active, .slide-in-right-leave-active {
  transition: transform 0.25s ease-in-out;
}
.slide-in-right-enter, .slide-in-right-leave-to {
  transform: translateX(150%);
}


</style>