

export async function formatHexameter (scansion) {
    let Feet = []
    await splithexameter();
    return Feet.length === 6 ? Feet : false;



    async function splithexameter () {
        let CurrentFoot = 1;

        let Foot = "";
        scansion.split("").forEach(Quantity => {
            if (!isDactyl(Foot) && !isSpondeus(Foot) && !isSpondeusAnceps(Foot) && (Quantity !== ' ')) {
                Foot = Foot + Quantity;
            }
            if (isDactyl(Foot) || isSpondeus(Foot) || isSpondeusAnceps(Foot)) {
                Feet.push(Foot);
                Foot = "";
                CurrentFoot = Foot + 1;
            }

        })
    }


}



export async function formatPentameter (scansion) {
    let Feet = []
    await splitpentameter();
    return Feet.length === 6 ? Feet : false;



    async function splitpentameter () {
        let CurrentFoot = 1;

        let Foot = "";
        scansion.split("").forEach(Quantity => {
            if (!isDactyl(Foot) && !isSpondeus(Foot) && !isSpondeusAnceps(Foot) && (Quantity !== ' ')) {
                if (Feet.length === 2 || Feet.length === 5) {
                    if (Foot.length < 1) {
                        Foot = Quantity
                        Feet.push(Foot)
                        Foot = "";
                    }

                } else {
                    Foot = Foot + Quantity;
                }
            }
            if (isDactyl(Foot) || isSpondeus(Foot) || isSpondeusAnceps(Foot)) {
                Feet.push(Foot);
                Foot = "";
                CurrentFoot = Foot + 1;
            }

        })
    }
}



function isDactyl (string) {
    return string === "-UU"
}



function isSpondeus (string) {
    return string === "--"
}



function isSpondeusAnceps (string) {
    return string === "-x"
}



export function formatHendecasyllabus (scansion) {
    return false;
}