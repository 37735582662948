

<template>
  <div class="no-outline" id="iris-messenger">
    <iris-messenger-template-bar  :messages="IrisMessengerStore.barMessages"/>
    <iris-messenger-template-dialog  :messages="IrisMessengerStore.dialogMessages"/>
    <iris-messenger-template-toast :messages="IrisMessengerStore.toastMessages"/>
    <iris-messenger-template-confirm  :messages="IrisMessengerStore.confirmMessages"/>
    <iris-messenger-template-flash  :messages="IrisMessengerStore.flashMessages"/>
    <iris-messenger-template-loader  :messages="IrisMessengerStore.loaderMessages"/>
  </div>
</template>

<script>
import HermeneusIcon from '../../hermeneus.icons/HermeneusIcon.vue'
import {EventBus} from "@/hermeneus.eventbus/eventbus.js";
import {useIrisMessengerStore} from "@/iris-messenger/IrisMessengerStore.js";
import IrisMessengerTemplateToast from "@/iris-messenger/components/IrisMessengerTemplateToast.vue";
import IrisMessengerTemplateDialog from "@/iris-messenger/components/IrisMessengerTemplateDialog.vue";
import IrisMessengerTemplateConfirm from "@/iris-messenger/components/IrisMessengerTemplateConfirm.vue";
import IrisMessengerTemplateFlash from "@/iris-messenger/components/IrisMessengerTemplateFlash.vue";
import IrisMessengerTemplateLoader from "@/iris-messenger/components/IrisMessengerTemplateLoader.vue";
import IrisMessengerTemplateBar from "./IrisMessengerTemplateBar.vue";

/**
 * Komponente wird über eine Blade-Component im master-index-view eingebunden
 * resources/views/components/__iris_messenger.blade.php
 */
export default {
  name: "iris-messenger",
  components: {
    IrisMessengerTemplateBar,
    IrisMessengerTemplateLoader,
    IrisMessengerTemplateFlash,
    IrisMessengerTemplateConfirm,
    IrisMessengerTemplateDialog,
    IrisMessengerTemplateToast,
    'hermeneus-icon': HermeneusIcon,
  },
  props: {
    template: {type: String, required: false, default: 'dialog'}
  },

  setup () {
    const IrisMessengerStore = useIrisMessengerStore();
    return {IrisMessengerStore}
  },


  methods: {
    /**
     * Close message (remove from message-array)
     * @param clickedMessage
     */
    close (clickedMessage) {
      this.IrisMessengerStore.close(clickedMessage.id)
    },
    /**
     * What happens if user presses confirm-button
     * @param message
     */
    confirmAction (message) {
      this.IrisMessengerStore.confirmAction(message)
    },
    hasTitle (message) {
      return message.title.length > 0;
    },
  },

}
</script>

<style lang="sass" scoped>
$warning: #F0AD4E
$success: #5cb85c
$secondary_color: #B22E2F
.iris-message__veil
  position: fixed
  top: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.6)
  z-index: 10

.iris-message-wide
  width: 100%
  border-radius: 0
  border: 0
  border-bottom: 1px solid white

  &.danger
    background: #D9534F
    color: #fff

  &.success
    background: #5cb85c
    color: #fff

  &.info
    background: #F0AD4E
    color: #fff

  &.confirm
    background: #eee
    color: #666


.iris-message-wide__body
  width: 100%
  display: flex
  justify-content: space-between

.iris-message-wide__body__title
  width: 20%

.iris-message-wide__body__content
  width: 70%
  display: flex

.iris-message-wide__body__close
  width: 10%
  text-align: right

.iris-message-frame
  width: 100%
  border-radius: 0

.iris-message-frame.im_colors
  border-radius: 5px

  &.danger
    border: 0.2rem solid #5cb85c
    color: $warning

  &.success
    border: 0.2rem solid $success
    color: $success

  &.info
    border: 0.2rem solid #999
    color: #999

  &.confirm
    border: 0.2rem solid #999
    color: #999

.iris-message-frame.im_background, .iris-message-frame__body.im_background
  &.danger
    background: $warning
    color: #fff

  &.success
    background: $success
    color: #fff

  &.info
    background: #fff
    color: $secondary_color

    .iris-message-frame__body__close
      button
        color: $secondary_color

  &.loading
    background: #fff
    color: $secondary_color

    .iris-message-frame__body__close
      button
        color: $secondary_color

  &.confirm
    background: #fff
    color: #999

    .iris-message-frame__body__content__buttons-container .button-alt
      background: #ccc
      color: #666

      &:hover
        background: #bbb

    .iris-message-frame__body__content__buttons-container .button-normal
      background: #5cb85c
      color: #fff

      &:hover
        background: #4d994d

  .iris-message-frame__body__close button
    color: #fff


.iris-message-frame__body--with-veil
  position: fixed
  z-index: 9999


.iris-message-frame__body
  width: 100%
  display: flex
  justify-content: space-between


.iris-message-frame__body__title
  color: #999

.iris-message-frame__body__content
  text-align: center

.iris-message-frame__body__close
  width: 10%
  font-weight: bold
  text-align: right

  button
    font-weight: bold


.iris-message
  width: 50%
  border: 1px solid #ddd
  margin: 0 auto 0.25rem auto
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 3px
  font-size: 0.85rem

.iris-message__head
  height: 1.5rem
  padding: 0.25rem
  background: #ddd
  display: flex
  justify-content: space-between

  &.white
    display: none


.iris-message__head__title
  font-size: 0.8rem
  color: #fff

.iris-message__body
  display: flex
  justify-content: flex-start
  flex-wrap: wrap
  padding: 0.5rem

  .icon
    padding: 0


.iris-message__body__message
  margin-left: 1rem
  max-width: 80%

.iris-message__body__buttons-container
  flex-basis: 100%
  text-align: right


//.iris-message--confirm

//.iris-message--danger

.iris-message-thumb
  min-height: 100%

.danger .iris-message-frame__body__close button
  color: $warning

.success .iris-message-frame__body__close button
  color: $success

.info .iris-message-frame__body__close button
  color: #999

.confirm .iris-message-frame__body__close button
  color: #999

.iris-message__head__close button
  border-radius: 3px
  font-size: 1rem
  padding: 0
  height: 1rem
  width: 1rem
  line-height: 1rem
  background: none
  color: #75868a

.iris-message-frame__body__content__buttons-container button:hover
  background: #326470
</style>