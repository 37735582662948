import ElementArray from "../../helpers/class_elements";
import EditorHelpers from "../../helpers/class_editorhelpers";
import Helpers from "../../../../helpers/class_helpers";

/**
 * Basic class for every tool in the toolbTar
 */
export default class Tool {
    constructor (name, id, config, behaviour, appearance, options, transformation) {
        this.name = name;
        this.id = id;
        this.config = config;
        this.Attributes = {
            operation_id_attribute: this.name + '_operation_id',
            order_attribute: this.name + '_order',
        };
        this.behaviour = behaviour;
        this.options = options;
        this.appearance = appearance;
        // Assign 0 if toolinstance doesn't have Group-property
        this.appearance.Group = appearance.Group || 0;
        this.transformation = transformation;
        this.GreyMode = this.isGreyMode();
        this.active = false;
        this.Operations = [];
        this.OperationId = 0; // Extended by AnswerTool (0097)
        this.OperationModels = [];
        this.SelectedElements = [];
        this.LoadingStatus = false;
        this.SignificantAttribute = this.Attributes.operation_id_attribute;
        this.registerBoundaryClasses();
    }


    isGreyMode () {
        if (this.config.hasOwnProperty('DelimitedBy')) {
            return !this.DelimitingTool.Operations.length > 0;
        } else {
            return false
        }
    }


    /**
     * Returns ToolInstance the current MarkingTool is delimited by
     * @returns {null}
     * @constructor
     */
    get DelimitingTool () {
        return this.config.hasOwnProperty('DelimitedBy') ? this.config.DelimitedBy[0] : null;
    }


    sync () {
    }



    syncModel () {
    }



    terminate () {


    }



    transform () {
    }



    /**
     * Registers names of boundary classes
     */
    registerBoundaryClasses () {
        if (this.config.hasOwnProperty('Boundaries') && this.config.Boundaries) {
            this.boundary_start_class = this.name + '_boundary_start';
            this.boundary_end_class = this.name + '_boundary_end';
            this.boundary_single_class = this.name + '_boundary_single';
        }
    }



    /*
     * Register all ElementArray, that can be affected by the tool
     * Note 2019_12_08: $EditorStore.Node_Text was not accessible in Edge; Substituted by Helpers.parseHTML(window.$EditorStore.Text)
     */
    registerElements () {
        this.AffectedElements = window.$EditorStore.Node_Text.querySelectorAll(this.config.AffectedElements);
    }



    /**
     * Remove the classes that were set in appearance.
     * @param Element
     * @type {Element}
     */
    removeBoundaryClasses (Element) {
        Element.classList.remove(this.boundary_single_class);
        Element.classList.remove(this.boundary_start_class);
        Element.classList.remove(this.boundary_end_class);
    }



    /**
     * Determines if the left MouseButtonPressed is currently pressed
     * cf. Vue-Instance/Vue-Template
     */
    static LeftMouseButtonIsPressed () {
        return window.MouseButtonIsPressed === true;
    }



    /**
     * Determines if the left Mousebutton is currently pressed
     * @param MouseEvent
     * @param MouseEvent.which
     * @param MouseEvent.buttons
     */
    static LMBisPressed (MouseEvent) {
        MouseEvent = MouseEvent || window.event;
        if ("buttons" in MouseEvent) {
            return MouseEvent.buttons === 1;
        }
        /** @namespace MouseEvent.button **/
        let button = MouseEvent.which || MouseEvent.button;
        return button === 1;
    }



    /**
     * If first child of new segment is a <br>, switch display to none
     * @param NewSegment
     */
    static hideFirstHTMLBreak (NewSegment) {
        let FirstElementChild = NewSegment.firstElementChild;
        if (FirstElementChild.nodeName === 'BR') {
            FirstElementChild.style.display = 'none';
        }
    }



    /**
     * Returns all selected ElementArray + preceding Linebreaks
     * @returns {Array}
     * @constructor
     */
    getSelectedElements (WithPrecedingLBs = true) {
        let OperationElements = this.SelectedElements;
        if (WithPrecedingLBs === true) {
            let OperationElements = [];
            this.SelectedElements.forEach(Element => {
                // If the ElementArray previous Sibling is a lb-Element, add it to array
                if (Tool.previousSiblingIsLB(Element)) {
                    OperationElements.push(Element.previousElementSibling);
                }
                OperationElements.push(Element);
            });
        }
        return ElementArray.sortByOrder(OperationElements);
    }



    /**
     * Returns true if the element is not wrapped within another element/tag
     * of a certain NodeType ('P', 'SPAN', 'SEG', etc...)
     * @param Element.parentNode
     */
    static isNotContainedBy (Element, NodeName) {
        if (Element.parentNode.nodeName !== NodeName) {
            return true;
        } else {
            return false;
        }
    }



    /**
     *  Returns a MarkingToolInstance if selected element is already marked
     */
    getToolOfIdentifier (AttributeValue, ToolClass = Tool) {
        // If selected element has one of the attributes, return true
        // Get attributes of all MarkingTool-Instances
        let ToolInstances = this.getToolInstancesOf(ToolClass);
        let FirstToolInstance = ToolInstances.filter(function (MarkingToolInstance) {
            if (AttributeValue === MarkingToolInstance.Attributes.operation_id_attribute) {
                return MarkingToolInstance;
            }
            ;
        });

        return FirstToolInstance[0];
    }



    /**
     * Returns true if the Element that is currently selected is adjacent
     * to any of the elements that are already selected
     * @param Element
     * @param SelectedElements
     * @param Attribute
     * @returns {boolean}
     */
    isAdjacent (Element, SelectedElements, Attribute) {
        let CurrentSelectedElementNumber = Element.getAttribute(Attribute);
        let AmountOfSelectedElements = SelectedElements.length;

        // Do not proceed, if the array of selected elements is empty
        if (AmountOfSelectedElements === 0) {
            return true;
        }

        // To be simplified
        for (let i = 0; i < AmountOfSelectedElements; i++) {

            let AlreadySelectedElementNumber = SelectedElements[i].getAttribute(Attribute);

            if (EditorHelpers.areConsecutiveNumbers(AlreadySelectedElementNumber, CurrentSelectedElementNumber)) {
                return true;
            }

        }
        return false;
    }



    static isLB (Element) {
        if (Element.nodeName === 'LB') {
            return true;
        }
    }



    /**
     * Get max. operation_id from all Operations and increment this number
     * Why? E.g. when text is loaded
     * Entweder es sind schon Sätze vorhanden => Die OperationID ist die höchste Zahl + 1; Andernfalls ist sie 0;
     * @param OperationsArray
     * @returns {Promise<number|number>}
     */
    async incrementedMaxOrZero (OperationsArray) {
        return OperationsArray.length > 0 ? Math.max.apply(Math, OperationsArray.map(Operation => Operation.operation_id)) + 1 : 0;
    }


    /**
     * Entweder es sind schon Sätze vorhanden => Die OperationID ist die höchste Zahl + 1; Andernfalls ist sie 1;
     * @param OperationsArray
     * @param Property
     * @returns {Promise<number|number>}
     */
    async incrementedMaxOrOne (OperationsArray, Property = "operation_id") {
        return OperationsArray.length > 0 ? Math.max.apply(Math, OperationsArray.map(Operation => Operation[Property])) + 1 : 1;
    }


    /**
     * @param Element
     * @param Element.previousElementSibling
     */
    static previousSiblingIsLB (Element) {
        let previousSibling = Element.previousElementSibling;
        if (previousSibling !== null) {
            if (previousSibling.nodeName === 'LB') {
                return true;
            }
        }
    }



    static isBR (Element) {

        if (Element.nodeName === 'BR') {
            return true;
        }
    }



    /**
     * Filters through an array of ElementArray and returns
     * elements with a specific attribute value
     */
    getElementsByAttributeValue (ArrayOfElements, AttributeName, AttributeValue) {
        let MarkedElements = [];
        ArrayOfElements.forEach(function (MarkableElement) {
            if (MarkableElement.getAttribute(AttributeName) === AttributeValue) {
                MarkedElements.push(MarkableElement);
            }
        });

        return MarkedElements;
    }



    /**
     * Filters through an array of ElementArray and returns
     * elements with a specific attribute value
     */
    getElementsByAttribute (Nodelist, AttributeName) {
        return Array.from(Nodelist).filter(
            /**
             *
             * @param {HTMLElement} Element
             * @returns {boolean}
             */
            Element => {
                return Element.hasAttribute(AttributeName);
            });
    }



    /**
     * Filters out Linebreak-ElementArray <lb> from ElementArray
     * @param ElementArray
     */
    static filterOutLB (ElementArray) {
        return ElementArray.filter(function (Element) {
            return Element.nodeName !== 'LB';
        });

    }



    /**
     * Formats text elements.
     * Interjects ..., if text elements are not consecutive in order
     * @param Elements
     * @param ExludeNodeNames
     * @returns {string}
     */
    formatTextElements (Elements, ExludeNodeNames = ['LB', 'MILESTONE']) {
        return Helpers.formatTextElements(Elements, ExludeNodeNames, this.Attributes.order_attribute);
    }



    /**
     * Assign all marked ElementArray to their own array and sort them by their order in the text
     */
    getAllMarkings (MarkedElements, MarkingAttribute) {
        let self = this;
        let AllMarkings = [];
        let AllMarkingIds = [];

        MarkedElements.forEach(function (MarkedElement) {

            let SingleMarkingId = MarkedElement.getAttribute(MarkingAttribute);

            if (EditorHelpers.notInArray(SingleMarkingId, AllMarkingIds)) {
                AllMarkingIds.push(SingleMarkingId);
            }

        });

        AllMarkingIds.forEach(function (MarkingId) {

            AllMarkings[MarkingId] = [];

            MarkedElements.forEach(function (MarkedElement) {

                if (MarkedElement.getAttribute(MarkingAttribute) === MarkingId) {
                    AllMarkings[MarkingId].push(MarkedElement);
                    // If the ElementArray previous Sibling is a lb-Element, add it to array
                    if (Tool.previousSiblingIsLB(MarkedElement)) {
                        AllMarkings[MarkingId].push(MarkedElement.previousElementSibling);
                    }
                }
            });
        });

        // Sort items by order
        AllMarkings.forEach(function (MarkedElementsArray) {
            ElementArray.sortByOrder(MarkedElementsArray);
        });


        return AllMarkings;
    }



    /**
     * Get all instances of a registered ToolClass
     */
    getToolInstancesOf (ToolClass, NotSelf = true) {

        let ActiveTool = this;
        let MarkingToolInstances = [];

        window.$EditorStore.Tools.forEach(Tool => {
            //if (Tool instanceof ToolClass) {
            if (Tool.constructor === ToolClass) {
                if (NotSelf === true && Tool.name === ActiveTool.name) {
                    return;
                }
                MarkingToolInstances.push(Tool);
            }
        });
        return MarkingToolInstances;
    }



    /**
     * Returns an array of all attributes, that were registered in the
     * Attribute-property of the registered tools
     */
    getAllOperationIdAttributes () {
        return EditorHelpers.flattenArray(window.$EditorStore.Tools.map(SingleTool => {
            return SingleTool.Attributes.operation_id_attribute;
        }));
    }



    /**
     * Returns boundary class of Tool if Boundaries were defined on instance
     * Filters out null values
     * @returns {Array.<*>}
     * @constructor
     */
    get BoundaryClasses () {
        return [
            this.config.Boundaries ? this.boundary_start_class : null,
            this.config.Boundaries ? this.boundary_end_class : null,
            this.config.Boundaries ? this.boundary_single_class : null,
        ].filter(item => item !== null);
    }



    /**
     * Assigns ToolInstance.name + '_operation_id', ToolInstance.OperationId as
     * attribute and value to selected element
     * @param ElementArray
     * @param AttributeName
     * @param Value
     */
    setOperationIdAttribute (ElementArray, Value) {
        ElementArray.forEach(Element => {
            Element.setAttribute(this.Attributes.operation_id_attribute, Value.toString());
        });
    }



    /**
     * Returns true if the given object or property
     * has a specific property
     */
    static hasProperty (ObjectOrProperty, Property) {
        if (ObjectOrProperty === null) {
            return false;
        }
        if (ObjectOrProperty instanceof Object && typeof ObjectOrProperty !== 'undefined') {
            if (Property in ObjectOrProperty) {
                /*if (ObjectOrProperty.hasOwnProperty(Property)) {*/
                return true;
            }
        }
    }



    /**
     * Checks whether the elements in the fragmented elements-array are in consecutive order
     * and returns key of first element that is not consecutive to its previous element
     * @param NonConsecutiveElementsArray
     * @param OrderAttribute
     * @returns {number | boolean}
     */
    static getSliceIndex (NonConsecutiveElementsArray, OrderAttribute = 'order') {
        for (let i = 1; i < NonConsecutiveElementsArray.length; i++) {
            let OrderPrevious = NonConsecutiveElementsArray[i - 1].getAttribute(OrderAttribute);
            let OrderThis = NonConsecutiveElementsArray[i].getAttribute(OrderAttribute);
            // Use this for Debugging
            //console.log(`Element ${NewOperationElements[i].textContent} \nOrder:  ${OrderThis} \nKey:  ${i} \nis not consecutive!`);
            if (OrderThis - OrderPrevious !== 1) {
                // Index of first element that is not consecutive by "OrderAttribute"
                return i;
            }
        }
        return false;
    }



    /**
     * Checks whether the elements in the fragmented elements-array are in consecutive order
     * and returns key of first element that is not consecutive to its previous element
     * @param NonConsecutiveElementsArray
     * @param OrderAttribute
     * @returns {Array}
     */
    static getSliceIndices (NonConsecutiveElementsArray, OrderAttribute = 'order') {
        let SliceIndices = [];
        for (let i = 1; i < NonConsecutiveElementsArray.length; i++) {
            let OrderPrevious = NonConsecutiveElementsArray[i - 1].getAttribute(OrderAttribute);
            let OrderThis = NonConsecutiveElementsArray[i].getAttribute(OrderAttribute);
            // Use this for Debugging
            //console.log(`Element ${NewOperationElements[i].textContent} \nOrder:  ${OrderThis} \nKey:  ${i} \nis not consecutive!`);
            if (OrderThis - OrderPrevious !== 1) {
                // Index of first element that is not consecutive by "OrderAttribute"
                SliceIndices.push(NonConsecutiveElementsArray[i]);
            }
        }
        return SliceIndices;
    }



    /**
     * Return true if a NodeElement (given by its index) within an array is not consecutive by 'OrderAttribute'
     * @param ElementArray
     * @param ElementKey
     * @param OrderAttribute
     * @returns {boolean}
     */
    static isNotConsecutive (ElementArray, ElementKey, OrderAttribute = 'order') {

        if (ElementArray.length > 1 && ElementKey !== 0) {
            let OrderPrevious = ElementArray[ElementKey - 1].getAttribute(OrderAttribute);
            let OrderThis = ElementArray[ElementKey].getAttribute(OrderAttribute);
            if (OrderThis - OrderPrevious !== 1) {
                return true;
            }
        }
        return false;
    }



    /**
     * Returns specific Operation from ToolInstance by its operation_id
     * @param ToolInstance
     * @param OperationId
     * @returns {Object} Operation
     */
    static getOperationOf (ToolInstance, OperationId) {
        return ToolInstance.Operations.find(function (Operation) {
            return Operation.operation_id === OperationId;
        });
    }



    /**
     * Returns specific Operation by its operation_id
     * @param OperationId
     * @returns {Object} Operation
     */
    getOperation (OperationId) {
        return this.Operations.find(function (Operation) {
            return Operation.operation_id == OperationId;
        });
    }



    /**
     * Get all elements of all operations of this tool
     * @returns {Array}
     * @constructor
     */
    get AllOperationElements () {
        let AllOperationElements = this.Operations.map(Operation => {
            return Operation.elements;
        });
        if (AllOperationElements.length > 0) {
            return AllOperationElements.reduce((accumulator, element) => {
                return accumulator.concat(element);
            });
        }
        return [];
    }



    /**
     * Add appearanceClass to ClassList
     * @param Element
     * @type {Element}
     */
    addAppearanceClass (Element) {
        Element.classList.add(this.appearance.CssClass);
    }



    /**
     * SubtrahendArray is substraced from MinuendArray, filtering it by "Attribute"
     * @param SubtrahendArray (NEED TO CONTAIN DOM-ElementArray!)
     * @param MinuendArray (NEED TO CONTAIN DOM-ElementArray!)
     * @param Attribute
     */
    static subtractElementArraysByAttribute (SubtrahendArray, MinuendArray, Attribute) {
        return MinuendArray.filter(function (OperationElement) {
            return EditorHelpers.notInArray(OperationElement, SubtrahendArray) && OperationElement.hasAttribute(Attribute);
        });
    }
}
