<template>
  <div class="lds-dual-ring" :class="color"></div>
</template>

<script>
export default {
  name: "loading-dual-ring",
  props: {
    color: {
      required: false,
      default: 'red'
    }
  }
}
</script>

<style scoped>
.lds-dual-ring {
  display: inline-block;
  width: 12px;
  height: 12px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 10px;
  height: 10px;
  margin: 2px;
  border-radius: 50%;

  animation: lds-dual-ring 1.2s linear infinite;
}

.red:after {
  border: 1px solid #B22E2F;
  border-color: #B22E2F transparent #B22E2F transparent;
}
.white:after {
  border: 1px solid #fff;
  border-color: #fff transparent #fff transparent;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>