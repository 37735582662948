import {ToolOperation} from "./class_tooloperation";
import Tool from "./class_tool";
import ElementArray from "../../helpers/class_elements";
import EditorHelpers from "../../helpers/class_editorhelpers";

/**
 * This is an abstract class
 */
export default class WrappingTool extends Tool {
    constructor (name, id, config, behaviour, appearance, options, transformation) {
        super(name, id, config, behaviour, appearance, options, transformation);
        this.SelectedElements = [];
        this.AffectedElements = [];
        this.Operations = [];
        this.OperationId = 0;
        this.Loadable = true;
        this.MouseDown = this.MouseDownEvent;
        this.MouseOut = this.MouseOutEvent;
        this.MouseOver = this.MouseOverEvent;
        this.MouseUp = this.MouseUpEvent;
        if (new.target === WrappingTool) {
            throw new TypeError("WrappingTool is an abstract class: Cannot construct instances directly!");
        }
    }



    /**
     * Initialize tool functionality
     * Registers SelectableElements
     * Registers EventListeners
     */
    async init () {
        await this.registerElements();
        this.numerateAffectedElements();
        this.registerEventListeners();


    }



    /**
     * Analyze all marked elements and assign differend markings to
     * specific arrays
     */
    sync () {
        this.syncedOperations.then(syncedOperations => {
            this.Operations = syncedOperations;
            if (this.config.Boundaries === true) {
                this.applyElementStyle();

            }
        });
    }


    /**
     * Abstract Operations from DOM after loading a hermeneus-xml string
     * @returns {Promise<void>}
     */
    async load () {
        this.Operations = await this.readOperationsFromDOM(window.$EditorStore.Node_Text);
    }


    /**
     * Synchronizes Operation models by abstracting tool operations from model DOM
     */
    async syncModel () {
        this.OperationModels = await this.syncOperationModels();
    }



    /**
     * Terminate the activity of current tool;
     */
    terminate () {
        this.removeEventListeners();
    }



    /**
     * @param Element
     * @type Element
     */
    erase (Element) {
        this.removeBoundaryClasses(Element);
        Element.classList.remove(this.appearance.CssClass);
        Element.removeAttribute(this.Attributes.operation_id_attribute);
    }



    /**
     * For each selection-process a selection-id is assigned to the selected elements
     * The selection-id is incremental and increases, whenever the left mouse-button
     * is released.
     */
    incrementOperationId () {
        this.OperationId++;
    }



    /**
     * Numerate the elements that were declared as markable with an attribute (Toolname + _order)
     */
    numerateAffectedElements () {
        this.AffectedElements.forEach((NumeratedMarkableElement, index) => {
            NumeratedMarkableElement.setAttribute(this.Attributes.order_attribute, index);
        });
    }



    /**
     * Add element to this.SelectedElements
     * @param Element
     */
    select (Element) {
        if (EditorHelpers.notInArray(Element, this.SelectedElements)) {
            this.SelectedElements.push(Element);
        }
    }



    /**
     * Add Event-Listeners for editable ElementArray (<w> and <pc>)
     */
    registerEventListeners () {
        // Iterate through all elements that where declared as markable
        this.AffectedElements.forEach(AffectedElement => {
            AffectedElement.addEventListener('mouseout', this.MouseOut, false);
            AffectedElement.addEventListener('mousedown', this.MouseDown, false);
            AffectedElement.addEventListener('mouseover', this.MouseOver, false);
        });
        document.addEventListener('mouseup', this.MouseUp, false);
    }



    /**
     * Remove Event-Listeners for editable ElementArray (<w> and <pc>)
     */
    removeEventListeners () {
        this.AffectedElements.forEach(AffectedElement => {
            AffectedElement.removeEventListener('mousedown', this.MouseDown, false);
            AffectedElement.removeEventListener('mouseout', this.MouseOut, false);
            AffectedElement.removeEventListener('mouseover', this.MouseOver, false);
        });
        document.removeEventListener('mouseup', this.MouseUp, false);
    }



    /**
     * Apply style classes for first and last or single element
     * @param Elements
     * @param FirstElement
     * @param LastElement
     */
    addBoundaryStyle (Elements, FirstElement, LastElement) {
        // Set Boundaries
        if (ElementArray.NumberOfVisible(Elements) > 1) {

            FirstElement.classList.add(this.boundary_start_class);
            LastElement.classList.add(this.boundary_end_class);
        } else {
            FirstElement.classList.add(this.boundary_single_class);
        }
    }



    /**
     * Remove style classes for all elements
     * @param Elements
     * @type Array
     */
    removeBoundaryStyle (Elements) {
        /*
                if (ElementArray.NumberOfVisible(Elements) > 1) {
        */
        Elements.forEach(
            Element => {
                this.removeBoundaryClasses(Element);
            });
        /*        } else {
                    /!**
                     * @param Element
                     * @type Element
                     *!/
                    Elements.classList.remove(this.boundary_single_class);
                }*/
    }



    /**
     * Event on mouseover
     */
    get MouseOutEvent () {
        // Remove declared CSS-Class as hover-effect
        return (Event) => {
            // this = target of MouseEvent (Element)
            Event.target.classList.remove(this.appearance.CssClassHover)
        };
    };



    /**
     * Event on mousedown
     */
    get MouseDownEvent () {

        return (Event) => {
            this.addAppearanceClass(Event.target);
            // operation_id is applied in MouseUpEvent
            this.select(Event.target);
        };
    };



    /**
     * Event on mouseover
     */
    get MouseOverEvent () {
        return (Event) => {
            // Add declared CSS-Class as hover-effect
            Event.target.classList.add(this.appearance.CssClassHover);
            if (this.canSelect(Event.target, Event)) {
                this.addAppearanceClass(Event.target);
                this.removeBoundaryClasses(Event.target);
                this.select(Event.target);
            }
        }
    };



    /**
     * Event on mouseup
     */
    get MouseUpEvent () {
        return (Event) => {
            this.finishOperation(this.OperationId);
        }
    }



    /**
     *
     * @param OperationId
     */
    finishOperation (OperationId = this.OperationId) {

        if (this.config.Overlap === false) {
            this.removeToolAttributesFromArray(this.SelectedElements);
        }
        this.setOperationIdAttribute(this.SelectedElements, OperationId);
        if (this.SelectedElements.length !== 0) {
            this.incrementOperationId();
            ToolOperation.record(this.SelectedElements, this.appearance.Title);
            ToolOperation.dispatchFinishedEvent();
        }
        this.SelectedElements = [];
    }



    /**
     * Handles overlapping markings
     * Removes attributes from another MarkingTool-Instance
     * @param ElementArray
     * @param Tool
     */
    removeToolAttributesFromArray (ElementArray, Tool = this.constructor) {
        let MarkingToolInstances = this.getToolInstancesOf(Tool);
        let MarkingToolAttributes = EditorHelpers.flattenArray(MarkingToolInstances.map(MarkingToolInstance => {
            return [MarkingToolInstance.Attributes.operation_id_attribute,];
        }));
        let MarkingToolClasses = EditorHelpers.flattenArray(MarkingToolInstances.map(MarkingToolInstance => {
            return [MarkingToolInstance.BoundaryClasses, MarkingToolInstance.appearance.CssClass];
        }));

        MarkingToolAttributes.forEach(MarkingToolAttribute => {
            ElementArray.forEach(Element => {
                Element.removeAttribute(MarkingToolAttribute);
            });
        });
        MarkingToolClasses.forEach(MarkingToolClass => {
            ElementArray.forEach(
                /** @param Element
                 * @type {Element}*/
                Element => {
                    Element.classList.remove(MarkingToolClass);
                });
        });
    }



    /**
     * Assign all marked ElementArray to their own array and sort them by their order in the text
     */
    syncOperations () {
        this.syncedOperations.then(syncedOperations => {
            this.Operations = syncedOperations;
        });
    }



    /**
     * Resolves abstracted Operations
     * @returns {Promise}
     */
    get syncedOperations () {
        return new Promise((resolve, reject) => {
            resolve(this.readOperationsFromDOM(window.$EditorStore.Node_Text));
        });
    }



    /**
     * Assign all marked ElementArray to their own array and sort them by their order in the text
     */


    /**
     * Abstract DOMElements to a separate copy of ToolOperations named 'OperationModels'
     * Why: We need an exact copy of visible DOM-Elements that will be transformed
     * and exported.
     */
    async syncOperationModels () {
        return await this.readOperationsFromDOM(window.$EditorStore.Node_Model_Text);
    }



    /**
     * Return an Array of operations that are abstracted by a set of DOM-elements
     * @param DOMNode
     * @returns {Array}
     */
    async readOperationsFromDOM (DOMNode) {
        let DOMElements = DOMNode.querySelectorAll(this.config.AffectedElements);
        let ElementsWithOperationID = this.getElementsByAttribute(DOMElements, this.Attributes.operation_id_attribute);
        let AllOperationIds = this.extractOperationIds(ElementsWithOperationID);
        let Operations = [];
        for await (let operation_id of AllOperationIds) {
            let OperationElements = this.getOperationElements(operation_id, ElementsWithOperationID, DOMNode) || [];
            Operations.push(new ToolOperation(operation_id, OperationElements));
            if (ElementArray.containsNonConsecutiveElements(this.getOperationElements(operation_id, ElementsWithOperationID, DOMNode))) {
                OperationElements = ElementArray.sliceOperationOldElements(OperationElements);
                let OperationElements_NonConsecutive = ElementArray.sliceOperationNewElements(OperationElements);
                this.setOperationIdAttribute(OperationElements_NonConsecutive, this.OperationId);
                Operations.push(new ToolOperation(this.OperationId.toString(), OperationElements_NonConsecutive));
                this.incrementOperationId();
            }
        }
        return Operations;
    }



    /**
     * Extracts array of OperationIds from all ElementArray with OperationIds
     * @param ElementsWithOperationID
     * @returns {Array}
     */
    extractOperationIds (ElementsWithOperationID) {
        let self = this;
        let AllOperationIds = [];
        ElementsWithOperationID.forEach(function (MarkedElement) {
            let OperationIdString = MarkedElement.getAttribute(self.Attributes.operation_id_attribute);
            // Falls das Attribute mehrere Operation-Ids hat (operation_id = "0 1")
            OperationIdString.split(' ').forEach(OperationId => {
                if (EditorHelpers.notInArray(OperationId, AllOperationIds)) {
                    AllOperationIds.push((OperationId));
                }
            });
        });
        return AllOperationIds;
    }



    /**
     * Create a new Operation on ToolInstance with a given OperationId from given ElementArray
     * @param OperationId
     * @param ElementsWithOperationID
     * @param setOperationIdAttributeAccordingly
     * @returns {ToolOperation}
     */
    createOperation (OperationId, ElementsWithOperationID, setOperationIdAttributeAccordingly = false) {
        if (setOperationIdAttributeAccordingly) {
            this.setOperationIdAttribute(ElementsWithOperationID, this);
        }
        // Create new Tooloperation
        return new ToolOperation(OperationId, this.getOperationElements(OperationId, ElementsWithOperationID, window.$EditorStore.TextNode));
    }



    /**
     * Checks if all conditions are true to mark selected element
     * Conditions are defined on the object instance
     */
    canSelect (Element, MouseEvent) {
        let self = this;

        // Which Event is handled?
        let EventType = MouseEvent.type;

        // If EvenType was set to fire even if LeftMouseButton is not pressed
        if (self.behaviour[EventType].MouseButtonPressed === false) {
            return true;
        }
        // Where only adjacent elements enabled for selection in the object instance?
        if (self.behaviour[EventType].Adjacent === true) {
            if (window.MouseButtonIsPressed === true &&
                // If Element is not yet in the array of selected elements ...
                self.SelectedElements.indexOf(Element) === -1 &&
                // ... and is adjacent to another Element in the array of SelectedElements
                self.isAdjacent(Element, self.SelectedElements, self.Attributes.order_attribute)
            ) {
                return true;
            }
        } else if (window.MouseButtonIsPressed === true &&
            // If Element is not yet in the array of selected elements ...
            self.SelectedElements.indexOf(Element) === -1) {
            return true;
        }

    }



    /**
     * Collect Operation-elements, check if any Elements are missing and sort them by order
     * @param OperationId
     * @param ElementsWithOperationID
     * @param DOMNode
     */
    getOperationElements (OperationId, ElementsWithOperationID, DOMNode) {
        let OperationElements = this.checkOperationIDAttribute(ElementsWithOperationID, OperationId);
        OperationElements = ElementArray.checkMissingElements(OperationElements, DOMNode);
        return ElementArray.sortByOrder(OperationElements);
    }




    /**
     * Compare ID of Operation with the operation-id-attribute of the DOM-Element
     * @param ElementsWithOperationID
     * @param OperationId
     */
    checkOperationIDAttribute (ElementsWithOperationID, OperationId) {
        return ElementsWithOperationID.filter(ElementWithOperationID => {

            let OperationIDString = ElementWithOperationID.getAttribute(this.Attributes.operation_id_attribute).toString();
            let OperationIDArray = OperationIDString.split(' ');
            // Strict Comparison
            if (OperationIDString === OperationId.toString()) {
                // If the ElementArray previous Sibling is a lb-Element, add it to array
                return ElementWithOperationID;
            }
            else if(OperationIDArray.includes(OperationId)) {
                return ElementWithOperationID;
            }
        });
    }



    /**
     * Element is already selected?
     * @param Element
     */
    hasOperationIdAttribute (Element) {
        return Element.hasAttribute(this.Attributes.operation_id_attribute);
    }


}