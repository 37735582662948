export default class WordAnalysisContainer {
    constructor (Analysis, Line, Keyword, WordElement) {

        this.line = Line;
        this.wordelement = WordElement;
        this.analysis = Analysis;
        this.keyword = Keyword;
    }




    static get EmptyAnalysis () {
        return [{
            "id": "",
            "info": "",
            "lemma": "",
            "pos": "",
            "morpho": [],
            "bedeutung": "",
            "is_accepted_reading": false,
        }];

    }



}