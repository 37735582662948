import {EventBus} from "../../hermeneus.eventbus/eventbus";
import axios from "axios";


/**
 * Stellt Nutzerdaten als Global Property zur Verfügung
 */
export let providesUserDataPlugin = {
    install: function (app, options) {
        app.config.globalProperties.$userData = window.UserData || false;

        app.config.globalProperties.$fetchUserData = async () => {
            try {
                const response = await axios.get('/profile/userdata');
                app.config.globalProperties.$userData = response.data;
            } catch (error) {
                console.error('Fehler beim Laden der Nutzerdaten:', error);
            }
        };

    },

}