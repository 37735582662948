/**
 * DO NOT IMPORT CLASSES THAT ARE DEPENDENT ON THIS CLASS
 * => circular dependency
 */
export default class EditorHelpers {


    /**
     * Returns true if the two given numbers are consecutive (e.g. 2,3 or 5,6)
     */
    static areConsecutiveNumbers (Number1, Number2) {
        if (EditorHelpers.hasDifferenceOf(Number1, Number2, 1)) {
            return true;
        }
    }


    /**
     * Decodes HTML-Entities to normal text (;nbsp => ' ')
     * Prevents errors by DOMDocument-Parser
     * @param InnerHTML
     * @returns {string}
     */
    static decodeHtmlEntities (InnerHTML) {
        let TempTextarea = document.createElement("textarea");
        TempTextarea.innerHTML = InnerHTML;
        return TempTextarea.value;

    }


    /**
     *
     * @param string
     * @returns {*}
     */
    static convertStraightQuotesToCurlyQuotes (string) {
        return string
            /* opening singles */
            .replace(/(^|[-\u2014\s(\["])'/g, "$1\u201a")

            /* closing singles & apostrophes */
            .replace(/'/g, "\u2018")

            /* opening doubles */
            .replace(/(^|[-\u2014/\[(\u2018\s])"/g, "$1\u201e")

            /* closing doubles */
            .replace(/"/g, "\u201c")

            /* em-dashes */
            .replace(/--/g, "\u2014");
    }


    /**
     * Decodes CERTAIN HTML-Entities to normal text (;nbsp => ' ')
     * Prevents errors by DOMDocument-Parser
     * @param encodedString
     * @returns {string}
     */
    static decodeStringEntities (encodedString) {
        let translate_re = /&(nbsp|amp|quot|lt|gt);/g;
        let translate = {
            "nbsp": " ",
            "amp": "&",
            "quot": "\"",
            "lt": "<",
            "gt": ">"
        };
        return encodedString.replace(translate_re, function (match, entity) {
            return translate[entity];
        }).replace(/&#(\d+);/gi, function (match, numStr) {
            let num = parseInt(numStr, 10);
            return String.fromCharCode(num);
        });
    }


    /**
     * Returns all Elements in an array of elements that are not consecutive by their 'order'-attribute
     * @param NumberArray
     * @returns {Array}
     */
    static getMissingNumbers (NumberArray) {

        let NonConsecutiveNumbers = [];
        checkInconsecutiveNumbers(NumberArray);



        // Recursive function that creates an array of numbers that are missing in a consecutive array
        function checkInconsecutiveNumbers (NumberArray) {
            for (let i = 1; i < NumberArray.length; i++) {
                if (NumberArray[i] - NumberArray[i - 1] !== 1) {
                    let NonConsecutiveNumber = parseInt(NumberArray[i]) - 1;
                    NonConsecutiveNumbers.push(NonConsecutiveNumber);
                    NumberArray.push(NonConsecutiveNumber);
                    NumberArray.sort((a, b) => a - b);
                    checkInconsecutiveNumbers(NumberArray)
                }
            }
        }



        return NonConsecutiveNumbers;
    }


    static getSentenceStringFromElementArray (ElementArray) {
        return ElementArray.map(Element => Element.innerHTML.trim()).join(' ');
    }


    static getSentenceAbbreviationString (Sentence) {
        if (Sentence.elements.length > 6) {
            return [
                Sentence.elements[0].innerHTML,
                Sentence.elements[1].innerHTML,
                Sentence.elements[2].innerHTML,
                ' ... ',
                Sentence.elements[Sentence.elements.length - 3].innerHTML,
                Sentence.elements[Sentence.elements.length - 2].innerHTML,
                Sentence.elements[Sentence.elements.length - 1].innerHTML,
            ].join('');
        } else {
            return Sentence.elements.map(element => element.innerHTML).join('');
        }
    }


    /**
     * @param Toolnames
     */
    static getTools (Toolnames) {
        return window.$EditorStore.Tools.filter(Tool => {
            return EditorHelpers.inArray(Tool.name, Toolnames);
        });
    }


    /**
     * @param Commandnames
     */
    static getCommands (Commandnames) {
        return window.$EditorStore.Commands.filter(Command => {
            return EditorHelpers.inArray(Command.name, Commandnames);
        });
    }


    /**
     * Parse HTML-string as traversable HTML
     * @param HTMLString
     * @returns {Element}
     */
    static parseHTML (HTMLString) {
        let HTMLWrapper = document.createElement('HTMLWrapper');
        HTMLWrapper.innerHTML = HTMLString;
        return HTMLWrapper.firstElementChild;
    }


    /**
     * Remove an attribute value from an attribute that has multiple values divided by ' ';
     * E.G.: <element attribute="value1 value2 value3">
     * @param Element
     * @param Attribute
     * @param Value
     */
    static removeAttributeFromAttributes (Element, Attribute, Value) {
        Element.setAttribute(Attribute, Element.getAttribute(Attribute).split(' ').filter(String => String !== Value).join(' '));
    }


    static addAttributeMultiple (Element, Attribute, Value) {
        let AttributeValueArray = Element.getAttribute(Attribute) ? Element.getAttribute(Attribute).split(' ') : [];
        AttributeValueArray.push(Value);
        Element.setAttribute(Attribute, AttributeValueArray.join(' '));
    }


    /**
     * Parse attribute with multiple values as array
     * @param Element
     * @param Attribute
     * @returns {string[]}
     */
    static getAttributeMultiple (Element, Attribute) {
        if (Element.hasAttribute(Attribute)) {
            let AttributeValue = Element.getAttribute(Attribute);
            if (AttributeValue.length > 0) {
                return AttributeValue.split(' ');
            }
        }
        return [];
    }


    /**
     * Assigns multiple values to an attribute separated by ' '
     * ModifierPrefix is added as a prefix to the values
     * @param Element
     * @param AttributeName
     * @param ValuesArray
     * @param ModifierPrefix
     */
    static setAttributeValueMultiple (Element, AttributeName, ValuesArray, ModifierPrefix = false) {
        if (ModifierPrefix) {
            // Append every value to prefix
            ValuesArray = ValuesArray.map(Value => {
                return ModifierPrefix + Value;
            });
        }
        let ValuesString = ValuesArray.length > 1 ? ValuesArray.join(' ') : ValuesArray[0];
        Element.setAttribute(AttributeName, ValuesString);
    }


    /**
     * Return the first element in array that is not of type [NodeName]
     * e.g. not <lb>
     * @param NodeName
     * @param ElementArray
     */
    static findFirstNodeThatIsNot (NodeName, ElementArray) {
        return ElementArray.find(Element => {
            return Element.nodeName !== NodeName;
        });
    }


    /**
     * @param Toolname
     */
    static getTool (Toolname) {
        return window.$EditorStore.Tools.find(Tool => {
            return Tool.name === Toolname;
        });
    }


    /**
     * @param Commandname
     */
    static getCommand (Commandname) {
        return window.$EditorStore.Commands.find(Tool => {
            return Tool.name === Commandname;
        });
    }



    /**
     * Returns true if
     * @param Key
     * @param Array
     * @returns {boolean}
     */
    static isNotLastKey (Key, Array) {
        return Key !== Array.length - 1;
    }


    /**
     * Reads the -attribute of the Element: pattern s#x (x = Stepnumber)
     * @param Element
     * @returns {string}
     */
    static readStepNumberAttribute (Element) {
        let nAttribute = Element.getAttribute('n');
        if (nAttribute && nAttribute.match(/s.*#.*/gi)) {
            return nAttribute.split('#')[1]

        }
    }


    /**
     * Returns true if the two given numbers have a certain difference
     * when subtracted from another
     */
    static hasDifferenceOf (Number1, Number2, Difference) {
        if (Number1 - Number2 === Difference) {
            return true;
        } else if (Number2 - Number1 === Difference) {
            return true;
        } else {
            return false;
        }
    }



    /**
     * Checks if needle is not yet in haystack
     */
    static notInArray (needle, haystack) {
        return haystack.indexOf(needle) === -1;
    }



    /**
     * Checks if needle is already in haystack
     */
    static inArray (needle, haystack) {
        return haystack.indexOf(needle) > -1;
    }


    /**
     * Flattens multidimensional array
     */
    static flattenArray (MultidimensionalArray) {
        let self = this;
        return MultidimensionalArray.reduce(
            (acc, val) => acc.concat(
                Array.isArray(val) ? self.flattenArray(val) : val
            ),
            []);
    }


    /**
     * Injects custom CSS into <head><style>
     * @param CSSString
     */
    static injectCSS (CSSString) {
        let newStyles = document.createElement('style');
        document.head.append(newStyles);
        newStyles.innerHTML = CSSString;
    }


    /**
     * Returns true if the given argument is an array and is not empty
     */
    static isNotEmpty (ArrayOrObject) {

        if (typeof ArrayOrObject === Array) {
            if (ArrayOrObject.length > 0) {
                return true;
            }
        }
    }

}