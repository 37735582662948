import Helpers from "../helpers/class_helpers";
import API from "../editor-reader/global/js/class_api";

export default class WordAnalyzer {

    /**
     * Read Analysis from DOM or (if not possible) retrieve from API
     * @returns {*}
     */
    static analyze (WordElement, AttributeName, API, Data = {}) {
        if (WordAnalyzer.isExtractable(WordElement, AttributeName)) {
            return WordAnalyzer.analyzeFromText(WordElement, AttributeName);
        }
        return WordAnalyzer.analyzeFromAPI(WordElement.textContent, API, Data);
    }


    /**
     * Reads analysis from DOM
     * @param DOMElement
     * @param AttributeName
     * @returns {*}
     */
    static analyzeFromText (DOMElement, AttributeName) {
        // Parse attribute to JSON and replace single quotes with double quotes
        let WordAnalyseisFromText = DOMElement.hasAttribute(AttributeName) && DOMElement.getAttribute(AttributeName) ? JSON.parse(Helpers.substituteSingleQuotes(DOMElement.getAttribute(AttributeName))) : [];
        if (WordAnalyseisFromText.hasOwnProperty('Lemmata') && WordAnalyseisFromText.Lemmata.length > 0) {
            return WordAnalyseisFromText;
        } else if (!WordAnalyseisFromText.hasOwnProperty('Lemmata') && WordAnalyseisFromText.length > 0) {
            return  WordAnalyzer.convertFormat(WordAnalyseisFromText);
        } else {
            return WordAnalyzer.EmptyAnalysis;
        }
    }


    /**
     * Convert to 0098-Format
     * @param WordAnalyseisFromText
     * @returns {{Format: string, Lemmata: *[], Form: string, config: {}}}
     */
    static convertFormat (WordAnalyseisFromText) {
        let EmptyWordAnalysis = WordAnalyzer.EmptyAnalysis;
        WordAnalyseisFromText.forEach(WordAnalysis => {
            EmptyWordAnalysis.Lemmata.push({
                    "id": WordAnalysis.id,
                    "info": WordAnalysis?.info ?? '',
                    "lemma": WordAnalysis.lemma,
                    "pos": WordAnalysis.wortart,
                    "morpho": WordAnalysis.bestimmungen,
                    "bedeutung": WordAnalysis.bedeutung,
                    "is_accepted_reading": (WordAnalysis?.isActual ?? WordAnalysis.isActualLemma) ?? false,
                });
        });
                return EmptyWordAnalysis;
    }


    /**
     * Is WordAnalyzer extractable from WordElement
     * @param DOMElement
     * @param AttributeName
     * @returns {boolean|boolean}
     */
    static isExtractable (DOMElement, AttributeName) {
        return DOMElement.hasAttribute(AttributeName) && DOMElement.getAttribute(AttributeName).length > 0;
    }


    /**
     * Retrieve Word analysis from API
     * @param Keyword
     * @param API_URL
     * @param Data
     */
    static async analyzeFromAPI (Keyword, API_URL, Data) {
        let Form = WordAnalyzer.sanitizeKeyword(Keyword);
        return await API.hermeneus_analysis(Form, API_URL, Data)
    }



    /**
     * Sanitize keyword for analysis-API-request via route
     * @param Keyword
     * @returns {string}
     */
    static sanitizeKeyword (Keyword) {
        return Keyword.trim().toLowerCase();
    }



    static get EmptyLemmataBag () {
        return {
            "id": "",
            "info": "",
            "lemma": "",
            "pos": "",
            "morpho": [],
            "bedeutung": "",
            "is_accepted_reading": false,
        };
    }


    static get EmptyAnalysis () {
        return {
            Form: '',
            Format: 'hermeneusv3',
            config: {},
            Lemmata: []
        };

    }



}