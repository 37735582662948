export class ToolOperation {
    /**
     *
     * @param operation_id
     * @param elements
     * @param n For displaying purposes
     */
    constructor (operation_id, elements, n = null) {
        this.operation_id = operation_id;
        this.elements = elements;
        this.n = n;
    }



    static get FinishedEvent () {
        return new Event('tooloperationfinished');
    }



    static record (SelectedElementsArray, Toolname) {
        window.$EditorStore.updateToolHistoryContainer({
            'tool': Toolname,
            'subject': SelectedElementsArray.length > 0 ? SelectedElementsArray.map(Element => Element.textContent).join(' ') : 'etwas'
        });
        window.$EditorStore.LogStack.info(`${Toolname} -> Target: ${SelectedElementsArray.length > 0 ? SelectedElementsArray.map(Element => Element.textContent).join(' ') : 'etwas'}` ,this.constructor.name)
    }


    static dispatchFinishedEvent () {
        document.dispatchEvent(ToolOperation.FinishedEvent);
    }

}
