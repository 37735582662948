<template>
  <div class="hermeneus-icon"
       v-html="svgContent"
       :style="{ width: `${width}px`, height: `${height}px` }"
       :class="colorClass"
       ref="iconRef">
  </div>
</template>

<script>
import {ref, onMounted, watch, computed} from 'vue';

const cache = new Map();

/**
 * Mit dieser Komponente können SVG-Icons dynamisch eingebunden werden.
 * Die Icons werden nur geladen, wenn sie im Viewport sichtbar sind.
 * Mit dem Prop 'name' wird der Name des Icons übergeben, das sich im Ordner 'public/img/svg' befindet.
 * (Beispiel: 'name="icon-name"' lädt das Icon 'icon-name.svg')
 * Als Farbe kann eine TailwindCSS-Klasse oder eine eigene Farbe übergeben werden.
 * Individuelle Farben können in der Datei 'custom-colors.css' definiert werden.
 * @file HermeneusIcon.vue
 * @description Komponente für das Einbinden von SVG-Icons
 * @module HermeneusIcon
 * @version 1.0.0
 */

export default {
  name: 'HermeneusIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      default: '15',
    },
    height: {
      type: [String, Number],
      default: '15',
    },
    color: {
      type: [String],
      default: 'current',
    },

    viewbox: {
      type: [Number, String],
      default: null,
    },
    strict: {
      type: Boolean,
      default: false,
    },
    cache: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    /**
     * Gibt die Farbe des Icons zurück.
     * @returns {string}
     */
    colorClass () {
      if (this.color.startsWith('text-')) {
        return `current ${this.color}`;
      } else if (this.color.startsWith('hi-color-')) {
        return `${this.color}`;
      } else if (this.color === 'current') {
        return 'current';
      } else {
        return `text-${this.color}`;
      }



    }
  },
  setup (props, {refs}) {
    const svgContent = ref('');
    const iconRef = ref(null);

    const iconSrc = computed(() => `/img/svg/${props.name}.svg`);

    const loadSVG = async () => {
      if (!cache.has(iconSrc.value)) {
        try {
          const response = await fetch(iconSrc.value);
          const text = await response.text();
          cache.set(iconSrc.value, text);
        } catch (e) {
          cache.delete(iconSrc.value);
        }
      }
      if (cache.has(iconSrc.value)) {
        svgContent.value = await cache.get(iconSrc.value);
      }
    };
    /**
     * Lazy loading mit der IntersectionObserver API
     * @type {IntersectionObserver}
     */

    const observer = new IntersectionObserver(
        async (entries) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            await loadSVG();
            if (iconRef.value) {
              observer.unobserve(iconRef.value);
            }
          }
        },
        {
          rootMargin: '0px',
          threshold: 0.1,
        }
    );

    onMounted(() => {
      if (iconRef.value) {
        observer.observe(iconRef.value);
      }
    });

    watch(
        () => props.name,
        async () => {
          if (iconRef.value && cache.has(iconSrc.value)) {
            svgContent.value = await cache.get(iconSrc.value);
          }
        }
    );

    return {
      svgContent,
      iconRef,
    };
  },
};
</script>


<style>

@import './css/custom-colors.css';

.hermeneus-icon {
  display: inline-block;
}

.current path,
.current circle,
.current polygon,
.current *,
.current rect {
  fill: currentColor;
}


</style>