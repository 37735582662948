<template>
    <div id="modal">
        <transition-group name="zoom">
            <loading-dots v-if="isLoading" :key="1" class="modal_loader"></loading-dots>
            <hermeneus-modal
                    :title="ModalData.title"
                    :uri="ModalData.uri"
                    :type="ModalData.type"
                    :size="ModalData.size"
                    :data="ModalData.data"
                    :prop="ModalData.prop"
                    :component="ModalData.component"
                    :appearance="ModalData.appearance"
                    v-if="showModal"
                    :key="2"
                    @close="close">

            </hermeneus-modal>
        </transition-group>

    </div>
</template>

<script>
    import HermeneusModal from "./HermeneusModal.vue";
    import {EventBus} from '../hermeneus.eventbus/eventbus'
    import {getModalData, getModalDataFromAttribute} from "./functions";
    import {wait} from "../helpers/functions_helpers";

    export default {
        name: "ModalOpener",
        components: {
            'hermeneus-modal': HermeneusModal
        },
        data: function () {
            return {
                showModal: false,
                isLoading: false,
                ModalData: {},
                ModalContent: {},
            }
        },
        methods: {
            /**
             * Adds click-Event to every element that has properties of 'CSSSelector'
             * Why? That way we can store modal data inline as JSON
             */
            addEventListeners: function (CSSSelector) {
                let ModalButtons = document.querySelectorAll(CSSSelector);
                ModalButtons.forEach(ModalButton => {
                    ModalButton.addEventListener('click', e => {
                        (async () => {
                            this.$data.ModalData = await getModalDataFromAttribute(e.currentTarget);
                            this.$data.showModal = true;
                        })();

                    }, false);
                });
            },
            close () {
                this.showModal = false;
                this.isLoading = false;
            }
        },
        mounted: function () {
            this.addEventListeners('.js-opens-modal');
            EventBus.$on('OpenModalEvent', data => {
                (async () => {
                    this.isLoading = true;
                    this.$data.ModalData = await getModalData(data);
                    this.$data.showModal = true;
                    this.isLoading = false;
                })();
            });
            EventBus.$on('CloseModalEvent', () => {
                this.close();
            })
        },
    }
</script>

<style scoped>
    .modal_loader {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    .modal-background {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        background-color: rgba(10, 10, 10, 0.86);
        z-index: 99900;

    }




</style>