export default {
    //When the bound element is inserted into the DOM...
    bind: function (el, binding, vnode) {
        el.classList.add('advanced-feature');
        if (!vnode.context.$userData.rights.accessAdvancedFeatures) {
            el.style.display = 'none';
            el.style.pointerEvents = 'none';
            el.disabled = true;
        }
    }
}