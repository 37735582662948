import FarbeTool from "../classes/class_farbetool";
import ToolOptionsFarbe from "./ToolOptionsFarbe.vue";

export const Farbe1 = new FarbeTool('farbe1', 'btn_farbe1', {
        AffectedElements: "w, pc",
        Overlap: true,
    },
    {
        mouseover: {
            Adjacent: true,
        },
        click: {
            Adjacent: false,
        },
    }, {
        Color: '#ED7D31',
        ColorLight: 'rgba(237, 125, 49, 0.5)',
        CssWhenActive: true,
        CssWhenOtherToolsActive: false,
        CssClass: 'farbe1',

        // box-shadows can be stacked on top of each other
        CssHover: `box-shadow: inset 0 -4px 0 0 rgb(237, 125, 49, 0.5);`,
        CssApplied: `box-shadow: inset 0 -4px 0 0 rgb(237, 125, 49, 1);`,
        CssClassHover: '',
        Cursor: 'custom',
        CursorURL: "/img/editor/cursors/ablabsmarker.png",
        Group: 3,
        Icon: "<img src='/img/svg/hermeneus-btn-farbe1.svg'>",
        Title: 'Farbe 1',
    }, {
        Description:
            `   
            <span class="hermeneus-editor__tool-description bold emphasize">Farbliche Markierung hinzufügen</span>`, Introduction: `
            Markieren Sie Wörter bei gedrückter Maustaste oder durch Klicken farblich. Mit Klick auf 'Fertig' speichern Sie Ihre Auswahl.`,
             ToolOptions: ToolOptionsFarbe
    }, {
        attributes: {
            function: 'farbe'
        },
        hierarchy: 3,
    });
