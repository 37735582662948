<template>

  <div class="iris-messenger__template--confirm">
    <!-- GENERAL STYLING -->
    <div v-if="messages.length > 0">
    <div  v-for="(message, key) in messages" :key="key"
         class="iris-message__toast flex flex-col  cursor-pointer noselect rounded shadow-lg"
         :class="{
        'text-white bg-success': message.type === 'success',
        'text-white bg-warning': message.type === 'warning',
        'text-white bg-danger': message.type === 'danger',
        'text-grey-600 bg-info': message.type === 'confirm',
        'text-grey-700 bg-white border border-grey-400': message.type === 'info' || message.type === '' || message.type ==='confirm',

      }"
    >
      <div class=" flex flex-row items-start justify-start">
        <div class="iris-message__icon p-4 text-3xl">
          <!-- ICON -->
          <i v-if="message.type === 'success'" class="fas fa-check-circle"></i>
          <i v-if="message.type === 'warning'" class="fas fa-exclamation-circle"></i>
          <i v-if="message.type === 'danger'" class="fas fa-exclamation-triangle"></i>
          <i v-if="message.type === 'info'" class="fas fa-info-circle"></i>
          <i v-if="message.type === 'confirm'" class="fas fa-question-circle"></i>
        </div>
        <!-- CENTER BODY -->
        <div class="iris-message__body flex flex-col p-4 w-full">
          <div class="iris-message__title font-bold  text-xl " v-if="hasTitle(message)">{{ message.title }}</div>
          <div class="iris-message__message leading-relaxed mt-3 text-lg" :class="{'text-xl py-1': !hasTitle(message)}" v-html="message.message"></div>
          <!-- CONFIRM BUTTONS -->
          <div class="iris-message__buttons-container flex flex-row justify-end mt-4 text-lg" >
            <button class=" rounded bg-grey-500 hover:bg-grey-600 text-white p-3" @click="$parent.close(message)">Abbrechen</button>
            <button class=" rounded bg-secondary_color hover:bg-secondary-800 text-white p-3 ml-4" @click="$parent.confirmAction(message)">OK</button>
          </div>
        </div>
        <button class="iris-message__close p-4 text-3xl hover:text-grey-300" @click="$parent.close(message)"><i class="fas fa-times"></i></button>
      </div>
      <div class="iris-message__countdown w-full h-1 bg-white opacity-50" :id="message.id" :style="`animation-duration: ${message.countdown}ms`"></div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "iris-messenger-template-confirm",
  props: {
    messages: {type: Array, required: false}
  },
  methods: {
    hasTitle (message) {
      return message.title.length > 0;
    },



  },



}
</script>

<style scoped>
.iris-message__toast {
  position: fixed;
  z-index: 9999;
  top: 20vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}


.iris-message__countdown {
  animation-name: countdown;
  animation-delay: 200ms;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
  animation-direction: normal;
  animation-play-state: running;
  animation-timing-function: linear
}

@keyframes countdown {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}



</style>