import AssignationTool from "./class_assignationtool";
import TEIXMLTransformer from "../../transformation/class_teitransformer";
import EditorHelpers from "../../helpers/class_editorhelpers";
import ElementArray from "../../helpers/class_elements.js";
import Notify from "../../notify/notify.class.js";

/**
 * A tool to colorize specific elements in the text
 * - User can provide a description with the color
 * - TEI-XML: Descriptions are saved inside <link>-tags inside a <linkGrp>
 * - TEI-XML: Linked elements will be tagged with a ana-Attribute (<w ana="color1">)
 * - HERMENEUS-XML: Descriptions are saved inside <hermeneus-link>-tags inside <hermeneus-links>
 * - Reader will provide buttons to display all colorized elements
 */
export default class FarbeTool extends AssignationTool {

    constructor (name, id, config, behaviour, appearance, options, transformation) {
        super(name, id, config, behaviour, appearance, options, transformation);
        this.Operations = [];
        this.EditMode = false;
        this.CurrentOperation_DescriptionInput = '';
        this.description = '';
        this.MouseUp = this.MouseUpEvent;
        this.CurrentOperation_Id = this.OperationId;
    }



    /**
     * Initialize tool functionality
     * Registers SelectableElements
     * Registers EventListeners
     */
    async init () {
        await this.registerElements();
        this.numerateAffectedElements();
        this.registerEventListeners();
        window.$EditorStore.setCanvasCssClasses('farbetool-active');


    }

    /**
     * Terminate the activity of current tool;
     */
    terminate () {
        this.removeEventListeners();
        window.$EditorStore.setCanvasCssClasses('');

    }


    /**
     * Abstract Operations from DOM after loading a hermeneus-xml string
     * @returns {Promise<void>}
     */
    async load () {
        await this.createLinkInSection();
        let ExistingDescriptionInFarbeNode = document.querySelector(`div[tool-id="${this.name}"]`).innerHTML;
        this.description = ExistingDescriptionInFarbeNode.length > 0 ? ExistingDescriptionInFarbeNode : '';
        this.Operations = await this.readOperationsFromDOM(window.$EditorStore.Node_Text);
    }


    /**
     * Create <div id="hermeneus-link"> with description of the Color
     */
    async createLinkInSection () {
        if (!document.querySelector(`div[tool-id="${this.name}"]`)) {
            let DIVElement = document.createElement('div');
            DIVElement.setAttribute('id', 'hermeneus-farbe');
            DIVElement.setAttribute('tool-id', this.name);
            DIVElement.innerHTML = ' ';
            document.querySelector('#hermeneus-farben').appendChild(DIVElement);
        }

    }


    /**
     * Triggered by onkeyup in Tooloptions Component
     * @param Text
     */
    updateDescription (Text) {
        this.description = Text;
    }


    /**
     * Saves description to <hermeneus-link>
     */
    saveDescriptionToLinkNode () {
        document.querySelector(`div[tool-id="${this.name}"]`).innerHTML = this.description;
    }


    /**
     * Remove Event-Listeners for editable ElementArray (<w> and <pc>)
     */
    removeEventListeners () {
        this.AffectedElements.forEach(AffectedElement => {
            AffectedElement.removeEventListener('mousedown', this.MouseDown, false);
            AffectedElement.removeEventListener('mouseout', this.MouseOut, false);
            AffectedElement.removeEventListener('mouseover', this.MouseOver, false);
        });
        document.removeEventListener('mouseup', this.MouseUp, false);
    }


    /**
     * Add Event-Listeners for editable ElementArray (<w> and <pc>)
     */
    registerEventListeners () {
        // Iterate through all elements that where declared as markable
        this.AffectedElements.forEach(AffectedElement => {
            AffectedElement.addEventListener('mouseout', this.MouseOut, false);
            AffectedElement.addEventListener('mousedown', this.MouseDown, false);
            AffectedElement.addEventListener('mouseover', this.MouseOver, false);
        });
        document.addEventListener('mouseup', this.MouseUp, false);
    }


    /**
     * Event on mousedown
     */
    get MouseDownEvent () {
        return (Event) => {
            if (this.hasWenigerAlsVierFarben(Event.target)) {
                this.addAppearanceClass(Event.target);
                this.setOperationId(Event.target);
                this.select(Event.target);
            } else {
                window.$IrisMessenger.flash({
                    type: 'danger',
                    title: 'Achtung',
                    message: 'Ein Element kann maximal <span class="underline">vier</span> Unterstreichungen gleichzeitig haben.',
                    selfDestructTimer: 5000
                });

            }
        };
    };



    /**
     * Event on mouseover
     * this = target of MouseEvent (Element)
     */
    get MouseOverEvent () {
        return (MouseEvent) => {
            MouseEvent.preventDefault();
            if (this.canSelect(MouseEvent.target, MouseEvent)) {
                if (this.hasWenigerAlsVierFarben(MouseEvent.target)) {
                    // Add to selected ElementArray
                    this.select(MouseEvent.target);
                    // Remove class
                    this.setOperationId(MouseEvent.target);
                    this.addAppearanceClass(MouseEvent.target);
                } else {
                    window.$IrisMessenger.flash({
                        type: 'danger',
                        title: 'Achtung',
                        message: 'Ein Element kann maximal <span class="underline">vier</span> Unterstreichungen gleichzeitig haben.',
                        selfDestructTimer: 5000
                    });                }
            }
            // Sort selected elements by their order-attribute (consecutive number)
            this.SelectedElements = ElementArray.sortByOrder(this.SelectedElements);
        }
    };


    /**
     * Überprüft, ob das Element weniger als 4 operation_id-Attribute
     * des FarbTools hat.
     * @param Element
     * @returns {boolean}
     */
    hasWenigerAlsVierFarben (Element) {
        return Array.from(Element.attributes).filter(Attribute => {
            return Attribute.nodeName.match(/(farbe).(_operation_id)/g)
        }).length < 4;
    }


    /**
     * Transform TextModel into TEI-format
     * @returns {Promise<void>}
     */
    async transform () {
        await this.unsetModelLink();
        await this.copyLinkNodes();
        EditorHelpers.flattenArray(this.OperationModels.map(ToolOperation => {
            return ToolOperation.elements;
        })).forEach(Element => {
            this.syncOrCreateAttribute(Element);
        });
    }


    /**
     * This function will either sync attribute values [divided by ' '] or create a new one
     * @param Element
     */
    syncOrCreateAttribute (Element) {
        if (Element.getAttribute('ana')) {
            let AnaAttributeValues = Element.getAttribute('ana').split(' ');
            if (!AnaAttributeValues.includes(this.name)) {
                AnaAttributeValues.push(this.name)
            }
            Element.setAttribute('ana', AnaAttributeValues.join(' '));

        } else {
            Element.setAttribute('ana', this.name)
        }
    }


    async unsetModelLink () {
        return new Promise(async (resolve, reject) => {
            let ExistingTEILink = await window.$EditorStore.Node_Model_Farben.querySelector(`span[xml\\:id="${this.name}"]`);
            if (ExistingTEILink) {
                ExistingTEILink.parentNode.removeChild(ExistingTEILink);
            }
            resolve();
        });
    }


    /**
     * Copies canvas-links to model-annotations in TEI-Form
     * @returns {Promise}
     */
    copyLinkNodes () {
        return new Promise(async (resolve, reject) => {
            let Farbe = await window.$EditorStore.Node_Farben.querySelector(`div[tool-id="${this.name}"]`);
            let TEIFarbe = document.createElementNS(TEIXMLTransformer.teiNS, 'span');
            TEIFarbe.setAttribute('xml:id', this.name);
            TEIFarbe.setAttribute('type', 'farbe');
            TEIFarbe.setAttribute('target', `color ${this.appearance.Color}`);
            TEIFarbe.innerHTML = Farbe.innerHTML;
            // Prevent empty tags
            await window.$EditorStore.Node_Model_Farben.appendChild(TEIFarbe);
            resolve();
        });
    }


    /**
     * Event on mouseout
     */
    get MouseUpEvent () {
        return (Event) => {
            this.finishAssignment();
        }
    }



}