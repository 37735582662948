import WrappingTool from "./class_a_wrappingtool";
import Transformer from "../../transformation/class_transformer";
import EditorHelpers from "../../helpers/class_editorhelpers";

/**
 * A basic selection that Marks attributes to the selected elements
 * Selectable elements are to be defined in the object instance
 */
export default class ClauseTool extends WrappingTool {
    constructor (name, id, config, behaviour, appearance, options, transformation) {
        super(name, id, config, behaviour, appearance, options, transformation);
        this.formatAffectedElements();
        this.CurrentSelectedSentenceID = null;
    }



    /**
     * Abstract Operations from DOM after loading a hermeneus-xml string
     * @returns {Promise<void>}
     */
    async load () {
        this.Operations = await this.readOperationsFromDOM(window.$EditorStore.Node_Text);
        this.OperationId = await this.incrementedMaxOrOne(this.Operations, "operation_id");

        this.applyElementStyle();
    }


    /**
     * Modifies this.config.DelimitedBy-property
     * Why? If ToolInstance is delimited by another tool, its AffectedElements can
     * only be marked if they have attribute of delimiting Tool
     */
    formatAffectedElements () {
        if (this.config.hasOwnProperty('DelimitedBy')) {
            let AffectedElementsArray = this.config.AffectedElements.split(', ');
            let formatAffectedElementsArray = AffectedElementsArray.map(AffectedElement => AffectedElement + '[' + this.DelimitingTool.name
                + '_operation_id]');
            this.config.AffectedElements = formatAffectedElementsArray.join(', ');
        }
    }



    /**
     * Return array of boundary attributes of Delimiter-Tools that delimit this Markingtool
     * If instance has no DelimitedBy-Property return []
     * @returns {Array}
     * @constructor
     */
    get DelimiterBoundaryAttributes () {
        return this.config.hasOwnProperty('DelimitedBy') ?
            this.config.DelimitedBy.map(DelimiterTool => {
                return DelimiterTool.Attributes.delimiter
            }) : [];
    }



    /**
     * Return array of attributes of Delimiter-Tools that delimit this Markingtool
     * If instance has no DelimitedBy-Property return []
     * @returns {Array}
     * @constructor
     */
    get DelimiterSelectionAttributes () {
        return this.config.hasOwnProperty('DelimitedBy') ?
            this.config.DelimitedBy.map(DelimiterTool => {
                return DelimiterTool.Attributes.operation_id_attribute
            }) : [];
    }



    /**
     *
     */
    async transform () {
        for await (let OperationModel of this.OperationModels) {
            let SegmentElement = document.createElement(this.transformation.teielement);
            SegmentElement.setAttribute('xml:id', this.name + '_' + OperationModel.operation_id);
            Transformer.setToolTransformationAttributes(SegmentElement, this);
            Transformer.wrapAround(OperationModel.elements, SegmentElement);
        }
    }



    /**
     * Set Delimiter classes for first and last marked element
     */
    applyElementStyle () {
        this.syncedOperations.then(syncedOperations => {
            syncedOperations.forEach(Operation => {
                let FirstElement = EditorHelpers.findFirstNodeThatIsNot('LB', Operation.elements);
                let LastElement = Operation.elements[Operation.elements.length - 1];
                this.addBoundaryStyle(Operation.elements, FirstElement, LastElement);
            });
        });
    }



    /**
     * Event on mouseover: Determines behaviour if user is marking inside
     * an DelimiterElement or not
     */
    get MouseOverEvent () {
        return (Event) => {
            let CurrentElement = Event.currentTarget;
            if (this.canSelect(CurrentElement, Event) && this.isInSentence(CurrentElement)) {
                if (CurrentElement.getAttribute(this.DelimiterSelectionAttributes[0]) === this.CurrentSelectedSentenceID) {
                    this.markClause(CurrentElement);
                }
                /**
                 * Nicht klar, wofür dies benötigt wird
                 */
                /*else if (this.SelectionWithinDelimiter !== true && this.isDelimiterElement(CurrentElement) !== true) {
                    this.mark(CurrentElement);
                }*/

            }

        }
    };



    /**
     * Event on MouseDown
     * Element is selected only if it was already delimited AND was not selected yet
     * Why? If a 'DelimitedBy'-property with a DelimiterTool was defined on MarkingToolInstance, element can
     * only be selected if it was delimited by that DelimiterTool
     */
    get MouseDownEvent () {
        return (Event) => {
            this.isDelimiterElement(Event.target) &&
            !this.hasOperationIdAttribute(Event.target) ?
                this.SelectionWithinDelimiter = true :
                this.SelectionWithinDelimiter = false;

            /**
             * Lies die aktuelle SentenceID aus, die beim aktuellen Markierungsvorgang gleich bleiben soll
             */
            this.CurrentSelectedSentenceID = Event.target.getAttribute(this.DelimiterSelectionAttributes[0]);
            this.markClause(Event.target);
        };
    };



    /**
     * Event on mouseup
     */
    get MouseUpEvent () {
        return (Event) => {
            this.finishOperation(this.OperationId);
            this.SelectionWithinDelimiter = false;
        }
    }



    /**
     * All actions that take place when an
     * element is added to selection
     * @param Element
     */
    markClause (Element) {
        this.addAppearanceClass(Element);
        this.removeBoundaryClasses(Element);
        this.select(Element);
    }



    /**
     * Determines whether current Element has an delimiter-attribute
     * @param Element
     */
    isDelimiterElement (Element) {
        return this.DelimiterSelectionAttributes.filter(DelimiterAttribute => {
            return Element.hasAttribute(DelimiterAttribute);
        }).length > 0;
    }



    /**
     * Determines whether current Element has an delimiter-attribute
     * @param Element
     */
    isDelimiterBoundary (Element) {
        return this.DelimiterBoundaryAttributes.filter(DelimiterAttribute => {
            return Element.hasAttribute(DelimiterAttribute);
        }).length > 0;
    }


    /**
     * Befindet sich das Element in einem markierten Satz?
     * @param Element
     * @returns {boolean}
     */
    isInSentence (Element) {
        return this.SelectionWithinDelimiter === true && this.isDelimiterElement(Element)
    }

}

