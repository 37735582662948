import EditorHelpers from "../helpers/class_editorhelpers";

/**
 * Prepares text for TEI-XML-transformation
 */
export default class Transformer {
    constructor () {

    }


    /**
     *
     * @param Element
     * @param ToolInstance
     */
    static setToolTransformationAttributes (Element, ToolInstance) {
        for (let attribute in ToolInstance.transformation.attributes) {
            if (ToolInstance.transformation.attributes.hasOwnProperty(attribute)) {
                Element.setAttribute(attribute, ToolInstance.transformation.attributes[attribute]);
            }
        }
    }


    /**
     *
     * @param ElementArray
     * @param WrapperElement
     * @returns {null}
     */
    static wrapAround (ElementArray, WrapperElement) {
        let FirstElementInArray = ElementArray[0];
        FirstElementInArray.parentNode.insertBefore(WrapperElement, FirstElementInArray);
        ElementArray.forEach(Element => {
            WrapperElement.appendChild(Element);
        });
        return null;
    }



    /**
     * Transforms all ToolOperations asynchronously in the following steps
     * a: Update ModelDOM by copying WorkspaceDOM
     * b: Synchronize ToolOperationModels, so their elements refer to the ModelDOM
     * c: Transform ModelDOM to TEIFormat
     * @returns {Promise<any>}
     */
    static async transformAll () {
        //await HermeneusEditor.updateTextModel();
        await window.$EditorStore.setTextModel();
        await window.$EditorStore.syncedOperationModels();
        await window.$EditorStore.transformTextModel();
    }


    /**
     * Get all attributes of given ToolInstances (array)
     * @param ToolInstances
     * @returns {*}
     * @constructor
     */
    static AllAttributes (ToolInstances = window.$EditorStore.Tools) {
        return EditorHelpers.flattenArray(ToolInstances.map(ToolInstance => {
            return [
                ToolInstance.Attributes.operation_id_attribute,
                'order',
                'sentence_n',
                ToolInstance.Attributes.delimiter ? ToolInstance.Attributes.delimiter : null,
                ToolInstance.Attributes.order_attribute ? ToolInstance.Attributes.order_attribute : null].filter(item => item !== null);
        }));
    }

}