import mitt from 'mitt';

const emitter = mitt();

// Exportiere die mitt-Funktionen als EventBus
export const EventBus = {
    $on: (...args) => emitter.on(...args),
    $off: (...args) => emitter.off(...args),
    $emit: (...args) => emitter.emit(...args),
};

// mitt als Objekt exportieren, falls man direkt auf die Funktionen zugreifen möchte
export const GlobalMitt = emitter;