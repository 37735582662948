import FarbeTool from "../classes/class_farbetool";
import ToolOptionsFarbe from "./ToolOptionsFarbe.vue";

export let Farbe6 = new FarbeTool('farbe6', 'btn_farbe6', {
        AffectedElements: "w, pc",
        Overlap: true,
    },
    {
        mouseover: {
            Adjacent: true,
        },
        click: {
            Adjacent: false,
        },
    }, {

        Color: '#b47113',
        ColorLight: 'rgba(180, 113, 19, 0.5)',
        CssClass: 'farbe6',
        CssWhenActive: true,
        CssWhenOtherToolsActive: false,
        // box-shadows can be stacked on top of each other
        CssHover: `box-shadow: inset 0 -4px 0 0 rgba(180, 113, 19, 0.5);`,
        CssApplied: `box-shadow: inset 0 -4px 0 0 rgba(180, 113, 19, 1);`,
        CssClassHover: '',
        Cursor: 'custom',
        CursorURL: "/img/editor/cursors/ablabsmarker.png",
        Group: 3,
        Icon: "<img src='/img/svg/hermeneus-btn-farbe6.svg'>",
        Title: 'Farbe 6',
    }, {
        Description:
            `   
            <span class="hermeneus-editor__tool-description bold emphasize">Farbliche Markierung hinzufügen</span>`, Introduction: `
            Markieren Sie Wörter bei gedrückter Maustaste oder durch Klicken farblich. Mit Klick auf 'Fertig' speichern Sie Ihre Auswahl.`,
             ToolOptions: ToolOptionsFarbe
    }, {
        attributes: {
            function: 'farbe6'
        },
        hierarchy: 3,
    });
