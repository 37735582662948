import FarbeTool from "../classes/class_farbetool";
import ToolOptionsFarbe from "./ToolOptionsFarbe.vue";

export let Farbe5 = new FarbeTool('farbe5', 'btn_farbe5', {
        AffectedElements: "w, pc",
        Overlap: true,
    },
    {
        mouseover: {
            Adjacent: true,
        },
        click: {
            Adjacent: false,
        },
    }, {

        Color: '#B8B8B8',
        ColorLight: 'rgba(184, 184, 184, 0.5)',
        CssClass: 'farbe5',
        CssWhenActive: true,
        CssWhenOtherToolsActive: false,
        // box-shadows can be stacked on top of each other
        CssHover: `box-shadow: inset 0 -4px 0 0 rgba(184, 184, 184, 0.5);`,
        CssApplied: `box-shadow: inset 0 -4px 0 0 rgba(184, 184, 184, 1);`,
        CssClassHover: '',
        Cursor: 'custom',
        CursorURL: "/img/editor/cursors/ablabsmarker.png",
        Group: 3,
        Icon: "<img src='/img/svg/hermeneus-btn-farbe5.svg'>",
        Title: 'Farbe 5',
    }, {
        Description:
            `   
            <span class="hermeneus-editor__tool-description bold emphasize">Farbliche Markierung hinzufügen</span>`, Introduction: `
            Markieren Sie Wörter bei gedrückter Maustaste oder durch Klicken farblich. Mit Klick auf 'Fertig' speichern Sie Ihre Auswahl.`,
             ToolOptions:  ToolOptionsFarbe
    }, {
        attributes: {
            function: 'farbe5'
        },
        hierarchy: 3,
    });
