import axios from 'axios';
import {EventBus} from '../hermeneus.eventbus/eventbus'
import {haxiosAPIClient} from "@/haxios/haxios.js";

/**
 *
 * @param Data
 * @returns {Promise<any>}
 */
export let getModalData = function (Data) {
    return new Promise((resolve, reject) => {
        // Get ModalData
        let ModalData = JSON.parse(Data);
        // If content-property was not set or is empty...
        if (!ModalData.data && ModalData.uri) {
            //... get content from given uri
            axios.get(ModalData.uri).then(response => {
                ModalData.data = response.data;
                EventBus.$emit('modal_content_loaded');
                resolve(ModalData);
            });
        } else if ((!ModalData.data && !ModalData.uri) && ModalData.uri_api) {
            haxiosAPIClient.get(ModalData.uri_api).then(response => {
                ModalData.data = response.data;
                EventBus.$emit('modal_content_loaded');
                resolve(ModalData);
            });
        } else {
            resolve(ModalData);
        }
    });
}



/**
 *
 * @param Element
 * @returns {Promise<any>}
 */
export let getModalDataFromAttribute = function (Element) {
    return new Promise((resolve, reject) => {
        // Get ModalData from Element-attribute
        let ModalData = JSON.parse(Element.getAttribute('modal-data'));
        // If content-property was not set or is empty...
        if (!ModalData.data && ModalData.uri) {
            //... get content from given uri
            axios.get(ModalData.uri).then(response => {
                ModalData.data = response.data;
                EventBus.$emit('modal_content_loaded');
                resolve(ModalData);
            });
        } else if ((!ModalData.data && !ModalData.uri) && ModalData.uri_api) {
            haxiosAPIClient.get(ModalData.uri_api).then(response => {
                ModalData.data = response.data;
                EventBus.$emit('modal_content_loaded');
                resolve(ModalData);
            });
        } else {
            resolve(ModalData);
        }
    });

}