import FarbeTool from "../classes/class_farbetool";
import ToolOptionsFarbe from "./ToolOptionsFarbe.vue";

export let Farbe4 = new FarbeTool('farbe4', 'btn_farbe4', {
        AffectedElements: "w, pc",
        Overlap: true,
    },
    {
        mouseover: {
            Adjacent: true,
        },
        click: {
            Adjacent: false,
        },
    }, {

        Color: '#70AD47',
        ColorLight: 'rgba(112, 173, 71, 0.5)',
        CssClass: 'farbe4',
        CssWhenActive: true,
        CssWhenOtherToolsActive: false,
        // box-shadows can be stacked on top of each other
        CssHover: `box-shadow: inset 0 -4px 0 0 rgb(112, 173, 71, 0.5);`,
        CssApplied: `box-shadow: inset 0 -4px 0 0 rgb(112, 173, 71, 1);`,
        CssClassHover: '',
        Cursor: 'custom',
        CursorURL: "/img/editor/cursors/ablabsmarker.png",
        Group: 3,
        Icon: "<img src='/img/svg/hermeneus-btn-farbe4.svg'>",
        Title: 'Farbe 4',
    }, {
        Description:
            `   
            <span class="hermeneus-editor__tool-description bold emphasize">Farbliche Markierung hinzufügen</span>`, Introduction: `
            Markieren Sie Wörter bei gedrückter Maustaste oder durch Klicken farblich. Mit Klick auf 'Fertig' speichern Sie Ihre Auswahl.`,
             ToolOptions: ToolOptionsFarbe
    }, {
        attributes: {
            function: 'ablabs'
        },
        hierarchy: 3,
    });
