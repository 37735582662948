<template>
  <div>
    <transition-group name="fade">
      <div v-if="messages.length > 0" class="iris-message__loader fixed w-full h-full ">
        <!-- GENERAL STYLING -->
        <div v-for="(message, key) in messages" :key="key" class="   flex flex-col h-full items-center justify-center pointer-events-none cursor-not-allowed noselect rounded "
             :class="{
        'text-success': message.type === 'success',
        'text-warning': message.type === 'warning',
        'text-danger': message.type === 'danger',
        'text-grey-600': message.type === 'confirm',
        'text-secondary_color ': message.type === 'info' || message.type === '' || message.type ==='confirm' || !message.type || !message.hasOwnProperty('type'),

      }"
        >
          <loading-comet></loading-comet>
          <div v-if="hasTitle(key)" class="mt-6 text-2xl">{{ message.title }}</div>
          <div v-if="hasTitle(key)" class="mt-4 text-lg italic">{{ message.message }}</div>
          <div class="flex flex-row justify-start w-full top-0 fixed ">
            <div class="iris-message__countdown  w-full h-2 bg-secondary_color " :id="message.id" :style="`animation-duration: ${message.selfDestructTimer}ms`"></div>
          </div>

        </div>
      </div>

    </transition-group>


  </div>
</template>

<script>
export default {
  name: "iris-messenger-template-loader",
  props: {
    messages: {type: Array, required: false}
  },
  methods: {
    hasTitle (index) {
      return this.messages[index].title.length > 0;
    },



  },



}
</script>

<style scoped>
.iris-message__loader {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  top: 50px;
  bottom: auto;
  left: auto;
  right: auto;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.6);
}


.iris-message__countdown {
  animation-name: countdown;
  animation-delay: 200ms;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
  animation-direction: normal;
  animation-play-state: running;
  animation-timing-function: linear
}

@keyframes countdown {
  0% {
    width: 0%;


  }
  100% {
    width: 100%;
  }
}


.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
</style>