import LogStack from "../../hermeneus.logstack/logstack.class.js";
import TextPreparation from "./reader.class_textpreparation";
import QuerySelectors from "./reader.class_queryselectors";
import Popper from 'popper.js';
import {EventBus} from '../../hermeneus.eventbus/eventbus'
import EditorHelpers from "../../hermeneus.editor/js/helpers/class_editorhelpers";
import {groupByWithValue, wait} from "../../helpers/functions_helpers";
import WordAnalysisContainer from "./reader.class_wordanalysiscontainer";
import WordAnalyzer from "../../hermeneus.wordanalyzer/class_wordanalyzer";
import Helpers from "../../helpers/class_helpers";
import Helper from "../../helpers/class_helpers";
import Calliope from "../../hermeneus.calliopejs/src/class_calliope";
import API from "../../editor-reader/global/js/class_api";
import {injectCustomCSS_ToolFarben} from "../../hermeneus.editor/js/tools/tools_farbe/cssGen.js";


window.MouseButtonIsPressed = false;
window.LogStack = new LogStack();
let WordNotes = [];

export default class Reader {
    /**
     *
     * @param component
     * @param text
     * @param config
     * @param textdata
     * @param config.toolnames
     * @param config.analysis_api
     * @param config.prefix
     */
    constructor (component, text, config, textdata) {

        /**
         * @property $data
         */
        this.component = component;
        this._text = text;
        this.textdata = textdata;
        this._toolnames = config.toolnames;
        this.config = config;
        this.api = this.defineAnalysisAPI(config);
        this.prefix = config.prefix;
        this.isDichtungsText = ['elegiac', 'hexameter'].includes(this.textdata.format);
        this.startWordAnalysis = this.startWordAnalysisEvent;
        this.registerWordIds();
        this.registerEventListenerWordAnalysis();
        this.registerEventListenerAnnotations();
        this.registerEventListenerSentenceButtons();
        this.registerEventListenerVerseButtons();
        this.registerEventListenerCloseAnalyseis();
        this.registerAutopinWordAnalyseis();
        this.registerSubjPred();
        this.registerSentences();
        if (this.component.$data.Textparts.Annotations) {
            this.registerAnnotations(this.component.$data.Textparts.Annotations);
        }
        if (this.component.$data.Textparts.Farben) {
            this.registerFarben(this.component.$data.Textparts.Farben);
            injectCustomCSS_ToolFarben('reader');
        }
        if (this.component.$data.Textparts.Highlights) {
            this.registerHighlights(this.component.$data.Textparts.Highlights);
        }
        if (this.component.$data.Textparts.Answers) {
            this.registerAnswers(this.component.$data.Textparts.Answers);
            this.setAnswersAreEnabled();
        }


    }


    /**
     * Basisroute für die Analyse-API
     * @returns {string|string}
     */
    defineAnalysisAPI (config) {
        return config.id ? `/analyze/text/${this.textdata.id}/` : `/analyze/`;
    }


    /**
     * Getter for DOM-element that contains the text
     * @returns {Element}
     * @constructor
     */
    static get TextNode () {
        return document.getElementById('reader__workspace__primary--text');
    }


    /**
     * Give unique index to each w-element
     */
    registerWordIds () {
        this.Words = document.querySelectorAll(QuerySelectors.word);
        this.Words.forEach(
            /**
             * @param {HTMLElement} Word
             * @param {string} WordIndex
             */
            (Word, WordIndex) => {
                Word.setAttribute('w_id', WordIndex);
            });
    }


    async SubjPred_On () {
        await this.removeEventListenerWordAnalysis();
        await Reader.SubjPred_registerEventListeners(Reader.TextNode, this.showSubjPredAttributionText);

    }


    async SubjPred_Off () {
        await this.registerEventListenerWordAnalysis();
        await Reader.SubjPred_removeEventListeners(Reader.TextNode, this.showSubjPredAttributionText);
        EventBus.$emit('setToolSubjPredHighlight', false);

    }


    static async SubjPred_registerEventListeners (TextNode, callback) {
        for (let Word of TextNode.querySelectorAll(QuerySelectors.w_subjpred())) {
            Word.addEventListener('click', callback, false);
        }
    }


    static async SubjPred_removeEventListeners (TextNode, callback) {
        for (let Word of TextNode.querySelectorAll(QuerySelectors.w_subjpred())) {
            Word.removeEventListener('click', callback, false);
        }
    }


    async showSubjPredAttributionText (e) {
        await Reader.SubjPred_removeHighlightClasses(Reader.TextNode);
        EventBus.$emit('setToolSubjPredHighlight', true);
        await Reader.SubjPred_addHighlightClasses(Reader.TextNode, e);

    }


    static async SubjPred_addHighlightClasses (TextNode, event) {
        let Attribute = event.target.getAttribute('function').split(' ').find(String => String.substring(0, 4) === 'pred' || 'subj' || 'sub-');
        let AttributeId = Attribute.split('#')[1];

        for (let Word of TextNode.querySelectorAll(`tei-w[function*="pred#${AttributeId}"], tei-w[function*="subj#${AttributeId}"], tei-w[function*="sub-pre#${AttributeId}"]`)) {

            /**
             * Erfasse nur die Wörter, bei denen nach AttributeId ein Leerzeichen folgt oder eine Wortgrenze vorliegt
             */
            if (Word.getAttribute('function').split(' ').find(String => String.substring(0, 4) === 'pred' || 'subj' || 'sub-').split('#')[1] === AttributeId) {
                Word.classList.add('attr-highlight')
            }

        }
        event.target.classList.add('attr-highlight');
    }


    static async SubjPred_removeHighlightClasses (TextNode) {
        for (let Word of TextNode.querySelectorAll(QuerySelectors.word)) {
            Word.classList.remove('attr-highlight')
        }
    }


    /**
     * Getter for DOM-element that contains the text model
     * @returns {Element}
     * @constructor
     */
    static get TextAnalysisNode () {
        return document.getElementById('reader__workspace__analysis--text');
    }



    /**
     * Getter for DOM-element that contains the notes
     * @returns {Element}
     * @constructor
     */
    static get NotesNode () {
        return document.getElementById('reader__ad-lineam');

    }



    /**
     * Every annotation ( TEI-<interp>-element in the DOM) is
     * registered as data in the Vue Instance
     */
    registerAnnotations (Annotations) {
        let AllAnnotations = Annotations.querySelectorAll(QuerySelectors.interp);
        AllAnnotations.forEach(AnnotationElement => {
            let AnnotationID = AnnotationElement.getAttribute("xml:id");
            let RendValues = Helpers.readMultipleBooleanAttributeValues(AnnotationElement, 'rend');
            let ReferencedElements = this.WordsWithAnnotationID(AnnotationID);
            let FirstRelevantElement = ReferencedElements[0];
            let Annotation = {
                id: AnnotationID,
                element: FirstRelevantElement,
                line: ReferencedElements.length > 0 ? Reader.getLineNumberOf(FirstRelevantElement) : '1',
                type: AnnotationElement.hasAttribute('type') ? AnnotationElement.getAttribute('type') : '',
                referenced_elements: ReferencedElements,
                referenced_text: AnnotationElement.hasAttribute('source') ? AnnotationElement.getAttribute('source') : Helpers.formatTextElements(ReferencedElements, ['LB', 'MILESTONE'], 'w_id'),
                render_referenced_elements: RendValues.hasOwnProperty('reftext') ? RendValues.reftext : 'true',
                autopin: RendValues.hasOwnProperty('autopin') ? RendValues.autopin : 'false',
                annotation_text: AnnotationElement.textContent,
            };
            if (Annotation.autopin == 'true') {
                this.addAnnotationNote(Annotation);
            }
            this.component.$data.Annotations.push(Annotation)
        });
    }


    /**
     * Every farbe ( TEI-<seg>-element in the DOM) is
     * registered as data in the Vue Instance
     */
    registerFarben (Farben) {
        let AlleFarben = Farben.querySelectorAll(QuerySelectors.span_farbe);
        // Fallback 0075
        if (AlleFarben.length === 0) {
            AlleFarben = Farben.querySelectorAll(QuerySelectors.link);
        }
        AlleFarben.forEach(FarbeElement => {
            let FarbeID = FarbeElement.getAttribute("xml:id");
            let FarbeTargetStyleArray = FarbeElement.getAttribute('target').split(' ');
            let ReferencedElements = this.WordsWithAna(FarbeID);
            if (ReferencedElements.length > 0) {

                this.component.$data.Farben.push(
                    {
                        id: FarbeID,
                        referenced_elements: ReferencedElements,
                        description: FarbeElement.textContent,
                        style: `${FarbeTargetStyleArray[0]}: ${FarbeTargetStyleArray[1]};`,
                        hex: FarbeTargetStyleArray[1],
                        show: false,
                    }
                );
            }
        });
    }


    /**
     * Every Highlight ( TEI-<div>-element in the DOM) is
     * registered as data in the Vue Instance
     */
    registerHighlights (Highlights) {
        let AlleHighlights = Highlights.querySelectorAll(QuerySelectors.span_highlight);
        AlleHighlights.forEach(HighlightElement => {
            let HighlightID = HighlightElement.getAttribute("xml:id");
            let HighlightStyle = HighlightElement.getAttribute('style');
            let Color = HighlightElement.getAttribute('color');
            let Mode = HighlightElement.getAttribute('show') ?? "always";
            let ReferencedElements = this.AllElementsWithAna(HighlightID);

            if (ReferencedElements.length > 0) {

                this.component.$data.Highlights.push(
                    {
                        id: HighlightID,
                        referenced_elements: ReferencedElements,
                        description: HighlightElement.textContent,
                        style: HighlightStyle,
                        hex: Color,
                        showText: Mode === 'always' || Mode === 'text',
                        showSentence: Mode === 'always' || Mode === 'sentence',
                        show: false,
                    }
                );
            }
        });
    }


    /**
     *
     * @param Answers
     */
    registerAnswers (Answers) {
        let AlleAnswers = Answers.querySelectorAll(QuerySelectors.span_answer);
        AlleAnswers.forEach(async AnswerElement => {
            let AnswerID = parseInt(AnswerElement.getAttribute("xml:id").replace("answer", ''));
            let SentenceID = parseInt(AnswerElement.getAttribute("corresp").substr(2));
            let AnswerText = AnswerElement.textContent;
            let Sentence = this.component.$data.Sentences.find(Sentence => parseInt(Sentence.sentence_id) === SentenceID)

            if (Sentence) {

                let SentenceString = await Sentence.sentence_string;
                let SentenceN = Sentence.sentence_n;
                this.component.$data.Answers.push({
                    id: AnswerID,
                    sentence_id: SentenceID,
                    sentence_string: SentenceString,
                    sentence_n: SentenceN,
                    answertext: AnswerText,
                })
            }
        })
    }


    /**
     * One-Time-Evaluation, ob die Musterlösung erlaubt ist
     */
    setAnswersAreEnabled () {
        if (this.component.data.config.answers_available === 'always') {
            this.component.$data.AnswersAreEnabled = true;
        } else if (this.component.data.config.answers_available === 'date') {
            this.component.$data.AnswersAreEnabled = (this.component.data.config.answers_available_at !== null) && (Date.parse(this.component.data.config.answers_available_at) < Date.now())
        }
    }


    registerSubjPred () {
        this.component.$data.SubjPred = Array.from(Reader.getSubjPredElements(this.component.$refs.workspace.$el));
    }


    registerSentences () {
        this.component.$data.Sentences = TextPreparation.getSentences(this.component.$data.Textparts.Source);
    }


    /**
     * Returns all w-Elements that have a certain AnnotationID
     * @param AnnotationID
     * @returns {any | NodeListOf<Element> | *}
     * @constructor
     */
    WordsWithAnnotationID (AnnotationID) {
        let TextContainer = document.getElementById('reader__workspace__primary--text');
        // Filter through all w-Elements that have a corresp attribute
        return Array.prototype.filter.call(TextContainer.querySelectorAll(QuerySelectors.w_corresp_gap_corresp), AnnotatedWord => {
            // Value of corresp is a concatenated string. Check if string contains AnnotationID
            return AnnotatedWord.getAttribute('corresp').replaceAll('annotation', '').split(' ').includes(AnnotationID.replace('annotation', ''));
        });
    }


    /**
     * Returns all w-Elements that have a certain ID
     * @param FunctionID
     * @returns {T[]}
     * @constructor
     */
    WordsWithAna (FunctionID) {
        let TextContainer = document.getElementById('reader__workspace__primary--text');
        // Filter through all w-Elements that have an ana-attribute
        return Array.prototype.filter.call(TextContainer.querySelectorAll(QuerySelectors.w_ana), WordWithAnaAttribute => {
            // Value of corresp is a concatenated string. Check if string contains AnnotationID
            return WordWithAnaAttribute.getAttribute('ana').includes(FunctionID);
        });
    }


    /**
     * Returns all Elements that have a certain ID
     * @param FunctionID
     * @returns {T[]}
     * @constructor
     */
    AllElementsWithAna (FunctionID) {
        let TextContainer = document.getElementById('reader__workspace__primary--text');
        // Filter through all Elements that have an ana attribute
        return Array.prototype.filter.call(TextContainer.querySelectorAll(QuerySelectors.all_ana), ElementWithAnaAttribute => {
            // Value of corresp is a concatenated string. Check if string contains certain ID
            return ElementWithAnaAttribute.getAttribute('ana').includes(FunctionID);
        });
    }



    /**
     * Returns inital Text as XML-string;
     * @constructor
     */
    get Text () {
        return this._text;
    }


    /**
     * Global namespace for TEI-XML
     * @returns {string}
     */
    static get teiNS () {
        return "http://www.tei-c.org/ns/1.0";
    }


    /**
     * Adds Wordnote if not already existing
     * @param WordNote
     */
    addWordNote (WordNote) {
        let AlreadyExistingWordNote = this.component.$data.WordNotes.find(ExistingWordNote => {
            return ExistingWordNote.line === WordNote.line && ExistingWordNote.lemma.lemma === WordNote.lemma.lemma;
        });
        !AlreadyExistingWordNote ? this.component.$data.WordNotes.push(WordNote) : null;
    }


    /**
     * Adds Wordnote if not already existing
     * @param AnnotationNote
     */
    addAnnotationNote (AnnotationNote) {
        let AlreadyExistingAnnotationNote = this.component.$data.AnnotationNotes.find(ExistingWordNote => {
            return ExistingWordNote.line === AnnotationNote.line && ExistingWordNote.annotation_text === AnnotationNote.annotation_text;
        });
        !AlreadyExistingAnnotationNote ? this.component.$data.AnnotationNotes.push(AnnotationNote) : null;
    }


    /**
     * Removes Wordnote
     * @param WordNote
     */
    removeWordNote (WordNote) {
        this.component.$data.WordNotes = this.component.$data.WordNotes.filter(ExistingWordNote => ExistingWordNote !== WordNote);
    }


    /**
     * Removes Annotationnote
     * @param AnnotationNote
     */
    removeAnnotationNote (AnnotationNote) {
        this.component.$data.AnnotationNotes = this.component.$data.AnnotationNotes.filter(ExistingAnnotationNote => ExistingAnnotationNote !== AnnotationNote);
    }


    /**
     * Read Object from Element attributes
     * @param Element
     */
    getAnnotationAnalysis (Element) {
        let AnnotationNamesAttribute = Element.getAttribute('corresp');
        let AnnotationNamesArray = AnnotationNamesAttribute.split(' ');
        return AnnotationNamesArray.map(AnnotationName => {
            let SingleAnnotationAnalysisObject = this.component.$data.Annotations.find(Annotation => {
                return Annotation.id === AnnotationName;
            });
            SingleAnnotationAnalysisObject.line = Reader.getLineNumberOf(Element);
            SingleAnnotationAnalysisObject.element = Element;
            return SingleAnnotationAnalysisObject;
        });
    }


    setAnnotationAnalysis (Element) {
        this.component.$data.AnnotationAnalysis = this.getAnnotationAnalysis(Element);
    }



    /**
     * Returns an object with Linenumbers of given element
     * Default property with Linenumber is 'original'
     * @param Element
     * @param JSONAttribute
     * @returns {*}
     */
    static getLineNumberOf (Element, JSONAttribute = 'original') {
        try {
            let closestLinebreak = Helper.getClosestLinebreak(Element);
            let LineNumbers = Helpers.parseLinebreakNAttribute(closestLinebreak);
            return LineNumbers[JSONAttribute];
        } catch (e) {
            return 0;
        }
    }



    /**
     *
     * @param WordElement
     * @param Source
     * @returns {Promise<WordAnalysisContainer>}
     */
    async getWordAnalysis (WordElement, Source = null) {
        let Line = Reader.getLineNumberOf(WordElement);
        let Analysis;
        switch (Source) {
            case 'text':
                Analysis = await WordAnalyzer.analyzeFromText(WordElement, 'lemma');
                break;
            case 'api':
                Analysis = await WordAnalyzer.analyzeFromAPI(WordElement, 'lemma', this.api);
                break;
            default:
                Analysis = await WordAnalyzer.analyze(WordElement, 'lemma', this.api);
                break;
        }

        let Keyword = WordElement.textContent;
        return new WordAnalysisContainer(Analysis, Line, Keyword, WordElement);
    }


    /**
     * Accesses w_id-Attribute to search a word by Reference
     * @param Element
     * @returns {Element}
     */
    static getReferenceWord (Element) {
        let Word_Id = Element.getAttribute('w_id');
        return Reader.TextNode.querySelector(QuerySelectors.w_by_id(Word_Id));
    }


    /**
     * EventListeners for all tei-w-elements in TEI-XML
     */
    registerEventListenerWordAnalysis () {
        for (let Word of this.Words) {
            Word.addEventListener('click', this.startWordAnalysis, false);
        }
    }


    /**
     * EventListeners for all tei-w-elements in TEI-XML
     */
    removeEventListenerWordAnalysis () {
        for (let Word of this.Words) {
            Word.removeEventListener('click', this.startWordAnalysis, false);
        }
    }


    /**
     * EventListeners for all tei-w-elements with corresp in TEI-XML
     */
    registerEventListenerAnnotations () {

        /**
         * @type {NodeListOf<Element>}
         */
        let Annotations = document.querySelectorAll(`${QuerySelectors.word_corresp}, ${QuerySelectors.gap_corresp}`);
        for (let Annotation of Annotations) {
            Annotation.addEventListener('click', async (e) => {
                /**
                 * Instantly Pin to the right
                 */
                if (this.config.pin_instant === true) {
                    this.getAnnotationAnalysis(e.currentTarget).forEach(AnnotationAnalysis => {
                        this.addAnnotationNote({
                            annotation_text: AnnotationAnalysis.annotation_text,
                            type: AnnotationAnalysis.type,
                            referenced_text: AnnotationAnalysis.referenced_text,
                            referenced_elements: AnnotationAnalysis.referenced_elements,
                            render_referenced_elements: AnnotationAnalysis.render_referenced_elements,
                            autopin: AnnotationAnalysis.autopin,
                            element: AnnotationAnalysis.element,
                            line: AnnotationAnalysis.line
                        })
                    });
                } else {
                    this.setAnnotationAnalysis(e.currentTarget);
                }
            });
        }
    }


    /**
     * Read words that have rend=autopin-attribute and pin them
     */
    registerAutopinWordAnalyseis () {
        for (let WordElement of this.Words) {
            if (WordElement.hasAttribute('rend') && WordElement.getAttribute('rend') === 'autopin') {
                this.analyzeAndPin(WordElement).then();
            }
        }
    }



    /**
     * EventListeners for opening sentence-viewer
     */
    registerEventListenerSentenceButtons () {
        /**
         * @type {NodeListOf<Element>}
         */
        let HermeneusHelpers = document.querySelectorAll(QuerySelectors.sentence_button);
        for (let HermeneusHelper of HermeneusHelpers) {
            HermeneusHelper.addEventListener('click', this.getSentenceAnalysis, false);
        }
    }


    /**
     * EventListeners for opening verse-viewer
     */
    registerEventListenerVerseButtons () {
        if (this.isDichtungsText) {

            /**
             * @type {NodeListOf<Element>}
             */
            let VerseButtons = document.querySelectorAll(QuerySelectors.verse_button);
            for (let VerseButton of VerseButtons) {
                /**
                 * Manuelle oder automatische Analyse
                 */
                if ((this.config.versanalyse === true) || ((this.config.versanalyse === false || !this.config.hasOwnProperty('versanalyse')) && VerseButton.parentElement.hasAttribute("met"))) {
                    VerseButton.addEventListener('click', this.getVerseAnalysis, false);
                }
            }
        }
    }


    /**
     * Wenn Nutzer bestimmte Elemente anklickt, wird WordAnalysis und AnnotationAnalysis geschlossen
     */
    registerEventListenerCloseAnalyseis () {
        let CloseOnClickElements = [
            'reader_wrapper',
            'reader',
            'reader__workspace__primary',
            'reader__workspace__primary--text',
            'reader__titleinfo__default',
            'reader__titleinfo',
            'reader__titleinfo__user',
            'reader__titleinfo__user--title',
            'reader__titleinfo__user--description',
            'reader__ad-lineam',
            'line_1',
            'line_2',
            'line_3',
            'line_4',
            'line_5',
            'line_6',
            'line_7',
            'line_8',
            'line_9',
            'line_10',
            'line_11',
            'line_12',
            'line_13',
            'line_14',
            'line_15',
            'line_16',
            'line_17',
            'line_18',
            'line_19',
            'line_20',
            'reader__toolbar'
        ];



        function closeAnalyseis (e) {
            if (CloseOnClickElements.includes(e.target.getAttribute('id'))) {
                EventBus.$emit('setWordAnalysis', false);
                EventBus.$emit('setVerseAnalysis', false);
                EventBus.$emit('setAnnotationAnalysis', false);
            }
        }



        document.querySelector('#reader_wrapper').addEventListener('click', e => closeAnalyseis(e), false);

    }


    /**
     * @param this.config.pin_instant
     * @returns {Function}
     */
    get startWordAnalysisEvent () {
        return async (e) => {
            if (this.canBeAnalyzed(e.currentTarget)) {
                /**
                 * Instantly Pin to the right
                 */
                if (this.config.pin_instant === true) {
                    await this.analyzeAndPin(e.currentTarget);
                } else {
                    await this.analyze(e.currentTarget);
                }

                await this.notifyGravitasCounter();
                // For word-highlighting in ReaderWorkspace
                EventBus.$emit('wordClicked', e.target);

            }
        }
    }


    /**
     * For VocabPopper
     * @param WordElement
     * @returns {Promise<void>}
     */
    async analyze (WordElement) {
        await this.unsetWordAnalysis();
        this.component.WordAnalysisLoading = true;
        // Transmit Element to VocabPopperComponent
        this.component.$data.WordAnalysis = await this.getWordAnalysis(WordElement, this.config.analysis_source);
        this.component.WordAnalysisLoading = false;

    }


    /**
     * Simple APICall and pin
     * @param WordElement
     */
    async analyzeAndPin (WordElement) {
        let WordAnalysisContainer = await this.getWordAnalysis(WordElement, this.config.analysis_source);
        WordAnalysisContainer.analysis.Lemmata.forEach(SingleLemmaAnalysis => {
            if (SingleLemmaAnalysis.lemma !== '') {
                this.addWordNote({
                    lemma: SingleLemmaAnalysis,
                    info: SingleLemmaAnalysis.info,
                    line: WordAnalysisContainer.line
                });
            }
        });
    }


    /**
     * Soll die Wortanalyse gestartet werden?
     * @param WordElement
     * @returns {boolean}
     */
    canBeAnalyzed (WordElement) {
        if (this.config.analysis_unless_anmerkung_exists === true && WordElement.hasAttribute('corresp')) {
            return false;
        }
        return true;
    }


    notifyGravitasCounter (Analysis) {
        if (this.config.analysis_gravitas_cost) {


            if (this.component.$userData.gravitas > 0 && this.config.analysis_source !== "text" && this.component.$data.WordAnalysis?.analysis?.length > 0) {
                EventBus.$emit('GravitasSubtracted', 5);
            } else if (this.component.$userData.gravitas == 0 && !this.component.$data.WordAnalysis?.analysis?.length > 0) {
                EventBus.$emit('GravitasError');
            }
        }
    }


    /**
     * Assigns reference-Element of PopperTooltip to
     * clicked word (querying by w_id)
     * @param WordID
     * @returns {Promise<void>}
     */
    static async openVocabPopperForWordID (WordID) {
        let ClickedWord = document.querySelector(QuerySelectors.w_by_id(WordID));
        let VocabPopperElement = document.querySelector('.reader__vocab-popper');
        let VocabPopper = new Popper(ClickedWord, VocabPopperElement);
    }



    async unsetWordAnalysis () {
        this.component.$data.WordAnalysis = {};
    }



    /**
     *
     * @returns {function(*)}
     */
    get getSentenceAnalysis () {
        return (e) => {
            let SentenceButton = e.currentTarget;
            let SentenceElement = SentenceButton.parentElement;
            this.component.$data.SentenceAnalysis = SentenceElement.innerHTML;

            /**
             * Fallback <0.0.6
             * Wenn das Attribut "xml:id" die Zeichenkette "delimit" enthält, dann splitte das Attribut bei "_"
             */

            let SentenceID = SentenceElement.getAttribute("xml:id").substr(1);
            if (SentenceElement.getAttribute("xml:id").includes("delimit")) {
                SentenceID = SentenceElement.getAttribute("xml:id").split("_")[2];
            }

            EventBus.$emit('setSentenceActiveID', SentenceID);
            // For shepherd-js-Tour
            EventBus.$emit('SentenceOpened');
        }
    }


    /**
     *
     * @returns {function(*)}
     */
    get getVerseAnalysis () {
        return async (e) => {
            this.component.$data.VerseAnalysis = 'isLoading';
            let VerseButton = e.currentTarget;
            let ClickedLBElement = VerseButton.parentNode;
            let Meter = this.getMeter(ClickedLBElement);
            EventBus.$emit('setVerseMeter', Meter);

            let VerseObject = JSON.parse(ClickedLBElement.getAttribute("met"))
            if (VerseObject) {
                this.component.$data.VerseAnalysis = VerseObject;
                await wait(300)
                this.component.$refs.verse.meter = Meter;
                this.component.$refs.verse.manual = true;
            } else {
                let NextLBElement = Helpers.getClosestLinebreak(ClickedLBElement, false)
                let ElementsBetween = Helpers.getElementsBetween(ClickedLBElement, NextLBElement);
                let Verse = ElementsBetween.map(Element => Element.textContent).join(' ');



                let Callback = (response) => {
                    this.component.$data.VerseAnalysis = response.data;
                    this.component.$refs.verseviewer.meter = Meter;
                }
                /**
                 * Wenn der API-Request fehlschlägt, wird Calliope (frontend-scanner) aufgerufen
                 * @type {any}
                 */
                let Result = await API.cltk_scansion(Meter, {verse: Verse, macronize: "False"}, Callback).catch(async error => {
                    let BackupResult = await (new Calliope(Verse)).analyze();
                    this.component.$refs.verseviewer.meter = Meter;
                    this.component.$data.VerseAnalysis = BackupResult;
                });
                /**
                 * Wenn der Hexameter nicht gültig ist, ...
                 */
                if (Result.valid === false) {

                    /**
                     * ...wird der Verse von einer anderen API analysiert
                     * @type {any}
                     */
                    let AnalysisOfAlatiusAPI = await API.cltk_scansion_alatius(Meter, {sentence: Verse, meter: Meter}, (response) => {
                        this.component.$data.VerseAnalysis = response.data;
                        this.component.$data.VerseAnalysis.scansion = response.data.scansion;
                        this.component.$refs.verseviewer.meter = Meter;

                    })
                    /**
                     * ...wird der Vers mit Längen versehen und zweideutige Vokale markiert
                     * @type {any}
                     */
                    /*  let MacronizedLettersWithAmbiguousLettersArray = await API.cltk_macronize(Meter, {sentence: Verse, ambiguousvowels: "True", minimaltext: "False", tojson: "False"}, async (response) => {
                        this.component.$refs.verseviewer.hasAmbiguousLetters = true;
                        this.component.$refs.verseviewer.MacronizedLettersWithAmbiguousLettersArray = response.data;
                    })*/
                }
            }



        }
    }


    /**
     *
     * @param ClickedLBElement
     * @returns {string}
     */
    getMeter (ClickedLBElement) {
        let Linenumber = TextPreparation.parseLBAttribute(ClickedLBElement).hermeneus;
        return Helpers.getMeterFromFormat(this.textdata.format, Linenumber)
    }


    /**
     * Argument must be an <s>-Node
     * @param SentenceElement
     * @returns {Promise<void>}
     */
    static getSteps (SentenceElement) {
        // Get all elements with an n-attribute that start with s
        let NumeratedSentenceElements = [...SentenceElement.querySelectorAll('*[n]')].filter(Element => Element.getAttribute('n')[0] === 's');
        let VerboseSteps = NumeratedSentenceElements.map(Element => {
            let StepNumber = EditorHelpers.readStepNumberAttribute(Element);
            return {'n': StepNumber, 'element': Element}
        });
        return groupByWithValue(VerboseSteps, 'n', 'element');
    }


    static getSubjPredElements (HTMLElement) {
        return HTMLElement.querySelectorAll('*[function]')
    }


    toggleShowFarbe (farbe) {
        this.component.$data.Farben.find(Farbe => Farbe.id === farbe.id).show = !this.component.$data.Farben.find(Farbe => Farbe.id === farbe.id).show;

    }


    toggleShowHighlight (highlight) {
        this.component.$data.Highlights.find(Highlight => Highlight.id === highlight.id).show = !this.component.$data.Highlights.find(Highlight => Highlight.id === highlight.id).show;

    }


    highlightHighlight (highlight) {
        let Highlight = this.component.$data.Highlights.find(Highlight => Highlight.id === highlight.id);
        Highlight.referenced_elements.forEach(Element => {
            Element.classList.add(`${highlight.id}_highlight`)
        })

    }


    dehighlightHighlight (highlight) {
        let Highlight = this.component.$data.Highlights.find(Highlight => Highlight.id === highlight.id);
        Highlight.referenced_elements.forEach(Element => {
            Element.classList.remove(`${highlight.id}_highlight`)
        })
    }


    hideAllFarben () {
        this.component.$data.Farben.forEach(farbe => {
            farbe.show = false
        })
    }



}


