import {formatHendecasyllabus, formatHexameter, formatPentameter} from "./format";
import Scansion from "./scansion.js";

/**
 * (C) 2021 Benedikt Blumenfelder (www.hermeneus.eu)
 * The underlying scansion-method (scansion.js) was written by Dylan Holmes
 * (http://www.logical.ai/arma/) under the GNU GPL 3.0+-licence.
 * This code is licensed under the same conditions, which means
 * that you are free to use, modify, and re-distribute this
 * work — even commercially — as long as you attribute the
 * original to me and share your modified versions
 * in the same way.
 */
export default class Calliope {
    constructor (text) {
        this.text = text;
        this.text_original = text;
        this.text_accented = null;
        this.text_scanned = null;
        this.text_macronized = null;
        this.text_scanned_unescaped = null;
        this.scansion_aligned = null;
        this.scansion_strict = null;
    }


    /**
     * This is the analysis-pipeline
     * @returns {{original, scanned: string, working_line: string, scansion: string, scansion_strict: string}}
     */
   async analyze () {
        const text_scanned = this.scan(this.text)
        const text_accented = this.accent(text_scanned)
        const text_macronized = this.macronize(text_scanned);
        const text_working_line = this.getWorkingLine(text_scanned);
        const text_scanned_transcribed = this.transcribe(text_scanned);
        const text_scanned_unescaped = this.unescape(text_scanned)
        const scansion_aligned = this.getScansion(text_scanned_transcribed, false)
        const scansion_strict = this.getScansionStrict(scansion_aligned)

        return {
            "accented": await text_accented,
            "macronized": await text_macronized,
            "scansion": scansion_aligned,
            "original": this.text_original,
            "valid": null,
            "working_line": text_working_line,
            "scansion_strict": scansion_strict
        }
    }



    /**
     * Replace all HTML-Breves and Macrons (&#772; &#774) with = and *
     * for better string transformation.
     * @param text_scanned_escaped
     * @returns {string}
     */
    transcribe (text_scanned_escaped) {
        return text_scanned_escaped.replaceAll("&#772;", "=").replaceAll("&#774;", "*")
    }


    /**
     * Only maintain macrons to get a macronized version of the text-string
     * @param text_scanned_escaped
     * @returns {string}
     */
    async accent (text_scanned_escaped) {
        const Replace = new Map(Object.entries({
            "a&#772;": "ā",
            "A&#772;": "Ā",
            "a&#774;": "ă",
            "A&#774;": "Ă",
            "e&#772;": "ē",
            "E&#772;": "Ē",
            "e&#774;": "ĕ",
            "E&#774;": "Ĕ",
            "i&#772;": "ī",
            "I&#772;": "Ī",
            "i&#774;": "ĭ",
            "I&#774;": "Ĭ",
            "o&#772;": "ō",
            "O&#772;": "Ō",
            "o&#774;": "ŏ",
            "O&#774;": "Ŏ",
            "u&#772;": "ū",
            "U&#772;": "Ū",
            "u&#774;": "ŭ",
            "U&#774;": "Ŭ"
        }));



        async function replaceTokens (Verse) {

            for (let item of Replace) {
                let search = item[0];
                let replace = item[1];
                Verse = await Verse.replaceAll(search, replace);
            }
            return Verse;
        }



        let text_accented = await replaceTokens(text_scanned_escaped);
        this.text_accented = this.unescape(text_accented);
        return this.unescape(text_accented);
    }
    /**
     * Only maintain macrons to get a macronized version of the text-string
     * @param text_scanned_escaped
     * @param combined // Combined macrons/breves?
     * @returns {string}
     */
    async macronize (text_scanned_escaped, combined = false) {
        const Replace = new Map(Object.entries({
            "a\u0304": "\u0101",
            "A\u0304": "\u0100",
            "e\u0304": "\u0113",
            "E\u0304": "\u0112",
            "i\u0304": "\u012B",
            "I\u0304": "\u012A",
            "o\u0304": "\u014D",
            "O\u0304": "\u014C",
            "u\u0304": "\u016B",
            "U\u0304": "\u016A",
        }));



        async function replaceCombinedTokens (Verse) {

            for (let item of Replace) {
                let search = item[0];
                let replace = item[1];
                Verse = await Verse.replaceAll(search, replace);
            }
            return Verse;
        }



        let text_macronized_combined = this.unescape(text_scanned_escaped.replaceAll("&#774;", ""));
        let text_macronized = combined ? text_macronized_combined : await replaceCombinedTokens(text_macronized_combined);
        this.text_macronized = text_macronized;
        return text_macronized;
    }


    /**
     * Transform HTML-encoded string to UTF-8-String
     * &#772; => ¯
     * &#774; => ˘
     * @param text_scanned
     * @returns {string}
     */
    unescape (text_scanned) {
        const txt = document.createElement("textarea");
        txt.innerHTML = text_scanned;
        this.text_scanned_unescaped = txt.value;
        return txt.value;
    }


    /**
     * Returns unscanned version of the string with elisions and substitutions of half-vowels (i => j)
     * @param text_scanned
     * @returns {string}
     */
    getWorkingLine (text_scanned) {
        return text_scanned.replaceAll("&#772;", "").replaceAll("&#774;", "");
    }


    /**
     * Get an utf-8 string with proper breves and macrons from the transcribed string.
     * Remove empty strings/spaces, that remained from letters with metric notations, so
     * the scansion is perfectly aligned with the original text again.
     * @param text_scanned_transcribed
     * @param superscript
     * @returns {string}
     */
    getScansion (text_scanned_transcribed, superscript = true) {
        const macron = superscript ? "¯" : "-"
        const breve = superscript ? "˘" : "U"

        const Meter = text_scanned_transcribed.split('').map(letter => {
            if (letter === "=") {
                return macron
            } else if (letter === "*") {
                return breve
            } else {
                return ' '
            }
        }).join("") + "x";

        return Meter.split("").map((letter, index) => {
            let nextletter = Meter.split("")[index + 1]
            if (letter === " " && (nextletter === macron || nextletter === breve || nextletter === "x")) {
                return null;
            }
            return letter;
        }).filter(letter => letter).join("")

    }


    /**
     * Get Scansion without spaces
     * @param scansion_aligned
     * @returns {string}
     */
    getScansionStrict (scansion_aligned) {
        return scansion_aligned.replaceAll(' ', '');
    }


    /**
     * Returns a scanned version of the text with breves and macrons
     * @returns {*}
     */
    scan (text_input) {

        const text_scanned = Scansion(text_input)
        this.text_scanned = text_scanned;
        return text_scanned;
    }


    /**
     * :DEPRECATED:
     * @param text_scanned_unescaped
     * @returns {Promise<void>}
     */
    async normalizeText (text_scanned_unescaped) {

        const replaceLetter = (search, replace, text_scanned_unescaped) => {
            return new Promise(async (resolve, reject) => {
                resolve(text_scanned_unescaped.replace(search, replace));
            });
        }



        const substitutionLookupTable = {
            "aē": "ae",
            "oē": "oe",
            "ă": "a",
            "ĕ": "e",
            "ĭ": "i",
            "ŏ": "o",
            "ŭ": "u",
        }



        for await (let key of Object.entries(substitutionLookupTable)) {
            let search = key;
            let replace = substitutionLookupTable[search];
            text_scanned_unescaped = await replaceLetter(search, replace, text_scanned_unescaped)
        }



    }




    static async  format (scansion, meter, asString = false) {
        let formattedScansion;
        if (meter === "hexameter") {
            formattedScansion = await formatHexameter(scansion)
        } else if (meter === "pentameter") {

            formattedScansion = await formatPentameter(scansion)
        } else if (meter === "hendecasyllabus") {

            formattedScansion = await formatHendecasyllabus(scansion)
        } else {
            formattedScansion = scansion;
        }

        return (asString && formattedScansion !== false) ? formattedScansion.join("|") : formattedScansion
    }



}

