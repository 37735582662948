import Tool from "../tools/classes/class_tool";
import EditorHelpers from "./class_editorhelpers";

export default class ElementArray {



    /**
     * Checks whether the elements in the fragmented elements-array are in consecutive order
     * and returns key of first element that is not consecutive to its previous element
     * @param NonConsecutiveElementsArray
     * @param OrderAttribute
     * @returns {boolean}
     */
    static containsNonConsecutiveElements (NonConsecutiveElementsArray, OrderAttribute = 'order') {

        if (NonConsecutiveElementsArray.length > 1) {
            for (let i = 1; i < NonConsecutiveElementsArray.length; i++) {
                let OrderPrevious = NonConsecutiveElementsArray[i - 1].getAttribute(OrderAttribute);
                let OrderThis = NonConsecutiveElementsArray[i].getAttribute(OrderAttribute);
                // Use this for Debugging
                //console.log(`Element ${NewOperationElements[i].textContent} \nOrder:  ${OrderThis} \nKey:  ${i} \nis not consecutive!`);
                if (OrderThis - OrderPrevious !== 1) {
                    return true;
                }
            }
        }
        return false;
    }



    /**
     * Returns true if array contains more than one element with a visible type (w or pc)
     * @param Elements
     * @returns {boolean}
     */
    static NumberOfVisible (Elements) {
        return Elements.filter(Element => {
            if (Element.nodeName === 'W' || Element.nodeName === 'PC') {
                return Element;
            }
        }).length;
    }



    /**
     * Create new ElementArray before non-consecutive Element index
     * @param ElementArray
     */
    static sliceOperationOldElements (ElementArray) {
        // Get index of first non-consecutive Element
        let SliceIndex = Tool.getSliceIndex(ElementArray);
        // Slice element array at this position
        return ElementArray.slice(0, SliceIndex);
    }



    /**
     * Create new ElementArray after non-consecutive Element index
     * @param ElementArray
     */
    static sliceOperationNewElements (ElementArray) {
        // Get index of first non-consecutive Element
        let SliceIndex = Tool.getSliceIndex(ElementArray);
        // Slice element array at this position
        // Create new array of elements that are non-consecutive
        return ElementArray.slice(SliceIndex);

    }


    /**
     * Remove Element from Array of Elements
     * @param ElementToRemove
     * @param ElementArray
     * @returns {*}
     */
    static removeElement (ElementToRemove, ElementArray) {
        return ElementArray.filter(Element => {
            return Element !== ElementToRemove;
        })
    }


    /**
     *
     *
     * @param SelectedElements
     * @param OrderAttribute
     * @returns {*}
     */
    static sortByOrder (SelectedElements, OrderAttribute = 'order') {
        SelectedElements.sort(function (a, b) {
            return a.getAttribute(OrderAttribute) - b.getAttribute(OrderAttribute);
        });
        return SelectedElements;
    }



    /**
     * Filters an array of Elements and returns only elements which are not of type
     * [NodeNames]
     * @param ElementArray
     * @param NodeNames
     * @type array
     */
    static excludeNodeNames (ElementArray, NodeNames) {
        return ElementArray.filter(Element => {
            return !EditorHelpers.inArray(Element.nodeName, NodeNames);
        })
    }


    /**
     * <element attr1="x" attr2="y" attr2="z"></element>
     * => <element></element>
     * @param ElementArray
     * @param AttributeNameArray
     */
    static removeMultipleAttributes (ElementArray, AttributeNameArray) {
        ElementArray.forEach(Element => {
            AttributeNameArray.forEach(Attribute => {
                Element.removeAttribute(Attribute);
            })
        })
    }


    /**
     *
     * @param ElementArray
     * @param DOMNode
     * @returns {*}
     */
    static checkMissingElements (ElementsArray, DOMNode) {
        let OpElementsIntArray = ElementsArray.map(
            /**
             * @param Element
             * @type {Element}
             */
            Element =>
                parseInt(Element.getAttribute('order')));
        let MissingOrderAttributes = EditorHelpers.getMissingNumbers(OpElementsIntArray);
        MissingOrderAttributes.forEach(MissingElementOrderInt => {
            let MissingElement = DOMNode.querySelector('[order="' + MissingElementOrderInt + '"');
            if (EditorHelpers.inArray(MissingElement.nodeName, ElementArray.NeutralElements)) {
                ElementsArray.push(MissingElement)
            }
        });
        return ElementsArray;
    }


    /**
     *
     * @returns {string[]}
     * @constructor
     */
    static get NeutralElements () {
        return ['LB', 'MILESTONE'];
    }



}