<template>
  <div class="modal is-active">
    <div :class="appearance.background === true ? 'modal-background' : 'modal-background-transparent'"
      @click="this.close"></div>
    <div v-bind:class="['modal-card', appearance.root, {
      'modal-s': size == 'small',
      'modal-m': size == 'medium',
      'modal-l': size == 'large',
      'modal-xl': size == 'extralarge',
      'modal-full': size == 'fullscreen',
    }]">
      <header class="modal-card-head no-outline">
        <p class="modal-card-title" v-html="title"></p>
        <button @click="this.close"><i class="hi hi-close text-2xl normal-grey hover-secondary no-outline"></i></button>
      </header>
      <section class="modal-card-body" :class="appearance.body">
        <!-- Content ... -->
        <component v-bind="prop"  :is="component" v-if="component" :data="data" ></component>
        <div v-else v-html="data"></div>
      </section>
      <footer v-show="appearance.footer !== false" class="modal-card-foot">
      </footer>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../hermeneus.eventbus/eventbus";
import { wait } from "../helpers/functions_helpers";

export default {
  name: "hermeneus-modal",

  props: {
    title: { required: false },
    uri: { required: false },
    type: { required: false },
    size: { required: false },
    component: { required: false },
    data: { required: false },
    prop: { required: false },
    /**
     * If footer is false, Modal has no footer
     * body contains classes that will be applied to modal-card-body
     */
    appearance: {
      required: false,
      type: Object,
      default: function () {
        return {
          footer: true,
          body: '',
          root: '',
          background: true
        }
      }
    },
  },
  data: function () {
    return {
      'show_background': this.appearance.background,
    }
  },
  methods: {
    appendHTML: function () {
    },
    close: function () {
      this.$emit('close');
      EventBus.$emit('CloseModalEvent');
    },

  },
  mounted: async function () {
    await wait(500);
    /*         this.show_background = true;*/
  },

}
</script>

<style>
.modal-card.modal-full {
  width: 100% !important;
  margin: 0;
}

.modal-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  background-color: rgba(10, 10, 10, 0.86);
  animation-name: fade;
  animation-delay: 1000ms;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}

.modal-background-transparent {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  background-color: none;
  animation-name: fade;
  animation-delay: 1000ms;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}

/*    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    }

    .fade-enter, .fade-leave-to !* .fade-leave-active below version 2.1.8 *!
    {
        opacity: 0;
    }*/

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1
  }
}</style>