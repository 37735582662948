<template>
  <div class="iris-messenger-template-bar-container ">
    <transition-group name="fadein">
      <div v-if="messages.length > 0">
        <!-- GENERAL STYLING -->
        <div v-for="(message, key) in messages" :key="key" class="iris-message__bar flex flex-col align-content-between justify-between cursor-pointer noselect   mb-3"
             :class="{
        'text-white bg-success hover:bg-success_dark': message.type === 'success',
        'text-white bg-warning hover:bg-warning_dark': message.type === 'warning',
        'text-white bg-danger hover:bg-danger_dark': message.type === 'danger',
        'text-grey-600 bg-info hover:bg-info_light': message.type === 'confirm',
        'text-grey-700 bg-yellow hover:bg-info_light': message.type === 'loading',
        'text-grey-700 bg-grey-200 border border-grey-400 hover:bg-info_light': message.type === 'info' || message.type === '' || message.type ==='confirm',
        'h-11': hasCountdown(key),
        'h-10': !hasCountdown(key),

      }"
        >

          <div class=" flex flex-row items-start justify-center">
            <div class="iris-message__icon p-2 text-base">
              <!-- ICON -->
              <i v-if="message.type === 'success'" class="fas fa-check-circle"></i>
              <i v-if="message.type === 'warning'" class="fas fa-exclamation-circle"></i>
              <i v-if="message.type === 'danger'" class="fas fa-exclamation-triangle"></i>
              <i v-if="message.type === 'info'" class="fas fa-info-circle"></i>
              <i v-if="message.type === 'confirm'" class="fas fa-question-circle"></i>
              <loading-dots v-if="message.type === 'loading'" class="" size="sm"></loading-dots>

            </div>
            <!-- CENTER BODY -->
            <div class="iris-message__body flex flex-row items-center w-full p-2">

              <div class="iris-message__title  w-48 text-sm font-bold mr-4" v-if="hasTitle(key)">{{ message.title }}</div>
              <div class="iris-message__message text-sm w-full leading-relaxed " :class="{'text-smW': !hasTitle(key)}" v-html="message.message"></div>
              <!-- CONFIRM BUTTONS -->
              <div class="iris-message__buttons-container flex flex-row justify-end " v-if="message.type==='confirm'">
                <button class=" rounded bg-grey-500 hover:bg-grey-600 text-white p-2" @click="$parent.close(message)">Abbrechen</button>
                <button class=" rounded bg-secondary_color hover:bg-secondary-800 text-white p-2 ml-4" @click="$parent.confirm(message)">OK</button>
              </div>
            </div>
            <button class="iris-message__close p-2 text-base hover:text-grey-300 " @click="$parent.close(message)"><i class="fas fa-times"></i></button>
          </div>
          <div class="iris-message__countdown w-full h-1 bg-white opacity-50" :id="message.id" :style="`animation-duration: ${message.countdown}ms`"></div>

        </div>

      </div>
    </transition-group>


  </div>
</template>

<script>
export default {
  name: "iris-messenger-template-bar",
  props: {
    messages: {type: Array, required: false}
  },
  methods: {
    hasTitle (index) {
      return this.messages[index].title.length > 0;
    },
    hasCountdown (index) {
      return this.messages[index].countdown;
    },



  },

  mounted () {


  }


}
</script>

<style scoped>
.iris-messenger-template-bar-container {
  overflow: hidden;
}

.iris-message__bar {
  z-index: 9999;
  height: 2.75rem;
}


.iris-message__countdown {
  animation-name: countdown;
  animation-delay: 200ms;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
  animation-direction: normal;
  animation-play-state: running;
  animation-timing-function: linear
}

@keyframes countdown {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}


.fadein-enter-active {
  animation: fadein 0.3s ease-in-out;
}

.fadein-leave-active {
  animation: fadein 0.25s reverse;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
</style>