import TextPreparation from "./reader.class_textpreparation";


/**
 * QuerySelectors for TEI-Text
 */
export default class QuerySelectors {
    static get Prefix () {
        return TextPreparation.ElementPrefix() ? 'tei-' : '';
    }


    static get Prefix_UC () {
        return QuerySelectors.Prefix.toUpperCase();
    }


    static get teiheader () {
        return TextPreparation.ElementPrefix() ? "tei-tei tei-teiheader" : "TEI teiHeader";
    }


    static get seriesstmt () {
        return `${QuerySelectors.Prefix}seriesstmt`;
    }


    static get seriesstmt_title () {
        return `${QuerySelectors.Prefix}seriesstmt ${QuerySelectors.Prefix}title`;
    }


    static get seriesstmt_title_sub () {
        return `${QuerySelectors.Prefix}seriesstmt ${QuerySelectors.Prefix}title[type='sub']`;
    }


    static get seriesstmt_biblscope () {
        return `${QuerySelectors.Prefix}seriesstmt ${QuerySelectors.Prefix}biblscope`;
    }


    static get seriesstmt_respstmt_name () {
        return `${QuerySelectors.Prefix}seriesstmt ${QuerySelectors.Prefix}respstmt ${QuerySelectors.Prefix}name`;
    }


    static get titlestmt_author () {
        return `${QuerySelectors.Prefix}titlestmt ${QuerySelectors.Prefix}author`;
    }


    static get titlestmt_title () {

        return `${QuerySelectors.Prefix}titlestmt ${QuerySelectors.Prefix}title`;
    }


    static get interprgrp () {
        return TextPreparation.ElementPrefix() ? "tei-tei tei-interpgrp" : "TEI interpGrp";
    }


    static get interp () {
        return `${QuerySelectors.Prefix}interp[xml\\:id]`;
    }


    static get linkgrp () {
        return TextPreparation.ElementPrefix() ? "tei-tei tei-linkgrp" : "TEI linkGrp";
    }


    /**
     * :TODO: Change highlight to plural in Editor
     * @returns {string}
     */
    static get div_highlights () {
        return TextPreparation.ElementPrefix() ? "tei-tei tei-div[type='highlight']" : "TEI div[type='highlight']";
    }


    static get div_farben () {
        return TextPreparation.ElementPrefix() ? "tei-tei tei-div[type='farben']" : "TEI div[type='farben']";
    }


    static get div_answers () {
        return TextPreparation.ElementPrefix() ? "tei-tei tei-div[type='answers']" : "TEI div[type='answers']";
    }


    static get span_farbe () {
        return TextPreparation.ElementPrefix() ? "tei-tei tei-span[type='farbe']" : "TEI span[type='farbe']";
    }


    static get span_highlight () {
        return TextPreparation.ElementPrefix() ? "tei-tei tei-span[type='highlight']" : "TEI span[type='highlight']";
    }


    static get span_answer () {
        return TextPreparation.ElementPrefix() ? "tei-tei tei-span[type='answer']" : "TEI span[type='answer']";
    }


    static get link () {
        return `${QuerySelectors.Prefix}link[xml\\:id]`;
    }


    static get div_textpart () {
        return TextPreparation.ElementPrefix() ? "tei-div[type='textpart']" : "div[type='textpart']";
    }


    static get linebreak () {
        return TextPreparation.ElementPrefix() ? "tei-lb" : "lb";
    }


    static get sentence () {
        return TextPreparation.ElementPrefix() ? "tei-s" : "s";
    }


    static get sentence_button () {
        return `${QuerySelectors.Prefix}s button`;
    }


    static get verse_button () {
        return `div.reader__workspace__primary--linenumber-container`;
    }


    static get word () {
        return `${QuerySelectors.Prefix}w`;
    }


    static get word_pc () {
        return `${QuerySelectors.Prefix}w, ${QuerySelectors.Prefix}pc`;
    }


    static get word_corresp () {
        return `${QuerySelectors.Prefix}w[corresp]`;
    }


    static get gap () {
        return `${QuerySelectors.Prefix}gap`;
    }


    static get pc () {
        return `${QuerySelectors.Prefix}pc`;
    }


    static get seg () {
        return `${QuerySelectors.Prefix}seg`;
    }


    static get gap_corresp () {
        return `${QuerySelectors.Prefix}gap[corresp]`;
    }


    static get w_corresp_gap_corresp () {
        return `${QuerySelectors.word_corresp}, ${QuerySelectors.gap_corresp}`;
    }


    static get w_ana () {
        return `${QuerySelectors.Prefix}w[ana]`;
    }


    static get all_ana () {
        return `${QuerySelectors.seg}[ana], ${QuerySelectors.seg}[ana], ${QuerySelectors.word}[ana], ${QuerySelectors.gap}[ana], ${QuerySelectors.pc}[ana], ${QuerySelectors.linebreak}[ana]`;
    }


    static w_by_id (id) {
        return `${QuerySelectors.Prefix}w[w_id="${id}"]`;

    }


    static w_subjpred () {
        return `${QuerySelectors.Prefix}w[function*="subj"], tei-w[function*="pred"], tei-w[function*="sub-pre"]`;

    }


    static get lb_w_gap () {
        return `${QuerySelectors.linebreak}, ${QuerySelectors.word}, ${QuerySelectors.gap}`;
    }


    static get seg_w_gap_pc_lb () {
        return `${QuerySelectors.seg}, ${QuerySelectors.word}, ${QuerySelectors.gap}, ${QuerySelectors.pc}, ${QuerySelectors.linebreak}`;
    }
}