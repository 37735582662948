import CETEI from '../CETEIcean/CETEI';
import ElementArray from "../hermeneus.editor/js/helpers/class_elements";
import QuerySelectors from "../hermeneus.reader/js/reader.class_queryselectors";
import {normalizeSentenceString} from "./functions_helpers";

export default class Helpers {



    /***
     * Return specific item from array
     * @param ItemToDelete
     * @param Array
     */
    static ArrayWithout (ItemToDelete, Array) {
        return Array.filter(Item => {
            return Item !== ItemToDelete;
        })
    }



    /**
     * Parse HTML-string as traversable HTML
     * @param HTMLString
     * @returns {Element}
     */
    static parseHTML (HTMLString) {
        let HTMLWrapper = document.createElement('HTMLWrapper');
        HTMLWrapper.innerHTML = HTMLString;
        return HTMLWrapper.firstElementChild;
    }



    /**
     * Parse XML-string as traversable XML
     * @param XMLString
     * @returns {Document}
     */
    static parseXML (XMLString) {
        let parser = new DOMParser();
        let doc = parser.parseFromString(XMLString, "application/xml");
        return (doc);
    }


    /**
     * Return HTML-String from traversable HTMLElement
     * @param HTMLElement
     * @returns {string}
     */
    static stringifyHTML (HTMLElement) {
        return (new XMLSerializer().serializeToString(HTMLElement));
    }


    /**
     * Replaces ' in string with "
     * @param String
     * @returns {XML|*|void}
     */
    static substituteSingleQuotes (String) {
        return String.replace(/'/g, '"');
    }


    /**
     * Use CETEIcean to create a valid html string with custom-elements
     * from a tei-xml-string
     * @param source
     * @returns {string | *}
     */
    static prefixTeiElements (source) {
        return (new CETEI()).makeHTML5(source).outerHTML;
    }


    /**
     * Apply class to element for certain amount of time
     * @param Element
     * @param ClassName
     * @param Milliseconds
     */
    static toggleClassFor (Element, ClassName, Milliseconds) {
        Element.classList.add(ClassName);
        setTimeout(function () {
            Element.classList.remove(ClassName);
        }, Milliseconds);
    }


    /**
     * Parses attribute-value-string with multiple attributes divided by ' ' as ARRAY
     * rend="autopin_true reftext_false" => ['autopin_true', 'reftext_false']
     * @param Element
     * @param AttributeName
     * @returns {string[]|*[]}
     */
    static readMultipleAttributeValues (Element, AttributeName) {
        if (Element.hasAttribute(AttributeName)) {
            return Element.getAttribute(AttributeName).split(' ');
        }
        return [];
    }


    /**
     * Parses attribute-value-string with multiple attributes divided by ' ' as ARRAY
     * rend="autopin_true reftext_false" => {autopin: true, reftext: false}
     * @param Element
     * @param AttributeName
     * @returns {{}}
     */
    static readMultipleBooleanAttributeValues (Element, AttributeName) {
        if (Element.hasAttribute(AttributeName)) {
            let Values = Element.getAttribute(AttributeName).split(' ');
            let KeyValueObject = {};
            let KeyValueArray = Values.map(Value => {
                let KeyValueArrayRaw = Value.split('_');
                if (KeyValueArrayRaw[1] == 'true' || KeyValueArrayRaw[1] == 'false') {
                    return KeyValueArrayRaw;
                }
            });
            KeyValueArray.forEach(KeyValueArray => {
                KeyValueObject[KeyValueArray[0]] = KeyValueArray[1];
            });
            return KeyValueObject;
        }
        return {};
    }


    /**
     * Determines if int is divisable by divisor
     * @param int
     * @param divisor
     * @returns {boolean}
     */
    static isDivisableBy (int, divisor) {
        return parseInt(int) % divisor === 0;
    }


    /**
     * Checks if needle is not yet in haystack
     */
    static notInArray (needle, haystack) {
        return haystack.indexOf(needle) === -1;
    }


    /**
     * Versmaß bestimmen
     * @param Format
     * @param VerseNumber
     * @returns {string}
     */
    static getMeterFromFormat (Format, VerseNumber) {
        if (Format === 'hexameter') {
            return 'hexameter';
        } else if (Format === 'elegiac' && !(VerseNumber % 2)) {
            return 'pentameter';
        } else {
            return 'hexameter';
        }
    }


    /**
     *
     * Reads the attribute, converts it to valid JSON
     * and returns an object
     * If n-Attribute is not given - set it manually.
     * @param LinebreakElement
     * @param setAttributeIfNotExisting
     * @returns {any}
     */
    static parseLinebreakNAttribute (LinebreakElement, setAttributeIfNotExisting = false) {
        let LineNumberAttribute = (typeof LinebreakElement.getAttribute('n') === 'string') && LinebreakElement.getAttribute('n').length > 0 ? LinebreakElement.getAttribute('n') : '{"hermeneus": "-", "original": "-"}';

        if (setAttributeIfNotExisting) {
            LinebreakElement.setAttribute('n', LineNumberAttribute);
        }
        return JSON.parse(Helpers.substituteSingleQuotes(LineNumberAttribute));
    }


    static isOverflowing (Element) {
        let curOverflow = Element.style.overflow;

        if (!curOverflow || curOverflow === "visible")
            Element.style.overflow = "hidden";

        let isOverflowing = Element.clientWidth < Element.scrollWidth
            || Element.clientHeight < Element.scrollHeight;

        Element.style.overflow = curOverflow;

        return isOverflowing;
    }


    /**
     * Return true if a NodeElement (given by its index) within an array is not consecutive by 'OrderAttribute'
     * @param ElementArray
     * @param ElementKey
     * @param OrderAttribute
     * @returns {boolean}
     */
    static isNotConsecutive (ElementArray, ElementKey, OrderAttribute = 'order') {

        if (ElementArray.length > 1 && ElementKey !== 0) {
            let OrderPrevious = ElementArray[ElementKey - 1].getAttribute(OrderAttribute);
            let OrderThis = ElementArray[ElementKey].getAttribute(OrderAttribute);
            if (OrderThis - OrderPrevious !== 1) {
                return true;
            }
        }
        return false;
    }


    /**
     * Formats text elements.
     * Interjects ..., if text elements are not consecutive in order
     * @param Elements
     * @param ExludeNodeNames
     * @returns {string}
     */
    static formatTextElements (Elements, ExludeNodeNames = ['LB', 'MILESTONE'], OrderAttribute = 'order') {
        let ElementArray_permitted = ElementArray.excludeNodeNames(Elements, ExludeNodeNames);
        let CurrentSelection = [];
        ElementArray_permitted.forEach((Element, ElementKey) => {
            // If Element is not consecutive by order to previous Element ...
            if (Helpers.isNotConsecutive(ElementArray_permitted, ElementKey, OrderAttribute)) {
                CurrentSelection.push(' ...');
            }
            if (Element.nodeName !== 'PC') {
                CurrentSelection.push(' ');
            }
            CurrentSelection.push(Element.textContent);
        });
        return CurrentSelection.join('');
    }


    /**
     * Traversiert DOMTree nach allen NodeListElements, die zwischen FirstElement und LastElement liegen
     * @param FirstElement
     * @param LastElement
     * @param NodeListElements
     * @returns {*[]}
     */
    static getElementsBetween (FirstElement, LastElement, NodeListElements = document.querySelectorAll(QuerySelectors.seg_w_gap_pc_lb)) {
        // Get all of the following nodes in correct order as NodeList and transform to array
        let DOMTree = [...NodeListElements];
        let ClickedElementKey = DOMTree.findIndex(DOMElement => DOMElement === FirstElement);
        let NextElementKey = LastElement ? DOMTree.findIndex(DOMElement => DOMElement === LastElement) : false;

        return DOMTree.filter((Element, Index) => {
            if ((Index > ClickedElementKey) && (Index < NextElementKey)) {
                return Element;
            } else if (!NextElementKey) {
                if (Index > ClickedElementKey) {
                    return Element;
                }
            }

        });
    }


    static async getSentenceStringFromElements (ElementArray, FormatTextString = true) {
        return FormatTextString ? await normalizeSentenceString(ElementArray.map(Element => Element.textContent).join(" ")) : ElementArray.map(Element => Element.textContent).join(" ");
    }


    /**
     *
     * @param Element
     * @param Previous: If false, the function will return the NEXT LB-Element
     * @param NodeListElements
     * @param ElementPrefix
     * @returns {boolean|{nodeName}|*}
     */
    static getClosestLinebreak (Element, Previous = true, NodeListElements = document.querySelectorAll(QuerySelectors.lb_w_gap), ElementPrefix = true) {
        // Get all of the following nodes in correct order as NodeList and transform to array
        let DOMTree = [...NodeListElements];
        // Get index number of clicked element
        let ElementKey = DOMTree.findIndex(DOMElement => DOMElement === Element);
        let LBIndices = [];
        // Get all LB-Elements and return an object to sort by
        DOMTree.forEach(
            /**
             * @param DOMElement
             * @param DOMElement.nodeName
             * @param Index
             */
            (DOMElement, Index) => {
                if (DOMElement.nodeName === `${ElementPrefix ? QuerySelectors.Prefix_UC : ''}LB`) {
                    LBIndices.push({
                        // Index of clicked element minus key of LB can tell
                        // minimal distance between element and LB
                        'Index_Sort': Previous ? ElementKey - Index : Index - ElementKey,
                        'Index_Original': Index,
                        'LBElement': DOMElement
                    });
                }
            });

        // Sort by ascending order and filter out negative values
        // Return first object [0] and read element
        try {

            return LBIndices.sort((a, b) => {
                return a.Index_Sort - b.Index_Sort;
            }).filter(LB => LB.Index_Sort > 0)[0].LBElement;
        } catch (error) {
            return false;
        }
    }
}