import ClauseTool from './class_clausetool.js';
import Transformer from "../../transformation/class_transformer";
import ElementArray from "../../helpers/class_elements";
import EditorHelpers from "../../helpers/class_editorhelpers";
import {ToolOperation} from "./class_tooloperation";

/**
 * A tool to erase Marked attributes or to unwrap elements
 */
export default class AssignationTool extends ClauseTool {

    constructor(name, id, config, behaviour, appearance, options, transformation) {
        super(name, id, config, behaviour, appearance, options, transformation);
        this.Operations = [];
        this.EditMode = false;
    }



    terminate() {
        this.removeEventListeners();
    }



    async transform() {
        for await (let OperationModel of this.OperationModels) {
            for await (let Element of OperationModel.elements) {
                Element.setAttribute('ana', this.name);
                Transformer.setToolTransformationAttributes(Element, this);

            }
        }
    }



    /**
     * Event on mouseout
     */
    get MouseOutEvent() {
        return (Event) => {
        }
    }



    /**
     * Event on mousedown
     */
    get MouseDownEvent() {
        return (Event) => {
            this.addAppearanceClass(Event.target);
            this.setOperationId(Event.target);
            this.select(Event.target);
        };
    };



    /**
    * Event on mouseover
    * this = target of MouseEvent (Element)
*/
    get MouseOverEvent() {
        return (MouseEvent) => {
            MouseEvent.preventDefault();
            if (this.canSelect(MouseEvent.target, MouseEvent)) {
                // Add to selected ElementArray
                this.select(MouseEvent.target);
                // Remove class
                this.setOperationId(MouseEvent.target);
                this.addAppearanceClass(MouseEvent.target);
            }
            // Sort selected elements by their order-attribute (consecutive number)
            this.SelectedElements = ElementArray.sortByOrder(this.SelectedElements);
        }
    };



    // this = target of MouseEvent (Element)
    get MouseClickEvent() {
        return (Event) => {
            this.select(Event.target);
            this.setOperationId(Event.target);
            this.addAppearanceClass(Event.target);
        }
    };



    /**
     * Adds or sets {toolname}_operation_id as attribute with its value(s)
     * IF User is editing an Operation, use its ID, otherwise use auto-incrementing ID
     * Check if Tool allows multiple operation IDs
     * @param Element
     */
    setOperationId(Element) {
        this.config.Multiple ? this.addOperationId_multiple(Element, this.OperationId) : this.setOperationId_single(Element, this.OperationId);
    }



    /**
     * Read {toolname}_operation_id - attribute and parse string as an array
     * @param Element
     * @type {Element}
     */
    getOperationId_multiple(Element) {
        let IDString = Element.getAttribute(this.Attributes.operation_id_attribute);
        if (IDString !== null) {
            return IDString.split(' ').map(function (OperationID) {
                return parseInt(OperationID)
            });
        }
        return [];
    }



    /**
     *
     * @param Element
     * @param OperationId
     */
    setOperationId_single(Element, OperationId) {
        Element.setAttribute(this.Attributes.operation_id_attribute, OperationId.toString());
    }



    /**
     *
     * @param Element
     * @param IdArray
     */
    setOperationId_multiple(Element, IdArray) {
        let CommentIDString;
        IdArray.length > 1 ? CommentIDString = IdArray.join(' ') : CommentIDString = IdArray[0];
        Element.setAttribute(this.Attributes.operation_id_attribute, CommentIDString);
    }



    /**
     * Sync Model Node
     * @returns {Promise<void>}
     */
    async syncModel() {
        this.OperationModels = await this.readOperationsFromDOM(window.$EditorStore.Node_Model_Text);
    }



    /**
     *
     * @param Element
     * @param OperationID
     */
    addOperationId_multiple(Element, OperationID) {
        let ExistingIDs = this.getOperationId_multiple(Element);
        // If Array is empty
        if (ExistingIDs.length === 0) {
            ExistingIDs[0] = OperationID;
            this.setOperationId_multiple(Element, ExistingIDs);
        }
        // If Array already contains OperationID
        else if (EditorHelpers.notInArray(OperationID, ExistingIDs)) {
            ExistingIDs.push(parseInt(OperationID));
            this.setOperationId_multiple(Element, ExistingIDs);
        }
    }



    /**
     * If comment gets deleted, remove its ID from attribute list
     * @param Element
     * @param OperationID
     */
    removeOperationId_multiple(Element, OperationID) {
        let ExistingIDs = this.getOperationId_multiple(Element);
        if (EditorHelpers.inArray(OperationID, ExistingIDs)) {
            let NewIDs = ExistingIDs.filter(function (ExistingID) {
                return ExistingID != OperationID;
            });
            this.setOperationId_multiple(Element, NewIDs);
        }
    }



    /**
     *
     * @param Element
     * @param OperationID
     * @returns {boolean}
     */
    hasOperationId_multiple(Element, OperationID) {
        let ExistingIDs = this.getOperationId_multiple(Element);
        //return EditorHelpers.inArray(OperationID, ExistingIDs) ? true : false;
        return !!EditorHelpers.inArray(OperationID, ExistingIDs);
    }



    /**
     * Add Event-Listeners for editable ElementArray (<w> and <pc>)
     */
    registerEventListeners() {
        let self = this;

        this.MouseDown = self.MouseDownEvent;
        this.MouseClick = self.MouseClickEvent;
        this.MouseOut = self.MouseOutEvent;
        this.MouseOver = self.MouseOverEvent;

        // Iterate through all elements that where declared as Erasable
        this.AffectedElements.forEach(AffectedElement => {
            /**
             * Event-Listener for Element, when starts marking
             */
            AffectedElement.addEventListener('mousedown', self.MouseDown, false);

            /**
             * Event-Listener for the element the user releases mouse on
             */
            AffectedElement.addEventListener('mouseout', self.MouseOut, false);

            /**
             * Event-Listener for Element, when user clicks Element
             */
            AffectedElement.addEventListener('click', self.MouseClick, false);
            /**
             * Event-Listener for Element, when user is marking
             */
            AffectedElement.addEventListener('mouseover', self.MouseOver, false);

        });
    }



    /**
     * Method finishes ToolOperation and is triggered by an Event (e.g. a button in ToolInfo-component)
     */
    finishAssignment() {
        if (this.SelectedElements.length !== 0) {
            this.incrementOperationId();
            ToolOperation.record(this.SelectedElements, this.appearance.Title);
            ToolOperation.dispatchFinishedEvent();
        }
        this.SelectedElements = [];
    };



    /**
     * Empty selection and remove visible classes in canvas
     */
    discard() {
        this.SelectedElements.forEach(SelectedElement => {
            SelectedElement.classList.remove(this.appearance.CssClass);
        });
        this.SelectedElements = [];
    }



    /**
     * Display currently selected ElementArray as an ordered String
     */
    get CurrentSelection() {
        if (this.SelectedElements.length > 0) {
            return this.formatTextElements(this.SelectedElements);
        }
        else {
            return false;
        }
    }



    /**
     * For Vue-Template
     * @returns {Array|*}
     * @constructor
     */
    get Assignations() {
        return this.Operations;
    }



    /**
     * Remove Event-Listeners for editable ElementArray (<w> and <pc>)
     */
    removeEventListeners() {
        if (this.AffectedElements.length) {
            this.AffectedElements.forEach(AffectedElement => {
                AffectedElement.removeEventListener('click', this.MouseClick, false);
                AffectedElement.removeEventListener('mouseout', this.MouseOut, false);
                AffectedElement.removeEventListener('mouseover', this.MouseOver, false);
                AffectedElement.removeEventListener('mousedown', this.MouseDown, false);
            });
        }
    }




    /**
     * Return an Array of operations that are abstracted by a set of DOM-elements
     * @param DOMNode
     * @returns {Array}
     */
    async readOperationsFromDOM(DOMNode) {
        let DOMElements = DOMNode.querySelectorAll(this.config.AffectedElements);
        let ElementsWithOperationID = this.getElementsByAttribute(DOMElements, this.Attributes.operation_id_attribute);

        let AllOperationIds = this.extractOperationIds(ElementsWithOperationID);
        let Operations = [];
        for await (let operation_id of AllOperationIds) {
            let OperationElements = this.getOperationElements(operation_id, ElementsWithOperationID, DOMNode) || [];
            Operations.push(new ToolOperation(operation_id, OperationElements));
        }
        return Operations;
    }

}
